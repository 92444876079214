.sol-mobile-menu {
  h1,
  h2 {
    font-family: @main-font;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    color: @white;
    font-size: 20px;
  }
  h2 {
    font-size: 30px;
  }
  &__item {
    position: relative;
    background-color: white;
    font-family: @main-font;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    color: @black;
    border-bottom: 1px solid @bg-gray;
    a {
      color: @black;
      display: block;
      padding: 0 (8px + 35px + 15px) 0 18px;
      line-height: 50px;
      height: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      &:hover {
        color: @black;
      }
    }
    button {
      .no-appearance;
      padding: 0;
      font-size: 22px;
      height: 44px;
      width: 44px;
      text-align: center;
      color: @black;
      position: absolute;
      right: 3px;
      top: 3px;
      &::before {
        //Increasing click area
        content: '';
        position: absolute;
        left: -7px;
        top: -7px;
        bottom: -7px;
        right: -7px;
      }
      & i.fal {
        line-height: 35px;
        font-size: 24px;
      }
    }
  }
  &__back {
    .no-appearance;
    display: flex;
    font-family: @main-font;
    font-size: 16px;
    font-weight: 800;
    color: @white;
    border: 1px solid fadeout(@white,20%);
    align-items: center;
    padding: 10px 20px 10px 10px;
    border-radius: @border-radius-normal;
    i.fal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 24px;
      background-color: transparent;
      color: @white;
    }
  }
  &__list {
    position: absolute;
    left: 15px;
    right: 15px;
    padding-bottom: 125px;
  }
  &__links {
    text-align: left;
    padding-top: 25px;
    margin-left: 20px;
    margin-bottom: 30px;
    a, button {
      display: block;
      font-family: @main-font;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.6px;
      color: @white;
      margin-bottom: 25px;
      text-decoration: none;
      &:hover {
        color: @white;
        text-decoration: underline;
      }
      i {
        font-size: 20px;
        margin-right: 10px;

        &.fa-volume-up {
          &::before {
            content: '\f025';
          }
        }
      }
    }

    button {
      .no-appearance;
      padding: 0;
    }
  }
  &__startpage-nav .sol-mobile-menu__item button {
    height: 50px;
    width: 100%;
    top: 0;
    right: 0;
    text-align: right;
    padding-right: 20px;
  }
}
