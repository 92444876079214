.sol-startpage-content-bottom {
    &__blurb {
      // height: 100%;

        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0,0,0,.16);
        display: grid;
        grid-template-rows: max-content auto;
        height: calc(100% - 30px);
        margin-bottom: 30px;
        position: relative;
        background-color: @white;

        h1,
        h2,
        h3,
        h4 {
          margin: 0 0 10px !important;
          font-size: 24px !important;
          overflow-wrap: anywhere;
        }

        p, p.font-normal {
          font-size: 18px;
          line-height: 1.2;
          margin-bottom: 0 !important;
          margin-top: 0;
        }

        .rspkr_dr_link {
          display: none;
        }

        &:hover{
          a {
            text-decoration: underline;
          }
        }
        .sv-image-portlet, .sv-mfpimage-portlet {
          border-radius: 5px 5px 0 0;
          grid-row: 1;
          padding-bottom: 56.25%;
          position: relative;

          img {
            border-radius: 5px 5px 0 0;
            height: 100%;
            left: 0;
            min-height: 100%!important;
            min-width: 100%!important;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: 50%;
            object-position: 50%;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }

        >.sv-vertical {
          border-top: 6px solid #af1d35;
          grid-row: 2;
          padding: 20px 30px 60px 20px;
        }
      }
    }

