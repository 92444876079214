.sol-eservices {
  .env-button {
    background-color: @mora-blue;
    margin: 15px 0;
    color: @white;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    justify-content: space-between;
    padding: 0 12px;
    transition: 0.2s;

    &::after {
      .icon(@fa-var-chevron-right, inherit, inline-block);
      font-size: 22px;
    }

    &:hover {
      background-color: darken(@mora-blue,10%);
    }
  }
}
