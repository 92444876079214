.sol-mora-vaxer-top{
  h1, p{
    text-align: left;
  }
  .tag{   
    background-color: var(--sol-map-category-backgroundcolor);
    color: var(--sol-map-category-color);
  }
}
.sol-mora-vaxer-image{
  display: flex;
  justify-content: center;
  max-width: 1240px !important;

  .sv-layout{
    width: 100%;
  }

  .sv-portlet-image-caption{
    background-color: var(--sol-map-category-backgroundcolor);
    color: var(--sol-map-category-color);
    padding: 13px 10px;
    margin-top: 0;
    font-size: 14px;
  }
  img{
    width: 100%;
    min-width: 100%;
    max-width: 100% !important;
    max-height: 515px !important;
    object-fit: cover;
  }
}

.sol-startpage-entrances-mv{
  .sol-landing-large{
    justify-content: center;
    align-items: center;

     .expandable-content{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
     }

     @media @sm{
      .expandable-content{
        display: none;
      }
      .expandable-content.expanded{
        display: flex;
        flex-wrap: wrap;
      }
     }
   
    #expand-btn{
      font-family: @main-font;
      background-color: transparent;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 18px;
      padding: 8px;
      display: none;

      @media @md{
        display: block;
      }

      .fa-chevron-down{
        color: white;
        font-size: 16px;
        margin-left: 4px;
      }

      &.expanded{
        .fa-chevron-down{
          transform: rotate(180deg);
        }
      }
    }

    @media @md{
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  .sol-landing-large__item{
    flex: 0 0 auto;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 6px;
    margin-left: 6px;
    align-items: center;

    @media @md{
      padding-bottom: 2px;
      padding-top: 2px;
    }

    a{
      display: flex;
      align-items: center;
    }

    .sol-landing-large__header{
      font-size: 18px;
      margin-bottom: 0;
    }
    .sol-landing-large__icon{
      width: 17px;
      height: 17px;
      font-size: 17px;
      margin-right: 2px;
      @media @md{
        top: auto;
      }
    }
  }
}
