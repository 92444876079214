.sol-event-listing {
  li {
    display: flex;
    align-items: center;
  }
}

.sol-event-item {
  display: flex;
  align-items: stretch;
  margin-top: 0;
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  .transition(opacity);
  &:hover {
    opacity: 0.9;

    .sol-event-item-content {
      text-decoration: underline;
    }
  }

  &-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    position: relative;
    background: @event-date-bg;
    color: @event-date-color;
    width: 80px;
    border-radius: @event-border-radius;
    border: 1px solid @event-border-color;
    flex: 0 0 80px;
    margin-right: 10px;
  }

  &-day {
    font-size: 20px;
    font-weight: 500;
  }

  &-content {
    padding: 4px 10px;
    text-align: left;
    display: inline-block;
    padding-top: 4px;
    background-color: #F1F1F1;
    width: 100%;
    min-height: 80px;
    border-radius: 5px;
  }

  &-heading {
    display: block;
    font-size: 16px !important;
    font-weight: 300;
    font-family: @heading-font;
    margin-top: -4px;
    text-decoration: none;

    @media @sm {
      line-height: 27px;
    }
  }

  &-desc {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-info {
    .sol-clearfix;
    padding: 0;
    margin-bottom: 2px;

    @media  @sm {
      margin-bottom: 4px;
    }

    &-item {
      display: inline-block;
      font-size: 12px;
      margin-right: 0px;
      color: @black;

      &:after {
        content: '/';
      }

      &:last-child:after {
        content: '';
      }
    }
  }
}

.sol-solid-events {
  margin-bottom: -13px;
}
