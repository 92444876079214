.sv-carousel {
  &__item {
    border-radius: @border-radius-normal;
    overflow: hidden;
  }
  &__navbtn-prev,
  &__navbtn-next {
    background-color: @color-2;
    border-color: transparent;
    color: black;

    &:after {
      top: 11.5px;
      right: unset;
      bottom: unset;
      left: unset;
      border: none;
      color: @white;
    }
  }
  &__navbtn-prev:after {
    .icon(@fa-var-angle-left);
    left: 8.5px;
  }
  &__navbtn-next:after {
    .icon(@fa-var-angle-right);
    right: 17.5px;
  }
  &__dot {
    border: 1px solid @white;
    background-color: @color-2;
    &--active,
    &:hover {
      background-color: lighten(@color-2, 15%) !important;
    }
  }

  .sv-imgslideshow {
    &-item {
      &__description {
        background-color: rgba(0,0,0,.7);
      }
    }
  }
}
