.env-modal-dialog {
  &__header {
    position: static;

    &__title {
      margin-bottom: 0!important;
      padding: 0;
    }
  }

  &__body {
    padding: var(--env-spacing-medium) var(--env-spacing-xxx-large);

    .env-link {
      display: inline-block;
      font-weight: 500;
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 3px;
      color: #5f5fb7;
      text-decoration: underline 1px;

      &:hover {
        text-decoration: underline 2px;
      }
    }
  }

  &__footer {
    padding: var(--env-spacing-medium) var(--env-spacing-xxx-large);

    .env-button--primary {
      background-color: #951637;

      &:hover {
        background-color: #680f27;
      }
    }

    .env-button--primary.env-button--ghost {
      background-color: transparent;
      border-color: #262626;
      color: #262626;

      &:hover {
        background-color: #7a1425;
        color: white;
      }
    }
  }
}
