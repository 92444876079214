.sol-article-wrapper {
  max-width: 828px;
  margin-left: auto;
  margin-right: auto;
  .sv-image-portlet,
  .sv-mfpimage-portlet {
    position: relative;
    overflow: hidden;
    .sol-article-date {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 60px;
      padding-top: 15px;
      padding-left: 0px;
      border-radius: @border-radius-normal;
      background-color: @news-item-date-bg;
      color: @news-item-date-color;
      text-align: center;
      &-day {
        display: block;
        font-size: 26px;
        line-height: 20px;
        font-weight: 400;
        @media @sm {
          font-size: 14px;
          line-height: 7px;
        }
      }
      &-month {
        font-size: 16px;
        line-height: 18px;
        display: block;
      }
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}

.sol-news-menu {
  .sol-menu-item[data-expanded="false"] {
    .sol-menu-list {
      display: none;
    }
  }
}

.sol-article__listing {
  max-width: inherit !important;
}

/* New listning */

.sol-article-listing {
    width: 100%;
}
