.sol-popular-pages-container {
  background-color: @mora-blue;
  .sol-popular-pages {
    .sol-clearfix;
    display: flex;
    justify-content: space-evenly;

    @media @md {
      align-items: start;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 15px;
    }

    @media @xs {
      // justify-content: center
    }

    & > a,
    & > div {
      color: @white;
      float: left;
      margin-bottom: 5px;

      @media @sm {
        font-size: 13px;
      }
    }

    & > div {
      display: inline-block;
      margin-right: 10px;
      font-weight: 600;
    }

    & > a {
      text-align: center;
      width: 200px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      border-left: 2px solid @white;
      padding: 2px 0 2px 10px;
      margin: 15px 10px 15px 0;

      @media @md {
        text-align: left;
        justify-content: left;
        border: none;
        padding: 0;
      }

      &:first-child {
        border: none;
      }

      &:hover {
        text-decoration: underline;
      }

      @media @md {
        width: 100%;
        margin-right: 0;
      }

      & i.fal {
        display: none;

        background: #fff;
        width: 22px;
        height: 22px;
        color: #2A809C;
        border-radius: 50%;
        text-align: center;
        font-weight: 300;
        font-size: 13px;
        margin-right: 5px;
        position: relative;

        @media @md {
          display: block;
        }

        &::before {
          content: '\f054';
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
        }
      }
    }

    &-first {
      @media @sm {
        width: 100%;
      }
    }
  }
}
