.sol-breadcrumbs-wrapper {
    .sol-hide-sm;
    .sol-breadcrumbs {
      .sol-clearfix;
      & li {
        float: left;
        & > a,
        & > span {
          font-size: 16px;
        }
        & > a {
          text-decoration: none;
          color: @breadcrumbs-text;
          &:hover {
            color: @breadcrumbs-text-hover;
            text-decoration: underline;
          }
        }

      }
    }
  }