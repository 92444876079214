/**
 * Soleil AB
 * Author: undefined
 * Date: Thu Feb 15 2024 12:56:59 GMT+0100 (Central European Standard Time)
 * Build script version: 2.2.4
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-aws:before {
  content: "\f375";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f4a1";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f332";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f390";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dna:before {
  content: "\f471";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edit:before {
  content: "\f044";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-female:before {
  content: "\f182";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frown:before {
  content: "\f119";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-key:before {
  content: "\f084";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-moon:before {
  content: "\f186";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nintendo-switch:before {
  content: "\f418";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-php:before {
  content: "\f457";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-alt:before {
  content: "\f3de";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-print:before {
  content: "\f02f";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-random:before {
  content: "\f074";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-rendact:before {
  content: "\f3e4";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-safari:before {
  content: "\f267";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-scanner:before {
  content: "\f488";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-plus:before {
  content: "\f4da";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-tint:before {
  content: "\f043";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-train:before {
  content: "\f238";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f2e2";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-light-300.svg#fontawesome') format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.0.13 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Light.woff') format('woff'), url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Regular.woff') format('woff'), url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Regularwoff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Medium.woff') format('woff'), url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Bold.woff') format('woff'), url('/webdav/files/System/resources/fonts/quick-sand/Quicksand-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sv-grid-main.sv-fluid-grid {
  padding-left: 45px;
  padding-right: 45px;
}
@media (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1200px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-12 {
    position: relative;
    left: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 1200px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-12 {
    position: relative;
    left: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sol-row::after,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (max-width: 479px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.sv-grid-main.sv-fluid-grid *[class*=sol-col-],
.sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
  flex: 1 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid *[class*=sol-col-],
  .sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none;
}
.sol-hide-sm {
  display: inherit;
}
@media (min-width: 1200px) {
  .sol-hide-xl {
    display: none;
  }
  .sol-show-xl {
    display: inherit;
  }
}
@media (max-width: 1199px) {
  .sol-hide-lg {
    display: none;
  }
  .sol-show-lg {
    display: inherit;
  }
}
@media (max-width: 991px) {
  .sol-hide-md {
    display: none;
  }
  .sol-show-md {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .sol-hide-sm {
    display: none;
  }
  .sol-show-sm {
    display: inherit;
  }
}
@media (max-width: 479px) {
  .sol-hide-xs {
    display: none;
  }
  .sol-show-xs {
    display: inherit;
  }
}
.sol-action-link {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  min-height: 51px;
  width: 100%;
  background-color: #fff;
  color: #262626;
  padding: 9px 10px 10px 60px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.sol-action-link:focus,
[data-whatinput="keyboard"] .sol-action-link:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-action-link:focus,
[data-whatinput="mouse"] .sol-action-link:focus,
[data-whatinput="touch"] .sol-action-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-action-link:hover {
  text-decoration: underline;
}
.sol-action-link p {
  max-width: 100%;
  margin-top: 0;
}
.sol-action-link .fa-angle-right {
  position: absolute;
  background-color: #2A809C;
  color: #fff;
  font-size: 30px;
  height: 100%;
  width: 45px;
  top: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  padding-top: 9px;
}
.input-label {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.input-required-asterisk:after {
  content: '*';
  color: #d53636;
  font-size: 16px;
  margin-left: 2px;
}
.input-text {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.input-text::-webkit-input-placeholder {
  color: #313131;
}
.input-text:-moz-placeholder {
  color: #313131;
}
.input-text::-moz-placeholder {
  color: #313131;
}
.input-text:-ms-input-placeholder {
  color: #313131;
}
.input-text:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-text:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-text:focus {
  background-color: #fff;
  border-color: black;
}
.input-text::-ms-clear,
.input-text::-webkit-search-cancel-button,
.input-text::-webkit-search-decoration,
.input-text::-webkit-search-results-button,
.input-text::-webkit-search-results-decoration {
  display: none;
}
.input-text-small {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  height: 35px;
  padding: 9px 16px 8px;
  font-size: 14px;
}
.input-text-small::-webkit-input-placeholder {
  color: #313131;
}
.input-text-small:-moz-placeholder {
  color: #313131;
}
.input-text-small::-moz-placeholder {
  color: #313131;
}
.input-text-small:-ms-input-placeholder {
  color: #313131;
}
.input-text-small:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-text-small:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-text-small:focus {
  background-color: #fff;
  border-color: black;
}
.input-text-small::-ms-clear,
.input-text-small::-webkit-search-cancel-button,
.input-text-small::-webkit-search-decoration,
.input-text-small::-webkit-search-results-button,
.input-text-small::-webkit-search-results-decoration {
  display: none;
}
.input-select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.input-select::-webkit-input-placeholder {
  color: #313131;
}
.input-select:-moz-placeholder {
  color: #313131;
}
.input-select::-moz-placeholder {
  color: #313131;
}
.input-select:-ms-input-placeholder {
  color: #313131;
}
.input-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-select:focus {
  background-color: #fff;
  border-color: black;
}
.input-select::-ms-clear,
.input-select::-webkit-search-cancel-button,
.input-select::-webkit-search-decoration,
.input-select::-webkit-search-results-button,
.input-select::-webkit-search-results-decoration {
  display: none;
}
.input-select-small {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
  padding: 9px 16px 8px;
  font-size: 14px;
}
.input-select-small::-webkit-input-placeholder {
  color: #313131;
}
.input-select-small:-moz-placeholder {
  color: #313131;
}
.input-select-small::-moz-placeholder {
  color: #313131;
}
.input-select-small:-ms-input-placeholder {
  color: #313131;
}
.input-select-small:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-select-small:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-select-small:focus {
  background-color: #fff;
  border-color: black;
}
.input-select-small::-ms-clear,
.input-select-small::-webkit-search-cancel-button,
.input-select-small::-webkit-search-decoration,
.input-select-small::-webkit-search-results-button,
.input-select-small::-webkit-search-results-decoration {
  display: none;
}
.input-text-invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.input-text-valid {
  border-color: #c7e6b3;
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/check_valid.svg');
}
.input-textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  min-height: 120px;
  height: auto;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input-textarea::-webkit-input-placeholder {
  color: #313131;
}
.input-textarea:-moz-placeholder {
  color: #313131;
}
.input-textarea::-moz-placeholder {
  color: #313131;
}
.input-textarea:-ms-input-placeholder {
  color: #313131;
}
.input-textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-textarea:focus {
  background-color: #fff;
  border-color: black;
}
.input-textarea::-ms-clear,
.input-textarea::-webkit-search-cancel-button,
.input-textarea::-webkit-search-decoration,
.input-textarea::-webkit-search-results-button,
.input-textarea::-webkit-search-results-decoration {
  display: none;
}
.input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.input-checkbox,
.input-radio {
  display: block;
  height: 0;
  opacity: 0;
}
.input-checkbox + label::before,
.input-radio + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.input-checkbox + label,
.input-radio + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.input-checkbox + label:hover::before,
.input-radio + label:hover::before {
  border-color: #257088;
}
.input-checkbox:focus + label::before,
.input-radio:focus + label::before,
.input-checkbox:focus:checked + label::before,
.input-radio:focus:checked + label::before {
  border: 2px solid black;
}
.input-checkbox:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.input-checkbox:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.input-checkbox:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.input-radio + label:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 1px;
}
.input-radio:checked + label:before {
  border-width: 3px;
  border-color: #2A809C;
  background-color: #2A809C;
}
.input-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
}
.input-btn:focus,
[data-whatinput="keyboard"] .input-btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .input-btn:focus,
[data-whatinput="mouse"] .input-btn:focus,
[data-whatinput="touch"] .input-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.input-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.input-btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.input-btn-small {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  font-size: 14px;
  padding: 5px 15px 6px;
}
.input-btn-small:focus,
[data-whatinput="keyboard"] .input-btn-small:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .input-btn-small:focus,
[data-whatinput="mouse"] .input-btn-small:focus,
[data-whatinput="touch"] .input-btn-small:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-btn-small:hover {
  background-color: #3f8da6;
  color: #fff;
}
.input-btn-small:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.input-btn-small i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-opening-hours {
  text-align: left;
}
@media (max-width: 767px) {
  .sol-opening-hours {
    max-width: 100%;
    overflow: auto;
  }
}
.sol-opening-hours table {
  border-spacing: 0;
  width: 100%;
  margin-bottom: 20px;
}
.sol-opening-hours tbody tr:first-child {
  background-color: rgba(42, 128, 156, 0.5) !important;
}
.sol-opening-hours tbody tr:nth-child(odd) {
  background-color: rgba(42, 128, 156, 0.15);
}
.sol-opening-hours td,
.sol-opening-hours th {
  padding: 5px;
}
.sol-opening-hours thead {
  text-decoration: underline;
}
body {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  background-color: #fff;
  overflow-x: hidden;
}
p,
p.font-normal,
.font-normal,
p.font-description,
.font-description {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  font-size: 20px;
  line-height: 1.7em;
  font-weight: 400;
}
@media (max-width: 767px) {
  p,
  p.font-normal,
  .font-normal,
  p.font-description,
  .font-description {
    font-size: 15px;
  }
}
ap,
ap.font-normal,
a.font-normal,
ap.font-description,
a.font-description,
p a,
p.font-normal a,
.font-normal a,
p.font-description a,
.font-description a {
  color: #2B6F88;
}
ap:hover,
ap.font-normal:hover,
a.font-normal:hover,
ap.font-description:hover,
a.font-description:hover,
p a:hover,
p.font-normal a:hover,
.font-normal a:hover,
p.font-description a:hover,
.font-description a:hover {
  color: #262626;
}
p.font-description {
  color: #262626;
  font-weight: 700;
}
ap.font-description,
p.font-description a {
  color: #2B6F88;
}
ap.font-description:hover,
p.font-description a:hover {
  color: #262626;
}
h1,
.font-heading-1,
h1.font-heading-1 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 28px;
  line-height: 1.3em;
  font-weight: 500;
}
@media (max-width: 991px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 31px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 26px;
  }
}
@media (max-width: 479px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
}
h2,
h2.font-heading-2,
.font-heading-2 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 700;
}
@media (max-width: 479px) {
  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-size: 18px;
    line-height: 29px;
  }
}
h3,
h3.font-heading-3,
.font-heading-3 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 500;
}
@media (max-width: 479px) {
  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-size: 14px;
  }
}
h4,
h4.font-heading-4,
.font-heading-4 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 500;
}
@media (max-width: 479px) {
  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-size: 12px;
  }
}
p.font-quote,
.font-quote {
  position: relative;
  font-family: 'Quicksand', sans-serif;
  color: #3c3c3c;
  font-size: 22px;
  line-height: 29px;
  font-weight: 100;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
}
@media (max-width: 991px) {
  p.font-quote,
  .font-quote {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  p.font-quote,
  .font-quote {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 479px) {
  p.font-quote,
  .font-quote {
    padding-left: 30px;
  }
}
p.font-quote::before,
.font-quote::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 20px;
  content: "\f10e";
  position: absolute;
  left: 20px;
  top: 0px;
  opacity: 0.3;
  line-height: 35px;
}
p.font-quote::before.fa-pull-left,
.font-quote::before.fa-pull-left {
  margin-right: 0.3em;
}
p.font-quote::before.fa-pull-right,
.font-quote::before.fa-pull-right {
  margin-left: 0.3em;
}
@media (max-width: 991px) {
  p.font-quote::before,
  .font-quote::before {
    left: 5px;
    line-height: 33px;
  }
}
@media (max-width: 479px) {
  p.font-quote::before,
  .font-quote::before {
    left: 5px;
    line-height: 31px;
  }
}
ol.sol-bullet-list,
ul.sol-bullet-list,
ol.sol-numbered-list,
ul.sol-numbered-list {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 0px;
  margin-left: 15px;
  list-style: none !important;
}
ol.sol-bullet-list li,
ul.sol-bullet-list li,
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 25px;
  list-style: none !important;
}
ol.sol-bullet-list li::before,
ul.sol-bullet-list li::before,
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  position: absolute;
  left: 0;
  display: inline-block;
  font-weight: 400;
}
ol.sol-bullet-list ul,
ul.sol-bullet-list ul,
ol.sol-numbered-list ul,
ul.sol-numbered-list ul {
  padding-left: 0.4rem;
}
ol.sol-bullet-linklist,
ul.sol-bullet-linklist {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 0px;
  margin-left: 15px;
  list-style: none !important;
}
ol.sol-bullet-linklist li,
ul.sol-bullet-linklist li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px;
}
ol.sol-bullet-linklist li::before,
ul.sol-bullet-linklist li::before {
  position: absolute;
  left: 0;
  top: 4px;
  display: inline-block;
  font-weight: 700;
}
.sol-bullet-list li::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 16px;
  content: "\f111";
  color: #3a92b3;
  font-size: 10px;
  margin-top: 11px;
  font-weight: 600 !important;
}
.sol-bullet-list li::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-bullet-list li::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-bullet-list ul li::before {
  font-size: 8px;
}
.sol-bullet-linklist li::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 16px;
  content: "\f105";
  color: #3a92b3;
}
.sol-bullet-linklist li::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-bullet-linklist li::before.fa-pull-right {
  margin-left: 0.3em;
}
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  counter-increment: step-counter;
  padding-left: 20px;
}
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  content: counter(step-counter) '.';
  color: #3a92b3;
  font-size: 14px;
  text-align: center;
  top: 0;
}
.sv-linkicon {
  width: 18px;
  height: 18px;
  max-width: initial;
  max-height: initial;
}
.sv-text-portlet .sv-linkicon {
  display: none;
}
* {
  box-sizing: border-box;
}
a:focus,
button:focus,
[data-whatinput="keyboard"] a:focus,
[data-whatinput="keyboard"] button:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] a:focus,
[data-whatinput="initial"] button:focus,
[data-whatinput="mouse"] a:focus,
[data-whatinput="mouse"] button:focus,
[data-whatinput="touch"] a:focus,
[data-whatinput="touch"] button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.sol-ul,
.sol-ul-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sol-float-right {
  float: right;
}
.sol-float-left {
  float: left;
}
.sol-hidden {
  display: none;
}
.sol-visibility-hidden {
  visibility: hidden;
}
.sol-clearfix:after,
.sol-clearfix:before {
  content: "";
  display: table;
}
.sol-clearfix:after {
  clear: both;
}
.sol-relative {
  position: relative;
}
.sol-no-anim * {
  transition: none !important;
}
.sol-external-link-icon {
  margin-left: 6px;
  font-size: 10px;
}
.sol-hide {
  display: none;
}
#google_translate_element .goog-te-combo {
  font-size: 20px;
  font-weight: 300;
}
.sv-video-portlet {
  margin-bottom: 15px;
}
.sol-icon {
  display: inline-block;
}
.sol-icon::after {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
}
.sol-required-asterisk:after {
  display: inline-block;
  content: "\002a";
  color: red;
  font-size: 14px;
}
.sol-alarm-list table {
  width: 100%;
}
.env-modal-dialog__header {
  position: static;
}
.env-modal-dialog__header__title {
  margin-bottom: 0!important;
  padding: 0;
}
.env-modal-dialog__body {
  padding: var(--env-spacing-medium) var(--env-spacing-xxx-large);
}
.env-modal-dialog__body .env-link {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 3px;
  color: #5f5fb7;
  text-decoration: underline 1px;
}
.env-modal-dialog__body .env-link:hover {
  text-decoration: underline 2px;
}
.env-modal-dialog__footer {
  padding: var(--env-spacing-medium) var(--env-spacing-xxx-large);
}
.env-modal-dialog__footer .env-button--primary {
  background-color: #951637;
}
.env-modal-dialog__footer .env-button--primary:hover {
  background-color: #680f27;
}
.env-modal-dialog__footer .env-button--primary.env-button--ghost {
  background-color: transparent;
  border-color: #262626;
  color: #262626;
}
.env-modal-dialog__footer .env-button--primary.env-button--ghost:hover {
  background-color: #7a1425;
  color: white;
}
.sol-pagination__list {
  display: flex;
  list-style: none;
  margin-top: 30px;
  align-items: center;
  padding-left: 25px;
  justify-content: flex-start;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-pagination__list {
    justify-content: flex-start;
    padding-left: 25px;
  }
}
@media (max-width: 991px) {
  .sol-pagination__list {
    justify-content: center;
  }
}
.sol-pagination__list-item > a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sol-pagination__list-item,
.sol-pagination__list-item--previous,
.sol-pagination__list-item--next,
.sol-pagination__list-item--first,
.sol-pagination__list-item--last {
  display: flex;
  margin-right: 20px;
}
.sol-pagination__list-item > a,
.sol-pagination__list-item--previous > a,
.sol-pagination__list-item--next > a,
.sol-pagination__list-item--first > a,
.sol-pagination__list-item--last > a {
  font-size: 19px;
  text-align: left;
  color: #262626;
  text-decoration: none;
  cursor: pointer;
}
.sol-pagination__list-item > a:hover,
.sol-pagination__list-item--previous > a:hover,
.sol-pagination__list-item--next > a:hover,
.sol-pagination__list-item--first > a:hover,
.sol-pagination__list-item--last > a:hover {
  text-decoration: none;
}
.sol-pagination__list-item > a:hover::after,
.sol-pagination__list-item--previous > a:hover::after,
.sol-pagination__list-item--next > a:hover::after,
.sol-pagination__list-item--first > a:hover::after,
.sol-pagination__list-item--last > a:hover::after {
  border-bottom-color: #6ecde9;
}
.sol-pagination__list-item.active > a,
.sol-pagination__list-item--previous.active > a,
.sol-pagination__list-item--next.active > a,
.sol-pagination__list-item--first.active > a,
.sol-pagination__list-item--last.active > a {
  border: solid 2px rgba(110, 205, 233, 0.6);
  border-radius: 50%;
}
.sol-pagination__list-item--previous > a,
.sol-pagination__list-item--next > a,
.sol-pagination__list-item--first > a,
.sol-pagination__list-item--last > a {
  font-size: 19px;
  font-weight: 300;
  text-align: left;
  color: #204560;
  text-decoration: none;
  position: relative;
}
.sol-pagination__list-item--previous > a::after,
.sol-pagination__list-item--next > a::after,
.sol-pagination__list-item--first > a::after,
.sol-pagination__list-item--last > a::after {
  content: '';
  border-bottom: 2px solid rgba(110, 205, 233, 0.6);
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 2px;
  z-index: -1;
}
.sv-carousel__item {
  border-radius: 8px;
  overflow: hidden;
}
.sv-carousel__navbtn-prev,
.sv-carousel__navbtn-next {
  background-color: #41869f;
  border-color: transparent;
  color: black;
}
.sv-carousel__navbtn-prev:after,
.sv-carousel__navbtn-next:after {
  top: 11.5px;
  right: unset;
  bottom: unset;
  left: unset;
  border: none;
  color: #fff;
}
.sv-carousel__navbtn-prev:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f104";
  left: 8.5px;
}
.sv-carousel__navbtn-prev:after.fa-pull-left {
  margin-right: 0.3em;
}
.sv-carousel__navbtn-prev:after.fa-pull-right {
  margin-left: 0.3em;
}
.sv-carousel__navbtn-next:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f105";
  right: 17.5px;
}
.sv-carousel__navbtn-next:after.fa-pull-left {
  margin-right: 0.3em;
}
.sv-carousel__navbtn-next:after.fa-pull-right {
  margin-left: 0.3em;
}
.sv-carousel__dot {
  border: 1px solid #fff;
  background-color: #41869f;
}
.sv-carousel__dot--active,
.sv-carousel__dot:hover {
  background-color: #6aabc2 !important;
}
.sv-carousel .sv-imgslideshow-item__description {
  background-color: rgba(0, 0, 0, 0.7);
}
.sol-box-decoration {
  border-radius: 8px;
  background-color: #dfecf0;
  border: 1px solid #d4e0e4;
  margin-bottom: 20px;
  padding: 30px;
}
.sol-box-decoration:after,
.sol-box-decoration:before {
  content: "";
  display: table;
}
.sol-box-decoration:after {
  clear: both;
}
@media (max-width: 991px) {
  .sol-box-decoration {
    padding: 20px;
  }
}
.sol-box-decoration-header {
  display: block;
  color: #262626;
  text-align: left;
  text-decoration: none;
  line-height: 26px;
  font-weight: 500;
  font-size: 22px;
  margin: 0 0 15px 0;
}
a .sol-box-decoration-header:focus,
[data-whatinput="keyboard"] a .sol-box-decoration-header:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] a .sol-box-decoration-header:focus,
[data-whatinput="mouse"] a .sol-box-decoration-header:focus,
[data-whatinput="touch"] a .sol-box-decoration-header:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-box-decoration .sv-text-portlet.sol-btn-small .font-btn {
  margin-bottom: 5px;
}
.sol-box-decoration.sol-box-decoration-alt {
  background-color: rgba(223, 236, 240, 0.7);
  border: 3px solid #2A809C;
}
.sol-box-decoration.sol-box-decoration-alt::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: block;
  font-size: 32px;
  content: "\f05a";
  color: #2A809C;
  position: absolute;
}
.sol-box-decoration.sol-box-decoration-alt::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-box-decoration.sol-box-decoration-alt::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-box-decoration.sol-box-decoration-alt > div {
  margin-left: 45px;
}
.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #e7e7e7;
}
.sol-simple-box-decoration {
  margin-top: 25px;
  padding: 30px 0;
  border-top: 4px solid #dcdcdb;
  background-color: #fff;
}
.sol-simple-box-decoration.sol-simple-box-decoration-alt {
  background-color: rgba(255, 255, 255, 0.5);
}
.sol-simple-box-decoration > div {
  display: block;
  margin-bottom: 5px;
}
.sol-simple-box-decoration > div.font-heading-3 {
  margin-top: 0;
}
.sv-editing-mode .sol-error-box,
.sv-editing-mode .sol-warning-box {
  display: block;
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.sol-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
}
.sol-btn:focus,
[data-whatinput="keyboard"] .sol-btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-btn:focus,
[data-whatinput="mouse"] .sol-btn:focus,
[data-whatinput="touch"] .sol-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
.font-btn > a {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  padding-bottom: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.font-btn > a:focus,
[data-whatinput="keyboard"] .font-btn > a:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .font-btn > a:focus,
[data-whatinput="mouse"] .font-btn > a:focus,
[data-whatinput="touch"] .font-btn > a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.font-btn > a:hover {
  background-color: #3f8da6;
  color: #fff;
}
.font-btn > a:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.font-btn > a i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-btn-small {
  padding: 10px 25px 10px;
  color: #fff;
  background-color: #2A809C;
  box-shadow: none;
  font-size: 15px;
  line-height: 10px;
  font-weight: 400;
  text-transform: none;
}
.sol-btn-small:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-btn-small:active {
  top: 0;
  box-shadow: none;
}
[data-whatinput="keyboard"] .sol-btn-small:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
.sv-text-portlet.sol-font-btn-small .font-btn {
  text-align: right !important;
  padding-top: 4px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a {
  background-color: transparent;
  color: #262626;
  font-size: 18px;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a::after {
  position: absolute;
  right: 6px;
  top: 6px;
  color: #af1d35;
  font-size: 30px !important;
  font-weight: 100 !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 16px;
  content: "\f105";
}
.sv-text-portlet.sol-font-btn-small .font-btn > a::after.fa-pull-left {
  margin-right: 0.3em;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a::after.fa-pull-right {
  margin-left: 0.3em;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a:hover {
  text-decoration: underline;
}
.sol-collapsible {
  border-radius: 8px;
  margin-bottom: 15px;
  background: #dfecf0;
}
.sol-collapsible__header {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 65px 16px 20px;
  background-color: #dfecf0;
  border-radius: 8px;
  color: #262626;
  font-family: 'Quicksand', sans-serif;
  text-align: left;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 500;
  font-size: 22px;
  margin: 0;
  cursor: pointer;
}
.sol-collapsible__header:focus,
[data-whatinput="keyboard"] .sol-collapsible__header:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-collapsible__header:focus,
[data-whatinput="mouse"] .sol-collapsible__header:focus,
[data-whatinput="touch"] .sol-collapsible__header:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-collapsible__header__icon {
  display: block;
  width: 56px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #2A809C;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sol-collapsible__header__icon::after,
.sol-collapsible__header__icon::before {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 4px;
  margin-left: -10px;
  margin-top: -1px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-collapsible__header__icon::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sol-collapsible__header--active {
  border-bottom-left-radius: 0;
  background-color: #2A809C;
  color: #fff;
  font-weight: 600;
}
.sol-collapsible__header--active .sol-collapsible__header__icon {
  border-bottom-right-radius: 0;
}
.sol-collapsible__header--active .sol-collapsible__header__icon::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sol-collapsible .sol-collapsible__content,
.sol-collapsible .sv-decoration-content {
  display: none;
  padding: 20px;
}
.sol-collapsible .sol-collapsible__content > *:last-child,
.sol-collapsible .sv-decoration-content > *:last-child {
  margin-bottom: 0;
}
.sol-collapsible .sol-collapsible__content p:last-of-type,
.sol-collapsible .sv-decoration-content p:last-of-type {
  margin: 0;
}
.sv-editing-mode .sol-collapsible .sol-collapsible__content,
.sv-editing-mode .sol-collapsible .sv-decoration-content {
  display: block;
}
.sol-collapsible.sol-collapsible-alt {
  background: #fff;
  border: 1px solid rgba(158, 158, 158, 0.5);
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header {
  font-size: 16px;
  background: #fff;
  padding: 14px 50px 13px 30px;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header__icon {
  height: 46px;
  background: transparent;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header__icon::after,
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header__icon::before {
  background: #262626;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header--active {
  border-bottom-right-radius: 0;
  background-color: #2A809C;
  color: #fff;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header--active .sol-collapsible__header__icon {
  border-bottom-right-radius: 0;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header--active .sol-collapsible__header__icon::after,
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header--active .sol-collapsible__header__icon::before {
  background: #fff;
}
.sol-collapsible.sol-collapsible-alt .sol-collapsible__header--active .sol-collapsible__header__icon::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sv-edit-mode .sol-collapsible__header .sol-collapsible__header__icon::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sv-edit-mode .sol-collapsible__header.sol-collapsible__header--active .sol-collapsible__header__icon::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sv-form-portlet,
.sol-form,
.sol-form-decoration {
  padding: 20px 0;
  background-color: transparent;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .sv-form-portlet,
  .sol-form,
  .sol-form-decoration {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .sv-form-portlet,
  .sol-form,
  .sol-form-decoration {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.sv-form-portlet h2,
.sol-form h2,
.sol-form-decoration h2 {
  margin-top: 0;
}
.sv-form-portlet form input[type='email'],
.sol-form form input[type='email'],
.sol-form-decoration form input[type='email'],
.sv-form-portlet form input[type='number'],
.sol-form form input[type='number'],
.sol-form-decoration form input[type='number'],
.sv-form-portlet form input[type='password'],
.sol-form form input[type='password'],
.sol-form-decoration form input[type='password'],
.sv-form-portlet form input[type='tel'],
.sol-form form input[type='tel'],
.sol-form-decoration form input[type='tel'],
.sv-form-portlet form input[type='text'],
.sol-form form input[type='text'],
.sol-form-decoration form input[type='text'],
.sv-form-portlet form input[type='url'],
.sol-form form input[type='url'],
.sol-form-decoration form input[type='url'],
.sv-form-portlet form select,
.sol-form form select,
.sol-form-decoration form select,
.sv-form-portlet form textarea,
.sol-form form textarea,
.sol-form-decoration form textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.sv-form-portlet form input[type='email']::-webkit-input-placeholder,
.sol-form form input[type='email']::-webkit-input-placeholder,
.sol-form-decoration form input[type='email']::-webkit-input-placeholder,
.sv-form-portlet form input[type='number']::-webkit-input-placeholder,
.sol-form form input[type='number']::-webkit-input-placeholder,
.sol-form-decoration form input[type='number']::-webkit-input-placeholder,
.sv-form-portlet form input[type='password']::-webkit-input-placeholder,
.sol-form form input[type='password']::-webkit-input-placeholder,
.sol-form-decoration form input[type='password']::-webkit-input-placeholder,
.sv-form-portlet form input[type='tel']::-webkit-input-placeholder,
.sol-form form input[type='tel']::-webkit-input-placeholder,
.sol-form-decoration form input[type='tel']::-webkit-input-placeholder,
.sv-form-portlet form input[type='text']::-webkit-input-placeholder,
.sol-form form input[type='text']::-webkit-input-placeholder,
.sol-form-decoration form input[type='text']::-webkit-input-placeholder,
.sv-form-portlet form input[type='url']::-webkit-input-placeholder,
.sol-form form input[type='url']::-webkit-input-placeholder,
.sol-form-decoration form input[type='url']::-webkit-input-placeholder,
.sv-form-portlet form select::-webkit-input-placeholder,
.sol-form form select::-webkit-input-placeholder,
.sol-form-decoration form select::-webkit-input-placeholder,
.sv-form-portlet form textarea::-webkit-input-placeholder,
.sol-form form textarea::-webkit-input-placeholder,
.sol-form-decoration form textarea::-webkit-input-placeholder {
  color: #313131;
}
.sv-form-portlet form input[type='email']:-moz-placeholder,
.sol-form form input[type='email']:-moz-placeholder,
.sol-form-decoration form input[type='email']:-moz-placeholder,
.sv-form-portlet form input[type='number']:-moz-placeholder,
.sol-form form input[type='number']:-moz-placeholder,
.sol-form-decoration form input[type='number']:-moz-placeholder,
.sv-form-portlet form input[type='password']:-moz-placeholder,
.sol-form form input[type='password']:-moz-placeholder,
.sol-form-decoration form input[type='password']:-moz-placeholder,
.sv-form-portlet form input[type='tel']:-moz-placeholder,
.sol-form form input[type='tel']:-moz-placeholder,
.sol-form-decoration form input[type='tel']:-moz-placeholder,
.sv-form-portlet form input[type='text']:-moz-placeholder,
.sol-form form input[type='text']:-moz-placeholder,
.sol-form-decoration form input[type='text']:-moz-placeholder,
.sv-form-portlet form input[type='url']:-moz-placeholder,
.sol-form form input[type='url']:-moz-placeholder,
.sol-form-decoration form input[type='url']:-moz-placeholder,
.sv-form-portlet form select:-moz-placeholder,
.sol-form form select:-moz-placeholder,
.sol-form-decoration form select:-moz-placeholder,
.sv-form-portlet form textarea:-moz-placeholder,
.sol-form form textarea:-moz-placeholder,
.sol-form-decoration form textarea:-moz-placeholder {
  color: #313131;
}
.sv-form-portlet form input[type='email']::-moz-placeholder,
.sol-form form input[type='email']::-moz-placeholder,
.sol-form-decoration form input[type='email']::-moz-placeholder,
.sv-form-portlet form input[type='number']::-moz-placeholder,
.sol-form form input[type='number']::-moz-placeholder,
.sol-form-decoration form input[type='number']::-moz-placeholder,
.sv-form-portlet form input[type='password']::-moz-placeholder,
.sol-form form input[type='password']::-moz-placeholder,
.sol-form-decoration form input[type='password']::-moz-placeholder,
.sv-form-portlet form input[type='tel']::-moz-placeholder,
.sol-form form input[type='tel']::-moz-placeholder,
.sol-form-decoration form input[type='tel']::-moz-placeholder,
.sv-form-portlet form input[type='text']::-moz-placeholder,
.sol-form form input[type='text']::-moz-placeholder,
.sol-form-decoration form input[type='text']::-moz-placeholder,
.sv-form-portlet form input[type='url']::-moz-placeholder,
.sol-form form input[type='url']::-moz-placeholder,
.sol-form-decoration form input[type='url']::-moz-placeholder,
.sv-form-portlet form select::-moz-placeholder,
.sol-form form select::-moz-placeholder,
.sol-form-decoration form select::-moz-placeholder,
.sv-form-portlet form textarea::-moz-placeholder,
.sol-form form textarea::-moz-placeholder,
.sol-form-decoration form textarea::-moz-placeholder {
  color: #313131;
}
.sv-form-portlet form input[type='email']:-ms-input-placeholder,
.sol-form form input[type='email']:-ms-input-placeholder,
.sol-form-decoration form input[type='email']:-ms-input-placeholder,
.sv-form-portlet form input[type='number']:-ms-input-placeholder,
.sol-form form input[type='number']:-ms-input-placeholder,
.sol-form-decoration form input[type='number']:-ms-input-placeholder,
.sv-form-portlet form input[type='password']:-ms-input-placeholder,
.sol-form form input[type='password']:-ms-input-placeholder,
.sol-form-decoration form input[type='password']:-ms-input-placeholder,
.sv-form-portlet form input[type='tel']:-ms-input-placeholder,
.sol-form form input[type='tel']:-ms-input-placeholder,
.sol-form-decoration form input[type='tel']:-ms-input-placeholder,
.sv-form-portlet form input[type='text']:-ms-input-placeholder,
.sol-form form input[type='text']:-ms-input-placeholder,
.sol-form-decoration form input[type='text']:-ms-input-placeholder,
.sv-form-portlet form input[type='url']:-ms-input-placeholder,
.sol-form form input[type='url']:-ms-input-placeholder,
.sol-form-decoration form input[type='url']:-ms-input-placeholder,
.sv-form-portlet form select:-ms-input-placeholder,
.sol-form form select:-ms-input-placeholder,
.sol-form-decoration form select:-ms-input-placeholder,
.sv-form-portlet form textarea:-ms-input-placeholder,
.sol-form form textarea:-ms-input-placeholder,
.sol-form-decoration form textarea:-ms-input-placeholder {
  color: #313131;
}
.sv-form-portlet form input[type='email']:focus,
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sv-form-portlet form input[type='number']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sv-form-portlet form input[type='password']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sv-form-portlet form input[type='tel']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sv-form-portlet form input[type='text']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sv-form-portlet form input[type='url']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sv-form-portlet form select:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sv-form-portlet form textarea:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sv-form-portlet form input[type='email']:hover,
.sol-form form input[type='email']:hover,
.sol-form-decoration form input[type='email']:hover,
.sv-form-portlet form input[type='number']:hover,
.sol-form form input[type='number']:hover,
.sol-form-decoration form input[type='number']:hover,
.sv-form-portlet form input[type='password']:hover,
.sol-form form input[type='password']:hover,
.sol-form-decoration form input[type='password']:hover,
.sv-form-portlet form input[type='tel']:hover,
.sol-form form input[type='tel']:hover,
.sol-form-decoration form input[type='tel']:hover,
.sv-form-portlet form input[type='text']:hover,
.sol-form form input[type='text']:hover,
.sol-form-decoration form input[type='text']:hover,
.sv-form-portlet form input[type='url']:hover,
.sol-form form input[type='url']:hover,
.sol-form-decoration form input[type='url']:hover,
.sv-form-portlet form select:hover,
.sol-form form select:hover,
.sol-form-decoration form select:hover,
.sv-form-portlet form textarea:hover,
.sol-form form textarea:hover,
.sol-form-decoration form textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sv-form-portlet form input[type='email']:focus,
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sv-form-portlet form input[type='number']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sv-form-portlet form input[type='password']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sv-form-portlet form input[type='tel']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sv-form-portlet form input[type='text']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sv-form-portlet form input[type='url']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sv-form-portlet form select:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sv-form-portlet form textarea:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  background-color: #fff;
  border-color: black;
}
.sv-form-portlet form input[type='email']::-ms-clear,
.sol-form form input[type='email']::-ms-clear,
.sol-form-decoration form input[type='email']::-ms-clear,
.sv-form-portlet form input[type='number']::-ms-clear,
.sol-form form input[type='number']::-ms-clear,
.sol-form-decoration form input[type='number']::-ms-clear,
.sv-form-portlet form input[type='password']::-ms-clear,
.sol-form form input[type='password']::-ms-clear,
.sol-form-decoration form input[type='password']::-ms-clear,
.sv-form-portlet form input[type='tel']::-ms-clear,
.sol-form form input[type='tel']::-ms-clear,
.sol-form-decoration form input[type='tel']::-ms-clear,
.sv-form-portlet form input[type='text']::-ms-clear,
.sol-form form input[type='text']::-ms-clear,
.sol-form-decoration form input[type='text']::-ms-clear,
.sv-form-portlet form input[type='url']::-ms-clear,
.sol-form form input[type='url']::-ms-clear,
.sol-form-decoration form input[type='url']::-ms-clear,
.sv-form-portlet form select::-ms-clear,
.sol-form form select::-ms-clear,
.sol-form-decoration form select::-ms-clear,
.sv-form-portlet form textarea::-ms-clear,
.sol-form form textarea::-ms-clear,
.sol-form-decoration form textarea::-ms-clear,
.sv-form-portlet form input[type='email']::-webkit-search-cancel-button,
.sol-form form input[type='email']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='email']::-webkit-search-cancel-button,
.sv-form-portlet form input[type='number']::-webkit-search-cancel-button,
.sol-form form input[type='number']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='number']::-webkit-search-cancel-button,
.sv-form-portlet form input[type='password']::-webkit-search-cancel-button,
.sol-form form input[type='password']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='password']::-webkit-search-cancel-button,
.sv-form-portlet form input[type='tel']::-webkit-search-cancel-button,
.sol-form form input[type='tel']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='tel']::-webkit-search-cancel-button,
.sv-form-portlet form input[type='text']::-webkit-search-cancel-button,
.sol-form form input[type='text']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='text']::-webkit-search-cancel-button,
.sv-form-portlet form input[type='url']::-webkit-search-cancel-button,
.sol-form form input[type='url']::-webkit-search-cancel-button,
.sol-form-decoration form input[type='url']::-webkit-search-cancel-button,
.sv-form-portlet form select::-webkit-search-cancel-button,
.sol-form form select::-webkit-search-cancel-button,
.sol-form-decoration form select::-webkit-search-cancel-button,
.sv-form-portlet form textarea::-webkit-search-cancel-button,
.sol-form form textarea::-webkit-search-cancel-button,
.sol-form-decoration form textarea::-webkit-search-cancel-button,
.sv-form-portlet form input[type='email']::-webkit-search-decoration,
.sol-form form input[type='email']::-webkit-search-decoration,
.sol-form-decoration form input[type='email']::-webkit-search-decoration,
.sv-form-portlet form input[type='number']::-webkit-search-decoration,
.sol-form form input[type='number']::-webkit-search-decoration,
.sol-form-decoration form input[type='number']::-webkit-search-decoration,
.sv-form-portlet form input[type='password']::-webkit-search-decoration,
.sol-form form input[type='password']::-webkit-search-decoration,
.sol-form-decoration form input[type='password']::-webkit-search-decoration,
.sv-form-portlet form input[type='tel']::-webkit-search-decoration,
.sol-form form input[type='tel']::-webkit-search-decoration,
.sol-form-decoration form input[type='tel']::-webkit-search-decoration,
.sv-form-portlet form input[type='text']::-webkit-search-decoration,
.sol-form form input[type='text']::-webkit-search-decoration,
.sol-form-decoration form input[type='text']::-webkit-search-decoration,
.sv-form-portlet form input[type='url']::-webkit-search-decoration,
.sol-form form input[type='url']::-webkit-search-decoration,
.sol-form-decoration form input[type='url']::-webkit-search-decoration,
.sv-form-portlet form select::-webkit-search-decoration,
.sol-form form select::-webkit-search-decoration,
.sol-form-decoration form select::-webkit-search-decoration,
.sv-form-portlet form textarea::-webkit-search-decoration,
.sol-form form textarea::-webkit-search-decoration,
.sol-form-decoration form textarea::-webkit-search-decoration,
.sv-form-portlet form input[type='email']::-webkit-search-results-button,
.sol-form form input[type='email']::-webkit-search-results-button,
.sol-form-decoration form input[type='email']::-webkit-search-results-button,
.sv-form-portlet form input[type='number']::-webkit-search-results-button,
.sol-form form input[type='number']::-webkit-search-results-button,
.sol-form-decoration form input[type='number']::-webkit-search-results-button,
.sv-form-portlet form input[type='password']::-webkit-search-results-button,
.sol-form form input[type='password']::-webkit-search-results-button,
.sol-form-decoration form input[type='password']::-webkit-search-results-button,
.sv-form-portlet form input[type='tel']::-webkit-search-results-button,
.sol-form form input[type='tel']::-webkit-search-results-button,
.sol-form-decoration form input[type='tel']::-webkit-search-results-button,
.sv-form-portlet form input[type='text']::-webkit-search-results-button,
.sol-form form input[type='text']::-webkit-search-results-button,
.sol-form-decoration form input[type='text']::-webkit-search-results-button,
.sv-form-portlet form input[type='url']::-webkit-search-results-button,
.sol-form form input[type='url']::-webkit-search-results-button,
.sol-form-decoration form input[type='url']::-webkit-search-results-button,
.sv-form-portlet form select::-webkit-search-results-button,
.sol-form form select::-webkit-search-results-button,
.sol-form-decoration form select::-webkit-search-results-button,
.sv-form-portlet form textarea::-webkit-search-results-button,
.sol-form form textarea::-webkit-search-results-button,
.sol-form-decoration form textarea::-webkit-search-results-button,
.sv-form-portlet form input[type='email']::-webkit-search-results-decoration,
.sol-form form input[type='email']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='email']::-webkit-search-results-decoration,
.sv-form-portlet form input[type='number']::-webkit-search-results-decoration,
.sol-form form input[type='number']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='number']::-webkit-search-results-decoration,
.sv-form-portlet form input[type='password']::-webkit-search-results-decoration,
.sol-form form input[type='password']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='password']::-webkit-search-results-decoration,
.sv-form-portlet form input[type='tel']::-webkit-search-results-decoration,
.sol-form form input[type='tel']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='tel']::-webkit-search-results-decoration,
.sv-form-portlet form input[type='text']::-webkit-search-results-decoration,
.sol-form form input[type='text']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='text']::-webkit-search-results-decoration,
.sv-form-portlet form input[type='url']::-webkit-search-results-decoration,
.sol-form form input[type='url']::-webkit-search-results-decoration,
.sol-form-decoration form input[type='url']::-webkit-search-results-decoration,
.sv-form-portlet form select::-webkit-search-results-decoration,
.sol-form form select::-webkit-search-results-decoration,
.sol-form-decoration form select::-webkit-search-results-decoration,
.sv-form-portlet form textarea::-webkit-search-results-decoration,
.sol-form form textarea::-webkit-search-results-decoration,
.sol-form-decoration form textarea::-webkit-search-results-decoration {
  display: none;
}
.sv-form-portlet form input[type='email'].sol-dirty-input:invalid,
.sol-form form input[type='email'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='email'].sol-dirty-input:invalid,
.sv-form-portlet form input[type='number'].sol-dirty-input:invalid,
.sol-form form input[type='number'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='number'].sol-dirty-input:invalid,
.sv-form-portlet form input[type='password'].sol-dirty-input:invalid,
.sol-form form input[type='password'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='password'].sol-dirty-input:invalid,
.sv-form-portlet form input[type='tel'].sol-dirty-input:invalid,
.sol-form form input[type='tel'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='tel'].sol-dirty-input:invalid,
.sv-form-portlet form input[type='text'].sol-dirty-input:invalid,
.sol-form form input[type='text'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='text'].sol-dirty-input:invalid,
.sv-form-portlet form input[type='url'].sol-dirty-input:invalid,
.sol-form form input[type='url'].sol-dirty-input:invalid,
.sol-form-decoration form input[type='url'].sol-dirty-input:invalid,
.sv-form-portlet form select.sol-dirty-input:invalid,
.sol-form form select.sol-dirty-input:invalid,
.sol-form-decoration form select.sol-dirty-input:invalid,
.sv-form-portlet form textarea.sol-dirty-input:invalid,
.sol-form form textarea.sol-dirty-input:invalid,
.sol-form-decoration form textarea.sol-dirty-input:invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sv-form-portlet form input[type='email']:required:valid,
.sol-form form input[type='email']:required:valid,
.sol-form-decoration form input[type='email']:required:valid,
.sv-form-portlet form input[type='number']:required:valid,
.sol-form form input[type='number']:required:valid,
.sol-form-decoration form input[type='number']:required:valid,
.sv-form-portlet form input[type='password']:required:valid,
.sol-form form input[type='password']:required:valid,
.sol-form-decoration form input[type='password']:required:valid,
.sv-form-portlet form input[type='tel']:required:valid,
.sol-form form input[type='tel']:required:valid,
.sol-form-decoration form input[type='tel']:required:valid,
.sv-form-portlet form input[type='text']:required:valid,
.sol-form form input[type='text']:required:valid,
.sol-form-decoration form input[type='text']:required:valid,
.sv-form-portlet form input[type='url']:required:valid,
.sol-form form input[type='url']:required:valid,
.sol-form-decoration form input[type='url']:required:valid,
.sv-form-portlet form select:required:valid,
.sol-form form select:required:valid,
.sol-form-decoration form select:required:valid,
.sv-form-portlet form textarea:required:valid,
.sol-form form textarea:required:valid,
.sol-form-decoration form textarea:required:valid {
  border-color: #c7e6b3;
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/check_valid.svg');
}
.sv-form-portlet form input[type='email'] ~ span.font-normal,
.sol-form form input[type='email'] ~ span.font-normal,
.sol-form-decoration form input[type='email'] ~ span.font-normal,
.sv-form-portlet form input[type='number'] ~ span.font-normal,
.sol-form form input[type='number'] ~ span.font-normal,
.sol-form-decoration form input[type='number'] ~ span.font-normal,
.sv-form-portlet form input[type='password'] ~ span.font-normal,
.sol-form form input[type='password'] ~ span.font-normal,
.sol-form-decoration form input[type='password'] ~ span.font-normal,
.sv-form-portlet form input[type='tel'] ~ span.font-normal,
.sol-form form input[type='tel'] ~ span.font-normal,
.sol-form-decoration form input[type='tel'] ~ span.font-normal,
.sv-form-portlet form input[type='text'] ~ span.font-normal,
.sol-form form input[type='text'] ~ span.font-normal,
.sol-form-decoration form input[type='text'] ~ span.font-normal,
.sv-form-portlet form input[type='url'] ~ span.font-normal,
.sol-form form input[type='url'] ~ span.font-normal,
.sol-form-decoration form input[type='url'] ~ span.font-normal,
.sv-form-portlet form select ~ span.font-normal,
.sol-form form select ~ span.font-normal,
.sol-form-decoration form select ~ span.font-normal,
.sv-form-portlet form textarea ~ span.font-normal,
.sol-form form textarea ~ span.font-normal,
.sol-form-decoration form textarea ~ span.font-normal {
  margin-top: 10px;
}
.sv-form-portlet form textarea,
.sol-form form textarea,
.sol-form-decoration form textarea {
  height: auto;
}
.sv-form-portlet form select,
.sol-form form select,
.sol-form-decoration form select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.sv-form-portlet form select::-webkit-input-placeholder,
.sol-form form select::-webkit-input-placeholder,
.sol-form-decoration form select::-webkit-input-placeholder {
  color: #313131;
}
.sv-form-portlet form select:-moz-placeholder,
.sol-form form select:-moz-placeholder,
.sol-form-decoration form select:-moz-placeholder {
  color: #313131;
}
.sv-form-portlet form select::-moz-placeholder,
.sol-form form select::-moz-placeholder,
.sol-form-decoration form select::-moz-placeholder {
  color: #313131;
}
.sv-form-portlet form select:-ms-input-placeholder,
.sol-form form select:-ms-input-placeholder,
.sol-form-decoration form select:-ms-input-placeholder {
  color: #313131;
}
.sv-form-portlet form select:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sv-form-portlet form select:hover,
.sol-form form select:hover,
.sol-form-decoration form select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sv-form-portlet form select:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  background-color: #fff;
  border-color: black;
}
.sv-form-portlet form select::-ms-clear,
.sol-form form select::-ms-clear,
.sol-form-decoration form select::-ms-clear,
.sv-form-portlet form select::-webkit-search-cancel-button,
.sol-form form select::-webkit-search-cancel-button,
.sol-form-decoration form select::-webkit-search-cancel-button,
.sv-form-portlet form select::-webkit-search-decoration,
.sol-form form select::-webkit-search-decoration,
.sol-form-decoration form select::-webkit-search-decoration,
.sv-form-portlet form select::-webkit-search-results-button,
.sol-form form select::-webkit-search-results-button,
.sol-form-decoration form select::-webkit-search-results-button,
.sv-form-portlet form select::-webkit-search-results-decoration,
.sol-form form select::-webkit-search-results-decoration,
.sol-form-decoration form select::-webkit-search-results-decoration {
  display: none;
}
.sv-form-portlet form .sol-form-field,
.sol-form form .sol-form-field,
.sol-form-decoration form .sol-form-field {
  margin-bottom: 10px;
}
.sv-form-portlet form .sol-label-container > label,
.sol-form form .sol-label-container > label,
.sol-form-decoration form .sol-label-container > label,
.sv-form-portlet form .sv-field-title-container > label,
.sol-form form .sv-field-title-container > label,
.sol-form-decoration form .sv-field-title-container > label,
.sv-form-portlet form .sv-login-input-group > label,
.sol-form form .sv-login-input-group > label,
.sol-form-decoration form .sv-login-input-group > label {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.sv-form-portlet form .sol-label-container > span,
.sol-form form .sol-label-container > span,
.sol-form-decoration form .sol-label-container > span,
.sv-form-portlet form .sv-field-title-container > span,
.sol-form form .sv-field-title-container > span,
.sol-form-decoration form .sv-field-title-container > span,
.sv-form-portlet form .sv-login-input-group > span,
.sol-form form .sv-login-input-group > span,
.sol-form-decoration form .sv-login-input-group > span {
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
}
.sv-form-portlet form .sv-field-title-container label span,
.sol-form form .sv-field-title-container label span,
.sol-form-decoration form .sv-field-title-container label span,
.sv-form-portlet form .sv-field-title-container span span,
.sol-form form .sv-field-title-container span span,
.sol-form-decoration form .sv-field-title-container span span {
  color: #af1a1a !important;
}
.sv-form-portlet form .sv-form-datefield > div,
.sol-form form .sv-form-datefield > div,
.sol-form-decoration form .sv-form-datefield > div {
  width: 50%;
  margin: 0 !important;
}
.sv-form-portlet form .sv-form-datefield > div:nth-of-type(1),
.sol-form form .sv-form-datefield > div:nth-of-type(1),
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(1) {
  padding-right: 5px;
}
.sv-form-portlet form .sv-form-datefield > div:nth-of-type(2),
.sol-form form .sv-form-datefield > div:nth-of-type(2),
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(2) {
  padding-left: 5px;
}
.sv-form-portlet form .sv-form-datefield > div select,
.sol-form form .sv-form-datefield > div select,
.sol-form-decoration form .sv-form-datefield > div select {
  min-width: 100%;
  margin-bottom: 10px;
}
.sv-form-portlet form br,
.sol-form form br,
.sol-form-decoration form br {
  display: none;
}
.sv-form-portlet form .font-normal,
.sol-form form .font-normal,
.sol-form-decoration form .font-normal {
  display: block;
}
.sv-form-portlet form .sv-formField,
.sol-form form .sv-formField,
.sol-form-decoration form .sv-formField {
  margin-bottom: 0;
}
.sv-form-portlet form .sv-formField .sv-form-consent label,
.sol-form form .sv-formField .sv-form-consent label,
.sol-form-decoration form .sv-formField .sv-form-consent label {
  font-weight: 400;
}
.sv-form-portlet form .sv-formField .sv-form-consent label span,
.sol-form form .sv-formField .sv-form-consent label span,
.sol-form-decoration form .sv-formField .sv-form-consent label span {
  color: #af1a1a !important;
}
@media (max-width: 767px) {
  .sv-form-portlet form .sv-formField,
  .sol-form form .sv-formField,
  .sol-form-decoration form .sv-formField {
    width: 100%;
    padding: 0;
  }
}
.sv-form-portlet form input[type='checkbox'],
.sol-form form input[type='checkbox'],
.sol-form-decoration form input[type='checkbox'] {
  display: block;
  height: 0;
  opacity: 0;
}
.sv-form-portlet form input[type='checkbox'] + label::before,
.sol-form form input[type='checkbox'] + label::before,
.sol-form-decoration form input[type='checkbox'] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sv-form-portlet form input[type='checkbox'] + label,
.sol-form form input[type='checkbox'] + label,
.sol-form-decoration form input[type='checkbox'] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sv-form-portlet form input[type='checkbox'] + label:hover::before,
.sol-form form input[type='checkbox'] + label:hover::before,
.sol-form-decoration form input[type='checkbox'] + label:hover::before {
  border-color: #257088;
}
.sv-form-portlet form input[type='checkbox']:focus + label::before,
.sol-form form input[type='checkbox']:focus + label::before,
.sol-form-decoration form input[type='checkbox']:focus + label::before,
.sv-form-portlet form input[type='checkbox']:focus:checked + label::before,
.sol-form form input[type='checkbox']:focus:checked + label::before,
.sol-form-decoration form input[type='checkbox']:focus:checked + label::before {
  border: 2px solid black;
}
.sv-form-portlet form input[type='checkbox']:checked + label:before,
.sol-form form input[type='checkbox']:checked + label:before,
.sol-form-decoration form input[type='checkbox']:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.sv-form-portlet form input[type='checkbox']:checked + label:before.fa-pull-left,
.sol-form form input[type='checkbox']:checked + label:before.fa-pull-left,
.sol-form-decoration form input[type='checkbox']:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.sv-form-portlet form input[type='checkbox']:checked + label:before.fa-pull-right,
.sol-form form input[type='checkbox']:checked + label:before.fa-pull-right,
.sol-form-decoration form input[type='checkbox']:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.sv-form-portlet form input[type='radio'],
.sol-form form input[type='radio'],
.sol-form-decoration form input[type='radio'] {
  display: block;
  height: 0;
  opacity: 0;
}
.sv-form-portlet form input[type='radio'] + label::before,
.sol-form form input[type='radio'] + label::before,
.sol-form-decoration form input[type='radio'] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sv-form-portlet form input[type='radio'] + label,
.sol-form form input[type='radio'] + label,
.sol-form-decoration form input[type='radio'] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sv-form-portlet form input[type='radio'] + label:hover::before,
.sol-form form input[type='radio'] + label:hover::before,
.sol-form-decoration form input[type='radio'] + label:hover::before {
  border-color: #257088;
}
.sv-form-portlet form input[type='radio']:focus + label::before,
.sol-form form input[type='radio']:focus + label::before,
.sol-form-decoration form input[type='radio']:focus + label::before,
.sv-form-portlet form input[type='radio']:focus:checked + label::before,
.sol-form form input[type='radio']:focus:checked + label::before,
.sol-form-decoration form input[type='radio']:focus:checked + label::before {
  border: 2px solid black;
}
.sv-form-portlet form input[type='radio'] + label:before,
.sol-form form input[type='radio'] + label:before,
.sol-form-decoration form input[type='radio'] + label:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 1px;
}
.sv-form-portlet form input[type='radio']:checked + label:before,
.sol-form form input[type='radio']:checked + label:before,
.sol-form-decoration form input[type='radio']:checked + label:before {
  border-width: 3px;
  border-color: #2A809C;
  background-color: #2A809C;
}
.sv-form-portlet form button[type='submit'],
.sol-form form button[type='submit'],
.sol-form-decoration form button[type='submit'],
.sv-form-portlet form input[type='submit'],
.sol-form form input[type='submit'],
.sol-form-decoration form input[type='submit'] {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-appearance: none;
}
.sv-form-portlet form button[type='submit']:focus,
.sol-form form button[type='submit']:focus,
.sol-form-decoration form button[type='submit']:focus,
.sv-form-portlet form input[type='submit']:focus,
.sol-form form input[type='submit']:focus,
.sol-form-decoration form input[type='submit']:focus,
[data-whatinput="keyboard"] .sv-form-portlet form button[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form form button[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="keyboard"] .sv-form-portlet form input[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form form input[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form-decoration form input[type='submit']:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sv-form-portlet form button[type='submit']:focus,
[data-whatinput="initial"] .sol-form form button[type='submit']:focus,
[data-whatinput="initial"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="initial"] .sv-form-portlet form input[type='submit']:focus,
[data-whatinput="initial"] .sol-form form input[type='submit']:focus,
[data-whatinput="initial"] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput="mouse"] .sv-form-portlet form button[type='submit']:focus,
[data-whatinput="mouse"] .sol-form form button[type='submit']:focus,
[data-whatinput="mouse"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="mouse"] .sv-form-portlet form input[type='submit']:focus,
[data-whatinput="mouse"] .sol-form form input[type='submit']:focus,
[data-whatinput="mouse"] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput="touch"] .sv-form-portlet form button[type='submit']:focus,
[data-whatinput="touch"] .sol-form form button[type='submit']:focus,
[data-whatinput="touch"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="touch"] .sv-form-portlet form input[type='submit']:focus,
[data-whatinput="touch"] .sol-form form input[type='submit']:focus,
[data-whatinput="touch"] .sol-form-decoration form input[type='submit']:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sv-form-portlet form button[type='submit']:hover,
.sol-form form button[type='submit']:hover,
.sol-form-decoration form button[type='submit']:hover,
.sv-form-portlet form input[type='submit']:hover,
.sol-form form input[type='submit']:hover,
.sol-form-decoration form input[type='submit']:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sv-form-portlet form button[type='submit']:active,
.sol-form form button[type='submit']:active,
.sol-form-decoration form button[type='submit']:active,
.sv-form-portlet form input[type='submit']:active,
.sol-form form input[type='submit']:active,
.sol-form-decoration form input[type='submit']:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sv-form-portlet form button[type='submit'] i.fal,
.sol-form form button[type='submit'] i.fal,
.sol-form-decoration form button[type='submit'] i.fal,
.sv-form-portlet form input[type='submit'] i.fal,
.sol-form form input[type='submit'] i.fal,
.sol-form-decoration form input[type='submit'] i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sv-form-portlet form button[type='submit'] > i.fal,
.sol-form form button[type='submit'] > i.fal,
.sol-form-decoration form button[type='submit'] > i.fal,
.sv-form-portlet form input[type='submit'] > i.fal,
.sol-form form input[type='submit'] > i.fal,
.sol-form-decoration form input[type='submit'] > i.fal {
  margin-right: 11px;
  margin-left: -15px;
}
.sv-form-portlet form .sol-form-divider,
.sol-form form .sol-form-divider,
.sol-form-decoration form .sol-form-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.sol-decoration-links {
  margin-bottom: 30px;
  padding: 10px 30px 20px 30px;
  box-shadow: none !important;
}
.sol-decoration-links p > a {
  display: inline-block;
  position: relative;
  width: 100%;
  color: #3a92b3;
  padding-left: 30px;
  margin-bottom: 5px;
  text-decoration: none;
}
.sol-decoration-links p > a:first-of-type {
  margin-top: 10px;
}
.sol-decoration-links p > a:last-of-type {
  margin-bottom: 0;
}
.sol-decoration-links p > a:hover {
  text-decoration: underline;
}
.sol-decoration-links p > a::before {
  font-weight: 900;
  font-size: 22px;
  content: "\f105";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  left: 0;
  top: 2px;
  text-align: center;
  padding-left: 2px;
  width: 20px;
  z-index: 8;
  background-color: #3a92b3;
  color: #fff;
  border-radius: 50%;
}
.sol-decoration-links p > a::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-decoration-links p > a::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-decoration-links p > a::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-decoration-links p > a::before.fa-pull-right {
  margin-left: 0.3em;
}
@media (max-width: 767px) {
  .sol-decoration-links p > a::before {
    top: 3px;
  }
}
.sol-decoration-links .sv-linkicon {
  position: absolute;
  top: 6px;
  left: 0;
  margin-left: 0;
  height: 18px;
  z-index: 10;
  background-color: #fff;
}
@media (max-width: 767px) {
  .sol-decoration-links .sv-linkicon {
    top: 4px;
  }
}
.sol-decoration-links .sol-external-link-icon {
  position: relative;
  top: -1px;
}
.sv-layout:not(.sv-editable-layout) > .sol-modal-decoration > .sv-layout > .sv-decoration-content {
  display: none;
}
.sol-modal-mask {
  position: fixed !important;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.sol-modal-container {
  max-width: 900px;
  max-height: calc(100vh - 250px);
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  padding: 35px 40px;
  position: relative;
  overflow: auto;
}
.sol-modal-container .sv-grid-main.sv-fluid-grid {
  padding-left: 0;
  padding-right: 0;
}
.sol-modal-container h1.font-heading-1 {
  margin-bottom: 24px;
}
.sol-modal-container .sol-related-item {
  margin-bottom: 0;
}
.sol-modal-container .sol-related-item-name {
  line-height: 1.5em;
}
.sol-modal-container .font-heading-2 {
  margin-top: 0;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .sol-modal-container {
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .sol-modal-container {
    max-width: 800px;
    max-height: 100vh;
    margin-top: 0;
    height: 100%;
    padding: 60px 20px 0;
  }
}
.sol-modal-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 55px;
  height: 55px;
  background: #af1d35;
  cursor: pointer;
}
.sol-modal-close i.fal {
  font-size: 30px;
  color: #fff;
}
.sol-modal-close:hover i.fal {
  color: rgba(255, 255, 255, 0.8);
}
.sol-modal-decoration button.font-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-bottom: 20px;
}
.sol-modal-decoration button.font-btn:focus,
[data-whatinput="keyboard"] .sol-modal-decoration button.font-btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-modal-decoration button.font-btn:focus,
[data-whatinput="mouse"] .sol-modal-decoration button.font-btn:focus,
[data-whatinput="touch"] .sol-modal-decoration button.font-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-modal-decoration button.font-btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-modal-decoration button.font-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-modal-decoration button.font-btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sv-text-portlet .sv-responsiveTable {
  overflow-x: scroll;
}
.sv-text-portlet table.sol-table-decoration {
  border-collapse: collapse;
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #e8f3f8;
  overflow: hidden;
}
.sv-text-portlet table.sol-table-decoration caption {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 1.2em;
  font-weight: 800;
}
.sv-text-portlet table.sol-table-decoration thead {
  background-color: #2A809C;
}
.sv-text-portlet table.sol-table-decoration thead th {
  padding: 11px 18px;
  border-right: 1px solid rgba(232, 243, 248, 0.3);
}
.sv-text-portlet table.sol-table-decoration thead th p {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-weight: 700;
}
.sv-text-portlet table.sol-table-decoration thead th:last-child {
  border: none;
  border-top-right-radius: 4px;
}
.sv-text-portlet table.sol-table-decoration thead th:first-child {
  border-top-left-radius: 4px;
}
.sv-text-portlet table.sol-table-decoration tbody {
  background-color: #fff;
}
.sv-text-portlet table.sol-table-decoration tbody tr td {
  border-right: 1px solid rgba(42, 128, 156, 0.15);
  padding: 11px 18px;
}
.sv-text-portlet table.sol-table-decoration tbody tr td p {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.sv-text-portlet table.sol-table-decoration tbody tr td:last-child {
  border: none;
}
.sv-text-portlet table.sol-table-decoration tbody tr:nth-child(odd) {
  background-color: #dfecf0;
}
.sol-widget-decoration {
  overflow: hidden;
  border-radius: 4px;
  background-color: #eaf2f5;
  box-shadow: 0 2px 2px rgba(38, 38, 38, 0.05);
  text-decoration: none;
}
.sol-widget-decoration .sv-mfpimage-portlet img {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  object-fit: cover;
  height: 170px;
  max-width: 100% !important;
}
@media (max-width: 991px) {
  .sol-widget-decoration .sv-mfpimage-portlet img {
    height: 130px;
  }
}
@media (max-width: 991px) {
  .sol-widget-decoration .sv-mfpimage-portlet img {
    height: 210px;
  }
}
@media (max-width: 767px) {
  .sol-widget-decoration .sv-mfpimage-portlet img {
    height: 180px;
  }
}
.sol-widget-decoration .sv-mfpimage-portlet img:before {
  content: '';
  background-color: #af1d35;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-widget-decoration .sol-widget-decoration-img {
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  height: 170px;
}
@media (max-width: 991px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    height: 124px;
  }
}
@media (max-width: 767px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    height: 250px;
  }
}
.sol-widget-decoration .sol-widget-decoration-img::before {
  content: '';
  background-color: #af1d35;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-widget-decoration .sv-text-portlet {
  position: relative;
  max-height: 133px;
  padding: 10px 20px 15px;
  font-size: 13px;
  line-height: 20px;
}
.sol-widget-decoration .sv-text-portlet::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 55px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff 85%);
}
.sol-widget-decoration .font-widget-heading {
  display: block;
  margin-bottom: 3px;
  color: #262626;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  text-decoration: none;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-widget-decoration .font-widget-heading a {
  font-size: 16px;
  text-decoration: none;
}
.sol-widget-decoration .font-widget-heading a:focus {
  outline: none !important;
  box-shadow: none !important;
}
.sol-widget-decoration .font-normal {
  margin-bottom: 0;
  line-height: 1.4em;
}
.sol-widget-decoration:hover .sol-widget-decoration-img::before {
  opacity: 0.5;
}
.sol-widget-decoration:hover .font-widget-heading {
  color: #af1d35;
  text-decoration: underline;
}
[data-whatinput="keyboard"] .sol-widget-decoration:focus-within {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black !important;
}
.sol-content-container {
  padding-top: 80px;
  -webkit-transition-property: padding-top;
  transition-property: padding-top;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-cookie-message {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 270px;
  background-color: rgba(38, 38, 38, 0.9);
  color: #fff;
  padding: 10px;
  font-size: 16px;
  min-height: 110px;
}
.sol-cookie-message:after,
.sol-cookie-message:before {
  content: "";
  display: table;
}
.sol-cookie-message:after {
  clear: both;
}
.sol-cookie-message a {
  color: #fff;
  text-decoration: underline;
}
.sol-cookie-message .sol-cookie-ok {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  padding: 10px 50px;
  border-radius: 8px;
  background-color: rgba(38, 38, 38, 0.9);
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  float: right;
  margin-top: 8px;
  margin-right: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput="keyboard"] .sol-cookie-message .sol-cookie-ok:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput="mouse"] .sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput="touch"] .sol-cookie-message .sol-cookie-ok:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-cookie-message .sol-cookie-message-content {
  max-width: 780px;
  color: #fff;
}
.sol-editor-bottom {
  bottom: 60px !important;
}
footer.sv-layout {
  overflow: auto;
  background-color: #af1d35;
  z-index: 1;
  padding-bottom: 20px;
  background-position: right;
  background-size: auto 85%;
}
@media (max-width: 767px) {
  footer.sv-layout {
    background-position-y: bottom;
    background-size: 90%;
  }
  footer.sv-layout .sv-grid-main.sv-fluid-grid .sv-column-4,
  footer.sv-layout .sv-grid-main.sv-fluid-grid .sv-column-8 {
    width: 100%;
  }
}
footer.sv-layout .sol-bottom .sv-grid-main .sv-row > .sv-column-4 {
  padding-right: 30px;
}
footer.sv-layout .sol-bottom h2,
footer.sv-layout .sol-bottom h3 {
  border-bottom: 2px solid white;
  color: white;
  font-size: 22px;
  margin-bottom: 10px !important;
  margin-top: 50px !important;
  font-weight: 500;
  line-height: 1.4;
}
footer.sv-layout .sol-bottom .sol-footer-social-media {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 10px 0;
}
footer.sv-layout .sol-bottom .sol-footer-social-media li {
  padding: 6px 6px;
  font-size: 35px;
}
footer.sv-layout .sol-bottom .sol-footer-social-media li i {
  transition: 0.2s;
  color: white;
}
footer.sv-layout .sol-bottom .sol-footer-social-media li:hover i {
  color: #e6e6e6;
}
footer.sv-layout .sol-bottom .font-normal {
  color: white;
  font-size: 18px;
}
footer.sv-layout .sol-bottom .font-normal a[rel="external"]::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f08e";
  margin-left: 10px;
  font-size: 12px;
}
footer.sv-layout .sol-bottom .font-normal a[rel="external"]::after.fa-pull-left {
  margin-right: 0.3em;
}
footer.sv-layout .sol-bottom .font-normal a[rel="external"]::after.fa-pull-right {
  margin-left: 0.3em;
}
footer.sv-layout .sol-bottom .font-normal a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  transition: 0.2s;
}
footer.sv-layout .sol-bottom .font-normal a:hover {
  text-decoration: underline;
  color: white;
}
footer.sv-layout .sol-bottom .font-normal a::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f054";
  margin: 0 10px 0 0;
}
footer.sv-layout .sol-bottom .font-normal a::before.fa-pull-left {
  margin-right: 0.3em;
}
footer.sv-layout .sol-bottom .font-normal a::before.fa-pull-right {
  margin-left: 0.3em;
}
footer.sv-layout .sol-bottom .env-button {
  background-color: white;
  border-radius: 5px;
  color: #af1d35;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 14px;
  margin: 10px 0;
  transition: 0.2s;
}
footer.sv-layout .sol-bottom .env-button::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f08d";
  margin: 0 10px 0 0;
}
footer.sv-layout .sol-bottom .env-button::before.fa-pull-left {
  margin-right: 0.3em;
}
footer.sv-layout .sol-bottom .env-button::before.fa-pull-right {
  margin-left: 0.3em;
}
footer.sv-layout .sol-bottom .env-button:hover {
  color: #af1d35;
  background-color: #e6e6e6;
}
footer.sv-layout .sol-bottom-phone .font-normal a::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f095";
  margin: 0 10px 0 0;
}
footer.sv-layout .sol-bottom-phone .font-normal a::before.fa-pull-left {
  margin-right: 0.3em;
}
footer.sv-layout .sol-bottom-phone .font-normal a::before.fa-pull-right {
  margin-left: 0.3em;
}
footer.sv-layout .sol-bottom-mail .font-normal a::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f0e0";
  margin: 0 10px 0 0;
}
footer.sv-layout .sol-bottom-mail .font-normal a::before.fa-pull-left {
  margin-right: 0.3em;
}
footer.sv-layout .sol-bottom-mail .font-normal a::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-shortcut-container {
  background-color: #e8f3f8;
  padding: 20px 20px 0;
}
.sol-shortcut {
  margin-left: -10px;
  margin-right: -10px;
}
.sol-shortcut:after,
.sol-shortcut:before {
  content: "";
  display: table;
}
.sol-shortcut:after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-shortcut {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.sol-shortcut a {
  text-decoration: none;
  max-width: 150px;
  display: inline-block;
}
.sol-shortcut-item {
  display: inline-block;
  float: left;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .sol-shortcut-item {
    width: 33%!important;
  }
}
@media (max-width: 479px) {
  .sol-shortcut-item {
    width: 49%!important;
  }
}
.sol-shortcut-item i.fal {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  border: 2px solid #af1d35;
  border-radius: 50%;
  background-color: #fff;
  color: #af1d35;
  font-size: 21px;
  text-align: center;
  line-height: 54px;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-shortcut-item a > span {
  color: #af1d35;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-shortcut-item a:focus i.fal,
.sol-shortcut-item a:hover i.fal {
  background-color: #41869f;
  color: #fff;
}
.sol-shortcut-item a:focus a > span,
.sol-shortcut-item a:hover a > span {
  color: #262626;
}
#sol_contact_modal_header .sol-modal-close,
#sol_contact_modal_footer .sol-modal-close {
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Quicksand', sans-serif;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
#sol_contact_modal_header .sol-modal-close:hover,
#sol_contact_modal_footer .sol-modal-close:hover {
  color: rgba(255, 255, 255, 0.8);
}
#sol_contact_modal_header .sol-modal-close i.fal,
#sol_contact_modal_footer .sol-modal-close i.fal {
  display: block;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-crisis-container {
  display: block;
  position: relative;
  width: 100%;
  background-color: #f4f4f4;
  background-color: #fff026;
  color: #262626;
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
@media (max-width: 991px) {
  .sol-crisis-container {
    height: auto;
  }
}
.sol-crisis-container .sv-row {
  position: relative;
  padding: 25px 25px 30px 155px;
  display: inline-block;
  -webkit-transition-property: padding;
  transition-property: padding;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 1199px) {
  .sol-crisis-container .sv-row {
    padding: 25px 25px 30px 100px;
  }
}
@media (max-width: 991px) {
  .sol-crisis-container .sv-row {
    padding: 20px 20px 20px 115px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sv-row {
    padding: 20px 20px 20px 60px;
  }
}
.sol-crisis-container i.fa-exclamation-triangle {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -55px;
  font-size: 70px;
  line-height: 100px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 991px) {
  .sol-crisis-container i.fa-exclamation-triangle {
    left: 30px;
    top: 20px;
    margin-top: 0;
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container i.fa-exclamation-triangle {
    left: 20px;
    font-size: 25px;
    line-height: 30px;
  }
}
.sol-crisis-container .sol-crisis-container {
  display: flex;
  flex-direction: column;
}
.sol-crisis-container .sol-crisis-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.sol-crisis-container .sol-crisis-message:after,
.sol-crisis-container .sol-crisis-message:before {
  content: "";
  display: table;
}
.sol-crisis-container .sol-crisis-message:after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message {
    display: block;
  }
}
.sol-crisis-container .sol-crisis-message-title {
  display: block;
  flex: 0 0 100%;
  margin-top: 0;
  margin-right: 20px;
  margin-bottom: 10px;
  color: #262626;
  font-size: 25px;
  font-weight: 700;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-title {
    width: 100%;
    font-size: 20px;
  }
}
.sol-crisis-container .sol-crisis-message p.font-description,
.sol-crisis-container .sol-crisis-message p.font-description a {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message p.font-description,
  .sol-crisis-container .sol-crisis-message p.font-description a {
    font-size: 15px;
    line-height: 20px;
  }
}
.sol-crisis-container .sol-crisis-message-desc {
  flex: 1 0 auto;
  color: #262626;
  line-height: 1.4em;
  max-width: 700px;
  margin-right: 30px;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sol-crisis-container .sol-crisis-message-desc {
    max-width: 545px;
  }
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-desc {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-desc {
    font-size: 15px;
    line-height: 20px;
  }
}
.sol-crisis-container .sol-crisis-message-more {
  text-align: right;
  flex-basis: 220px;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-more {
    text-align: left;
  }
}
.sol-crisis-container .sol-crisis-message-more a {
  padding: 4px 8px;
  margin-right: 10px;
  border: 1px solid rgba(38, 38, 38, 0.1);
  border-radius: 8px;
  background-color: rgba(38, 38, 38, 0.1);
  color: #262626;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-more a {
    font-size: 14px;
  }
}
.sol-crisis-container .sol-crisis-message-more a:hover {
  background-color: transparent;
}
.sol-crisis-container .sol-toggle-crisis {
  position: absolute;
  top: calc(50% - 11px);
  right: 0px;
  z-index: 10;
  color: #262626;
  cursor: pointer;
  font-weight: 500;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-toggle-crisis {
    font-size: 0;
    right: 7px;
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-toggle-crisis {
    right: 7px;
  }
}
.sol-crisis-container .sol-toggle-crisis i.fal {
  margin-left: 10px;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-toggle-crisis i.fal {
    font-size: 20px;
  }
}
.sol-crisis-container.sol-crisis--small {
  height: 52px;
}
.sol-crisis-container.sol-crisis--small .sv-row {
  padding: 15px 25px 10px 155px;
}
.sol-crisis-container.sol-crisis--small i.fa-exclamation-circle {
  font-size: 35px;
  line-height: 60px;
  left: 90px;
  margin-top: -29px;
}
@media (max-width: 991px) {
  .sol-crisis-container.sol-crisis--small i.fa-exclamation-circle {
    margin-top: -24px;
  }
}
.sol-crisis-container.sol-crisis--small .sol-crisis-message {
  margin-bottom: 10px;
}
.sol-crisis-container.sol-crisis--small .sol-crisis-message-title {
  margin-bottom: 0;
}
.sol-crisis-container.sol-crisis--small .sol-crisis-message p {
  display: none;
}
.sol-crisis-container.sol-crisis--small .sol-crisis-message-more {
  display: none;
}
.sol-no-script i.fa-warning {
  font-size: 50px;
  margin-top: -57px;
}
.sol-no-script .sv-row {
  padding: 15px 20px 0 90px;
}
.sol-no-script .sol-crisis-message-title {
  margin-bottom: 5px;
}
header {
  position: fixed;
  width: 100%;
  z-index: 50;
}
header .sol-top {
  padding: 15px 45px;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}
@media (max-width: 991px) {
  header .sol-top {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 13px;
    padding-bottom: 8px;
  }
}
header .sol-top .sol-top-logo-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  padding-top: 12px;
}
@media only screen and (max-width: 991px) {
  header .sol-top .sol-top-logo-container {
    left: initial;
  }
}
@media (max-width: 991px) {
  header .sol-top .sol-top-logo-container {
    text-align: right;
  }
}
header .sol-top .sol-top-logo > img {
  height: 60px;
  margin-top: 2px;
}
@media screen and (max-width: 991px) and (min-width: 370px) {
  header .sol-top .sol-top-logo > img {
    height: 53px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 410px) and (min-width: 370px) {
  header .sol-top .sol-top-logo > img {
    height: 50px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 370px) and (min-width: 350px) {
  header .sol-top .sol-top-logo > img {
    height: 46px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 350px) and (min-width: 330px) {
  header .sol-top .sol-top-logo > img {
    height: 42px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 330px) {
  header .sol-top .sol-top-logo > img {
    height: 30px;
    margin-right: 10px;
    margin-top: 7px;
  }
}
header + div + div + div.sv-layout {
  padding-top: 80px;
}
header .sol-bottom {
  background-color: #af1d35;
  text-align: center;
}
@media (max-width: 991px) {
  header .sol-bottom {
    display: none;
  }
}
.sol-to-content {
  position: absolute;
  margin-left: 100%;
  right: 100%;
  top: -3px;
  margin-right: 20px;
  width: 0;
  height: 0;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: #af1d35;
  font-size: 14px;
  color: #fff;
}
.sol-to-content:focus {
  width: auto;
  height: auto;
}
@media (max-width: 767px) {
  .sol-to-content:focus {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }
}
.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: #fff;
}
.sol-mobile-header > div {
  position: relative;
  max-width: 728px;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .sol-mobile-header > div {
    height: 55px;
  }
}
@media (max-width: 991px) {
  .sol-mobile-header {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-mobile-header {
    background-color: #af1d35;
  }
  .sol-mobile-header .sol-mobile-logo {
    display: none;
  }
}
.sol-mobile-header .sol-mobile-logo {
  float: left;
  margin-left: 15px;
  margin-top: 11px;
  height: 30px;
}
.sol-hamburger-wrapper {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #af1d35;
  border: 0;
  text-align: center;
  padding-top: 43px;
  z-index: 9;
}
.sol-hamburger-wrapper:focus,
[data-whatinput="keyboard"] .sol-hamburger-wrapper:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-hamburger-wrapper:focus,
[data-whatinput="mouse"] .sol-hamburger-wrapper:focus,
[data-whatinput="touch"] .sol-hamburger-wrapper:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-hamburger-wrapper:hover {
  cursor: pointer;
}
@media only screen and (min-width: 992px) {
  .sol-hamburger-wrapper {
    display: none;
  }
}
.sol-hamburger-wrapper .sol-hamburger {
  width: 40px;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -20px;
}
.sol-hamburger-wrapper .sol-hamburger > span {
  display: block;
  width: 40px;
  height: 2px;
  background-color: #fff;
  transition: transform 500ms;
}
.sol-hamburger-wrapper .sol-hamburger .sol-hamburger-middle {
  margin-top: 8px;
  margin-bottom: 8px;
  transition: width 500ms;
}
@media (max-width: 767px) {
  .sol-hamburger-wrapper .sol-hamburger {
    right: 10px;
  }
}
.sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-top {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
  width: 38px;
  top: 1px;
  position: relative;
}
.sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-middle {
  width: 0;
}
.sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-bottom {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
  width: 38px;
}
.sol-hamburger-wrapper .sol-hamburger-text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 1px;
}
.sol-mobile-menu-modal-mask {
  position: fixed;
  z-index: 9998;
  left: 0;
  width: 100vw;
  transition: opacity 0.3s ease;
  top: 80px !important;
  display: block;
  height: auto;
  min-height: 100vh;
}
.sol-mobile-menu-modal-close {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  cursor: pointer;
  padding: 0;
}
.sol-mobile-menu-modal-close i {
  display: none;
  margin-bottom: 5px;
  font-size: 34px;
}
.sol-mobile-menu-modal-container {
  transition: opacity 0.3s ease;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #af1d35;
  padding: 15px 15px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sol-mobile-menu-modal-container:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-mobile-menu-modal-transition-enter {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-leave-active {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-enter .sh-mobile-menu-modal-container {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-leave-active .sh-mobile-menu-modal-container {
  opacity: 0;
}
.sol-mobile-menu h1,
.sol-mobile-menu h2 {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #fff;
  font-size: 20px;
}
.sol-mobile-menu h2 {
  font-size: 30px;
}
.sol-mobile-menu__item {
  position: relative;
  background-color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: #262626;
  border-bottom: 1px solid #f4f4f4;
}
.sol-mobile-menu__item a {
  color: #262626;
  display: block;
  padding: 0 58px 0 18px;
  line-height: 50px;
  height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
}
.sol-mobile-menu__item a:hover {
  color: #262626;
}
.sol-mobile-menu__item button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  font-size: 22px;
  height: 44px;
  width: 44px;
  text-align: center;
  color: #262626;
  position: absolute;
  right: 3px;
  top: 3px;
}
.sol-mobile-menu__item button::before {
  content: '';
  position: absolute;
  left: -7px;
  top: -7px;
  bottom: -7px;
  right: -7px;
}
.sol-mobile-menu__item button i.fal {
  line-height: 35px;
  font-size: 24px;
}
.sol-mobile-menu__back {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  align-items: center;
  padding: 10px 20px 10px 10px;
  border-radius: 8px;
}
.sol-mobile-menu__back i.fal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  background-color: transparent;
  color: #fff;
}
.sol-mobile-menu__list {
  position: absolute;
  left: 15px;
  right: 15px;
  padding-bottom: 125px;
}
.sol-mobile-menu__links {
  text-align: left;
  padding-top: 25px;
  margin-left: 20px;
  margin-bottom: 30px;
}
.sol-mobile-menu__links a,
.sol-mobile-menu__links button {
  display: block;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.6px;
  color: #fff;
  margin-bottom: 25px;
  text-decoration: none;
}
.sol-mobile-menu__links a:hover,
.sol-mobile-menu__links button:hover {
  color: #fff;
  text-decoration: underline;
}
.sol-mobile-menu__links a i,
.sol-mobile-menu__links button i {
  font-size: 20px;
  margin-right: 10px;
}
.sol-mobile-menu__links a i.fa-volume-up::before,
.sol-mobile-menu__links button i.fa-volume-up::before {
  content: '\f025';
}
.sol-mobile-menu__links button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
}
.sol-mobile-menu__startpage-nav .sol-mobile-menu__item button {
  height: 50px;
  width: 100%;
  top: 0;
  right: 0;
  text-align: right;
  padding-right: 20px;
}
.sol-mobile-search {
  display: none;
}
.sol-tool-nav-item .sol-js-toggle-search {
  display: none;
}
@media only screen and (max-width: 991px) and (min-width: 275px) {
  .sol-tool-nav-item .sol-js-toggle-search {
    display: block;
    margin-left: 30px;
  }
  .sol-tool-nav-item .sol-js-toggle-search i.fa-search {
    margin-bottom: 10px;
    margin-top: 1px;
  }
}
@media only screen and (max-width: 275px) and (max-width: 767px) {
  .sol-tool-nav-item .sol-js-toggle-search {
    display: block;
    margin-left: 19px;
  }
  .sol-tool-nav-item .sol-js-toggle-search i.fa-search {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 410px) and (min-width: 370px) {
  .sol-tool-nav-item .sol-js-toggle-search i.fa-search {
    font-size: 25px;
  }
}
@media only screen and (max-width: 370px) and (min-width: 350px) {
  .sol-tool-nav-item .sol-js-toggle-search i.fa-search {
    font-size: 20px;
  }
}
@media only screen and (max-width: 350px) {
  .sol-tool-nav-item .sol-js-toggle-search i.fa-search {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-item .sol-js-show-box {
    margin-left: -8px;
  }
}
.sol-tool-nav-item .sol-js-show-box i.fal,
.sol-tool-nav-item .sol-js-show-box i.fas,
.sol-tool-nav-item .sol-js-show-box i.fab {
  margin-bottom: 10px;
}
@media only screen and (max-width: 410px) and (min-width: 370px) {
  .sol-tool-nav-item .sol-js-show-box i.fal,
  .sol-tool-nav-item .sol-js-show-box i.fas,
  .sol-tool-nav-item .sol-js-show-box i.fab {
    font-size: 25px;
  }
}
@media only screen and (max-width: 370px) and (min-width: 350px) {
  .sol-tool-nav-item .sol-js-show-box i.fal,
  .sol-tool-nav-item .sol-js-show-box i.fas,
  .sol-tool-nav-item .sol-js-show-box i.fab {
    font-size: 20px;
  }
}
@media only screen and (max-width: 350px) {
  .sol-tool-nav-item .sol-js-show-box i.fal,
  .sol-tool-nav-item .sol-js-show-box i.fas,
  .sol-tool-nav-item .sol-js-show-box i.fab {
    font-size: 17px;
  }
}
.sol-top-nav {
  display: inline-block;
  vertical-align: top;
}
.sol-top-nav:after,
.sol-top-nav:before {
  content: "";
  display: table;
}
.sol-top-nav:after {
  clear: both;
}
.sol-top-nav-item {
  float: left;
  padding: 10px 12px 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 700;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  vertical-align: top;
  text-decoration: none;
  max-width: 125px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-top-nav-item:focus,
[data-whatinput="keyboard"] .sol-top-nav-item:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-top-nav-item:focus,
[data-whatinput="mouse"] .sol-top-nav-item:focus,
[data-whatinput="touch"] .sol-top-nav-item:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-top-nav-item:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.sol-top-nav-item:hover {
  background-color: rgba(58, 146, 179, 0.25);
}
.sol-top-nav-item.sol-active {
  background-color: rgba(58, 146, 179, 0.6);
}
.sol-tool-nav {
  float: right;
  margin-top: -4px;
  position: relative;
}
@media (max-width: 991px) {
  .sol-tool-nav {
    display: inline-block;
    vertical-align: top;
    float: none;
    margin-top: 0;
    margin-left: 55px;
  }
}
.sol-tool-nav-item {
  display: block;
  float: left;
}
.sol-tool-nav-item > a,
.sol-tool-nav-item > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  margin-left: 10px;
  font-weight: 400;
  color: #262626;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  display: block;
}
.sol-tool-nav-item > a:focus,
.sol-tool-nav-item > button:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item > a:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item > button:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-tool-nav-item > a:focus,
[data-whatinput="initial"] .sol-tool-nav-item > button:focus,
[data-whatinput="mouse"] .sol-tool-nav-item > a:focus,
[data-whatinput="mouse"] .sol-tool-nav-item > button:focus,
[data-whatinput="touch"] .sol-tool-nav-item > a:focus,
[data-whatinput="touch"] .sol-tool-nav-item > button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
@media (max-width: 991px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    display: none;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    color: #262626;
    margin: 0 9px 9px;
  }
}
.sol-tool-nav-item > a i.fal,
.sol-tool-nav-item > button i.fal {
  color: #af1d35;
  font-size: 30px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.sol-tool-nav-item .sol-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #262626;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  padding: 0;
}
.sol-tool-nav-item .sol-close:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item .sol-close:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-tool-nav-item .sol-close:focus,
[data-whatinput="mouse"] .sol-tool-nav-item .sol-close:focus,
[data-whatinput="touch"] .sol-tool-nav-item .sol-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-tool-nav-item .sol-close i.fal {
  font-size: 11px;
}
.sol-tool-nav-item .sol-translate-link {
  background-color: #2A809C;
  color: #fff;
  margin-top: 10px;
  padding: 8px;
  width: 100%;
}
.sol-tool-nav-item #google_translate_element {
  margin-top: 15px;
  border-top: 1px solid lightgrey;
  padding-top: 15px;
}
.sol-tool-nav-box {
  position: absolute;
  width: 189px;
  margin-top: 10px;
  margin-left: -60px;
  padding: 17px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1;
  cursor: default;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-tool-nav-box {
    margin-left: 0;
    right: 45px;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-box {
    left: 50%;
    margin-left: -109px;
    width: 215px;
    padding: 12px;
  }
}
.sol-tool-nav-box a.sol-btn-round {
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  color: #3a92b3;
  border: 2px solid #3a92b3;
  border-radius: 50%;
  font-size: 15px;
  padding-top: 1px;
  text-align: center;
}
@media (max-width: 767px) {
  .sol-tool-nav-box a.sol-btn-round {
    width: 40px;
    height: 40px;
    font-size: 21px;
    padding-top: 0;
    line-height: 37px;
  }
}
.sol-tool-nav-box a.sol-btn-round:first-child {
  margin-left: 0;
}
.sol-tool-nav-box a.sol-btn-round:last-child {
  margin-right: 0;
}
.sol-tool-nav-box a.sol-btn-round:hover {
  border-color: #9e9e9e;
}
.sol-tool-nav-box-translate {
  padding: 12px 17px 9px;
  width: 265px;
  margin-left: -140px;
  box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}
@media (max-width: 991px) {
  .sol-tool-nav-box-translate {
    right: -60px;
    left: unset;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-box-translate {
    margin-top: -3px;
  }
}
.sol-tool-nav-box-translate p {
  margin-bottom: 15px;
}
.sol-tool-nav-box-translate a {
  padding-bottom: 15px;
  display: block;
  margin: auto;
  width: max-content;
  text-decoration: none;
  text-align: center;
}
.sol-tool-nav-box-translate a:hover {
  text-decoration: underline;
}
.rsbtn {
  position: relative;
  display: inline-block;
  z-index: 10010;
  top: 300px;
  width: 355px!important;
  padding: 0px !important;
}
.rsbtn > a {
  display: none !important;
}
.rsbtn .rsbtn_tooltoggle {
  opacity: 0;
}
.rsbtn .rsbtn_dragbar {
  display: none !important;
}
.rsexpanded {
  width: 100% !important;
  position: fixed !important;
}
.rsexpanded > a {
  display: block !important;
}
.rsexpanded .rsbtn_tooltoggle {
  opacity: 1;
}
.rsexpanded .rsbtn_dragbar {
  display: block !important;
}
.sol-hamburger-megamenu-wrapper {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #af1d35;
  border: 0;
  text-align: center;
  padding-top: 43px;
  z-index: 9;
}
.sol-hamburger-megamenu-wrapper:focus,
[data-whatinput="keyboard"] .sol-hamburger-megamenu-wrapper:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-hamburger-megamenu-wrapper:focus,
[data-whatinput="mouse"] .sol-hamburger-megamenu-wrapper:focus,
[data-whatinput="touch"] .sol-hamburger-megamenu-wrapper:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-hamburger-megamenu-wrapper:hover {
  cursor: pointer;
}
@media (max-width: 991px) {
  .sol-hamburger-megamenu-wrapper {
    display: none;
  }
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu {
  width: 40px;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -20px;
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu > span {
  display: block;
  width: 40px;
  height: 2px;
  background-color: #fff;
  transition: transform 500ms;
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu .sol-hamburger-megamenu-middle {
  margin-top: 8px;
  margin-bottom: 8px;
  transition: width 500ms;
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu.sol-active .sol-hamburger-megamenu-top {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
  width: 38px;
  top: 1px;
  position: relative;
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu.sol-active .sol-hamburger-megamenu-middle {
  width: 0;
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu.sol-active .sol-hamburger-megamenu-bottom {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
  width: 38px;
}
@media (max-width: 767px) {
  .sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu {
    right: 10px;
  }
}
.sol-hamburger-megamenu-wrapper .sol-hamburger-megamenu-text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 1px;
}
.sol-top-search-field {
  position: relative;
}
.sol-top-search-field input[type=search],
.sol-top-search-field input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  -webkit-transition-property: border-color;
  transition-property: border-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  width: 100%;
  height: 75px;
  padding: 0 75px 0 25px;
  background-color: #fff;
  color: #757575;
  font-family: 'Quicksand', sans-serif;
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
  border-radius: 0;
}
.sol-top-search-field input[type=search]:focus,
.sol-top-search-field input[type=text]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
@media (max-width: 767px) {
  .sol-top-search-field input[type=search],
  .sol-top-search-field input[type=text] {
    height: 60px;
    font-size: 21px;
  }
}
@media (max-width: 479px) {
  .sol-top-search-field input[type=search],
  .sol-top-search-field input[type=text] {
    font-size: 16px;
    border-radius: 0;
  }
}
@media only screen and (max-width: 329px) {
  .sol-top-search-field input[type=search],
  .sol-top-search-field input[type=text] {
    font-size: 11px;
  }
}
.sol-top-search-field input[type=search]:focus,
.sol-top-search-field input[type=text]:focus {
  border-color: #e7e7e7;
  box-shadow: inset 0 0px 0px 2px #af1d35;
}
@media (max-width: 991px) {
  .sol-top-search-field input[type=search]:focus,
  .sol-top-search-field input[type=text]:focus {
    box-shadow: none;
  }
}
.sol-top-search-field button[type=submit],
.sol-top-search-field button.sol-js-toggle-search {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 75px;
  background-color: transparent;
  color: #fff;
  color: #262626;
  font-family: 'Quicksand', sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  display: block;
}
.sol-top-search-field button[type=submit]:focus,
.sol-top-search-field button.sol-js-toggle-search:focus,
[data-whatinput="keyboard"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="keyboard"] .sol-top-search-field button.sol-js-toggle-search:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="initial"] .sol-top-search-field button.sol-js-toggle-search:focus,
[data-whatinput="mouse"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="mouse"] .sol-top-search-field button.sol-js-toggle-search:focus,
[data-whatinput="touch"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="touch"] .sol-top-search-field button.sol-js-toggle-search:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
@media (max-width: 767px) {
  .sol-top-search-field button[type=submit],
  .sol-top-search-field button.sol-js-toggle-search {
    width: 60px;
    height: 60px;
  }
}
.sol-top-search-field button[type=submit] > i.fal,
.sol-top-search-field button.sol-js-toggle-search > i.fal {
  position: relative;
  top: 11px;
  font-size: 25px;
  font-weight: 300;
  width: 60px;
  padding-bottom: 20px;
}
.sol-top-search-field > div:hover input[type=search],
.sol-top-search-field > div:hover input[type=text] {
  border-color: #e7e7e7;
}
.sol-top-search-field > div:hover button {
  background-color: #af1d35;
  color: #fff;
}
header .sol-top .sol-top-search-field {
  position: absolute;
  top: 0;
  left: 80px;
  width: 360px;
  z-index: 9;
  -webkit-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 1199px) {
  header .sol-top .sol-top-search-field {
    width: 280px;
  }
}
header .sol-top .sol-top-search-field input[type=search],
header .sol-top .sol-top-search-field input[type=text] {
  height: 50px;
  border: 1px solid #af1d35;
  border-left: 8px solid #af1d35;
  padding: 0 0 0 15px;
  font-size: 16px;
  color: #262626;
  margin: 15px;
}
header .sol-top .sol-top-search-field button[type=submit] {
  background-color: transparent;
  top: 0;
  right: -17px;
  color: #262626;
  height: 78px;
}
.sol-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 0 20px 30px 190px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  min-height: 140px;
}
@media (max-width: 767px) {
  .sol-article-item {
    padding-top: 190px;
    padding-right: 20px;
    padding-left: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
  }
}
.sol-article-item.sol-no-img {
  padding-left: 0;
  min-height: auto;
}
@media (max-width: 767px) {
  .sol-article-item.sol-no-img {
    padding-top: 20px;
  }
}
.sol-article-item .sol-article-item-img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 180px;
  height: 140px;
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
  border-radius: 8px;
  object-fit: cover;
  object-position: 50%;
}
@media (max-width: 991px) {
  .sol-article-item .sol-article-item-img {
    width: 160px;
  }
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-img {
    width: 100%;
    height: 180px;
  }
}
.sol-article-item .sol-article-item-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  border-radius: 8px;
}
.sol-article-item .sol-article-item-date {
  color: #727272;
  margin-bottom: 3px;
  font-weight: 400;
  font-size: 14px;
}
.sol-article-item .sol-article-item-heading {
  display: block;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;
  line-height: 32px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-article-item .sol-article-item-desc {
  font-weight: 400;
}
.sol-article-item .sol-article-item-event-info {
  margin-top: 10px;
}
.sol-article-item .sol-article-item-event-info:after,
.sol-article-item .sol-article-item-event-info:before {
  content: "";
  display: table;
}
.sol-article-item .sol-article-item-event-info:after {
  clear: both;
}
.sol-article-item .sol-article-item-event-info-item {
  margin-right: 13px;
  font-size: 11px;
  float: left;
}
.sol-article-item .sol-article-item-event-info-item i {
  margin-right: 6px;
  color: #af1d35;
}
.sol-article-item:hover .sol-article-item-img:before {
  background-color: rgba(175, 29, 53, 0.4);
}
.sol-article-item:hover .sol-article-item-heading {
  color: #af1d35;
  text-decoration: underline;
}
.sol-article-item.sol-event-item:hover .sol-article-item-heading {
  text-decoration: none;
  color: #262626;
}
.sol-event-info .sol-simple-box-decoration {
  margin-top: 0;
  padding-top: 0;
  background: rgba(223, 236, 240, 0.7);
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 15px 20px;
  border: 0;
}
.sol-event-info-field {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}
.sol-event-info-field > span {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}
.sol-event-info-small {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: 8px;
  color: #262626;
  background: #dfecf0;
}
.sol-event-info-small:after,
.sol-event-info-small:before {
  content: "";
  display: table;
}
.sol-event-info-small:after {
  clear: both;
}
.sol-event-info-small-item {
  margin-right: 10px;
  font-size: 16px;
  float: left;
  position: relative;
  margin-left: 20px;
}
.sol-event-info-small-item i {
  margin-right: 6px;
  color: #af1d35;
}
.sol-event-info-small-item.date::before {
  font-weight: 900;
  display: block;
  content: "\f133";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  width: 20px;
  height: 20px;
  padding-left: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  left: -20px;
  top: 1px;
  box-sizing: border-box;
}
.sol-event-info-small-item.date::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.date::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.date::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.date::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.time::before {
  font-weight: 900;
  display: block;
  content: "\f017";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  width: 20px;
  height: 20px;
  padding-left: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  left: -20px;
  top: 1px;
  box-sizing: border-box;
}
.sol-event-info-small-item.time::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.time::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.time::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.time::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.place::before {
  font-weight: 900;
  display: block;
  content: "\f041";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  width: 20px;
  height: 20px;
  padding-left: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  left: -20px;
  top: 1px;
  box-sizing: border-box;
}
.sol-event-info-small-item.place::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.place::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.place::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.place::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.sol-event-info-small-categories::before {
  font-weight: 900;
  display: block;
  content: "\f02b";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  width: 20px;
  height: 20px;
  padding-left: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  left: -20px;
  top: 1px;
  box-sizing: border-box;
}
.sol-event-info-small-item.sol-event-info-small-categories::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.sol-event-info-small-categories::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-item.sol-event-info-small-categories::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-info-small-item.sol-event-info-small-categories::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-info-small-categories span:after {
  content: '/';
  margin-left: 4px;
}
.sol-event-info-small-categories span:last-child:after {
  content: '';
  margin-left: 0;
}
.sol-event-listing li {
  display: flex;
  align-items: center;
}
.sol-event-item {
  display: flex;
  align-items: stretch;
  margin-top: 0;
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-item:hover {
  opacity: 0.9;
}
.sol-event-item:hover .sol-event-item-content {
  text-decoration: underline;
}
.sol-event-item-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #2A809C;
  color: #fff;
  width: 80px;
  border-radius: 16px;
  border: 1px solid #2A809C;
  flex: 0 0 80px;
  margin-right: 10px;
}
.sol-event-item-day {
  font-size: 20px;
  font-weight: 500;
}
.sol-event-item-content {
  padding: 4px 10px;
  text-align: left;
  display: inline-block;
  padding-top: 4px;
  background-color: #F1F1F1;
  width: 100%;
  min-height: 80px;
  border-radius: 5px;
}
.sol-event-item-heading {
  display: block;
  font-size: 16px !important;
  font-weight: 300;
  font-family: 'Quicksand', sans-serif;
  margin-top: -4px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-event-item-heading {
    line-height: 27px;
  }
}
.sol-event-item-desc {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1.4em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sol-event-item-info {
  padding: 0;
  margin-bottom: 2px;
}
.sol-event-item-info:after,
.sol-event-item-info:before {
  content: "";
  display: table;
}
.sol-event-item-info:after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-event-item-info {
    margin-bottom: 4px;
  }
}
.sol-event-item-info-item {
  display: inline-block;
  font-size: 12px;
  margin-right: 0px;
  color: #262626;
}
.sol-event-item-info-item:after {
  content: '/';
}
.sol-event-item-info-item:last-child:after {
  content: '';
}
.sol-solid-events {
  margin-bottom: -13px;
}
.sol-article-filter {
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .sol-article-filter {
    display: block;
  }
}
.sol-article-filter__text {
  font-size: 19px;
  padding-right: 10px;
  padding-top: 15px;
}
@media (max-width: 767px) {
  .sol-article-filter__text {
    margin-bottom: 10px;
  }
}
.sol-article-filter__text i.fa,
.sol-article-filter__text i.far,
.sol-article-filter__text i.fal,
.sol-article-filter__text i.fab,
.sol-article-filter__text i.fas {
  margin-right: 5px;
  font-size: 22px;
}
.sol-article-filter__select {
  flex: 1;
  display: flex;
}
@media (max-width: 767px) {
  .sol-article-filter__select {
    display: block;
  }
}
.sol-article-filter select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
  margin-right: 10px;
  background-color: #2A809C;
  color: #fff;
  border-radius: 8px;
  height: 50px;
  border-color: transparent;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down-neg.svg');
  background-position: right 15px top 17px;
}
.sol-article-filter select::-webkit-input-placeholder {
  color: #313131;
}
.sol-article-filter select:-moz-placeholder {
  color: #313131;
}
.sol-article-filter select::-moz-placeholder {
  color: #313131;
}
.sol-article-filter select:-ms-input-placeholder {
  color: #313131;
}
.sol-article-filter select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-article-filter select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-article-filter select:focus {
  background-color: #fff;
  border-color: black;
}
.sol-article-filter select::-ms-clear,
.sol-article-filter select::-webkit-search-cancel-button,
.sol-article-filter select::-webkit-search-decoration,
.sol-article-filter select::-webkit-search-results-button,
.sol-article-filter select::-webkit-search-results-decoration {
  display: none;
}
@media (max-width: 767px) {
  .sol-article-filter select {
    margin: 0 0 10px 0;
  }
}
.sol-article-filter select:hover,
.sol-article-filter select:focus {
  background-color: #3f8da6;
  color: #fff;
}
.sol-article-filter .sol-clear-filter {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  border: solid 2px transparent;
  background-color: #2A809C;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 6px 16px;
  min-width: 0;
  font-size: 13px;
  font-weight: 500;
  min-height: 33px;
  line-height: 33px;
  padding: 0 15px;
  padding: 0;
  flex: 0 0 40px;
}
.sol-article-filter .sol-clear-filter:focus,
[data-whatinput="keyboard"] .sol-article-filter .sol-clear-filter:focus {
  outline: 2px solid black;
  outline-offset: -2px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-article-filter .sol-clear-filter:focus,
[data-whatinput="mouse"] .sol-article-filter .sol-clear-filter:focus,
[data-whatinput="touch"] .sol-article-filter .sol-clear-filter:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  z-index: inherit;
}
.sol-article-filter .sol-clear-filter:focus,
.sol-article-filter .sol-clear-filter [data-whatinput='keyboard']:focus {
  background-color: #f4f2f0;
  color: #262626;
}
[data-whatinput='initial'] .sol-article-filter .sol-clear-filter:focus,
[data-whatinput='mouse'] .sol-article-filter .sol-clear-filter:focus,
[data-whatinput='touch'] .sol-article-filter .sol-clear-filter:focus {
  color: #fff;
  background-color: #3f8da6;
}
.sol-article-filter .sol-clear-filter:hover {
  color: #fff;
  background-color: #3f8da6;
}
.sol-article-filter .sol-clear-filter i.fa,
.sol-article-filter .sol-clear-filter i.far,
.sol-article-filter .sol-clear-filter i.fal,
.sol-article-filter .sol-clear-filter i.fab,
.sol-article-filter .sol-clear-filter i.fas {
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
  position: relative;
  top: 1px;
}
@media (max-width: 767px) {
  .sol-article-filter .sol-clear-filter {
    display: block;
    width: 100%;
  }
}
.sol-article-filter .sol-clear-filter i.fa,
.sol-article-filter .sol-clear-filter i.far,
.sol-article-filter .sol-clear-filter i.fal,
.sol-article-filter .sol-clear-filter i.fab,
.sol-article-filter .sol-clear-filter i.fas {
  margin-right: 0;
  font-size: 19px;
}
.sol-clear-filter.font-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px 12px 25px;
  margin: 0 10px 20px 0;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
}
.sol-show-more {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.sol-show-more button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  border: solid 2px transparent;
  background-color: #2A809C;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.sol-show-more button:focus,
[data-whatinput="keyboard"] .sol-show-more button:focus {
  outline: 2px solid black;
  outline-offset: -2px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-show-more button:focus,
[data-whatinput="mouse"] .sol-show-more button:focus,
[data-whatinput="touch"] .sol-show-more button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  z-index: inherit;
}
.sol-show-more button:focus,
.sol-show-more button [data-whatinput='keyboard']:focus {
  background-color: #f4f2f0;
  color: #262626;
}
[data-whatinput='initial'] .sol-show-more button:focus,
[data-whatinput='mouse'] .sol-show-more button:focus,
[data-whatinput='touch'] .sol-show-more button:focus {
  color: #fff;
  background-color: #3f8da6;
}
.sol-show-more button:hover {
  color: #fff;
  background-color: #3f8da6;
}
.sol-show-more button i.fa,
.sol-show-more button i.far,
.sol-show-more button i.fal,
.sol-show-more button i.fab,
.sol-show-more button i.fas {
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
  position: relative;
  top: 1px;
}
/* lessfile: articles/listing.less */
.sol-article-listing ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sol-article-listing-result {
  position: relative;
}
.sol-startpage-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  min-height: 120px;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
}
.sol-startpage-article-item.sol-no-img {
  padding-left: 0;
  min-height: auto;
}
@media (max-width: 767px) {
  .sol-startpage-article-item.sol-no-img {
    padding-top: 20px;
  }
}
.sol-startpage-article-item .sol-startpage-article-item-img {
  height: 200px;
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
  object-fit: cover;
  object-position: 50%;
}
@media (max-width: 767px) {
  .sol-startpage-article-item .sol-startpage-article-item-img {
    height: 180px;
  }
}
.sol-startpage-article-item .sol-startpage-article-item-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  border-radius: 8px;
}
.sol-startpage-article-item .sol-startpage-article-item-date {
  color: #727272;
  margin-bottom: 3px;
  font-weight: 400;
  font-size: 14px;
}
.sol-startpage-article-item .sol-startpage-article-item-date::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f017";
  font-size: 12px;
  margin-right: 8px;
}
.sol-startpage-article-item .sol-startpage-article-item-date::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-startpage-article-item .sol-startpage-article-item-date::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-startpage-article-item .sol-startpage-article-item-heading {
  display: block;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Quicksand', sans-serif;
  line-height: 25px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-startpage-article-item .sol-startpage-article-item-desc {
  font-weight: 400;
}
.sol-startpage-article-item .sol-startpage-article-item-event-info {
  margin-top: 10px;
}
.sol-startpage-article-item .sol-startpage-article-item-event-info:after,
.sol-startpage-article-item .sol-startpage-article-item-event-info:before {
  content: "";
  display: table;
}
.sol-startpage-article-item .sol-startpage-article-item-event-info:after {
  clear: both;
}
.sol-startpage-article-item .sol-startpage-article-item-event-info-item {
  margin-right: 13px;
  font-size: 11px;
  float: left;
}
.sol-startpage-article-item .sol-startpage-article-item-event-info-item i {
  margin-right: 6px;
  color: #af1d35;
}
.sol-startpage-article-item:hover .sol-startpage-article-item-heading {
  color: #af1d35;
  text-decoration: underline;
}
.sol-startpage-article-firstItem {
  margin: 0 5px;
  display: flex;
}
.sol-startpage-article-firstItem a {
  min-height: auto;
  padding: 0;
}
.sol-startpage-article-firstItem a .sol-startpage-article-item-content {
  padding: 15px 10px;
}
.sol-startpage-article-firstItem a .sol-startpage-article-item-content .sol-startpage-article-item-date {
  padding-top: 5px;
}
.sol-startpage-article-firstItem .sol-startpage-article-item-img {
  border-radius: 5px 5px 0 0;
}
.sol-article-itemList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
}
@media (max-width: 991px) {
  .sol-article-itemList {
    display: flex;
    flex-direction: column;
  }
}
.sol-article-wrapper {
  max-width: 828px;
  margin-left: auto;
  margin-right: auto;
}
.sol-article-wrapper .sv-image-portlet,
.sol-article-wrapper .sv-mfpimage-portlet {
  position: relative;
  overflow: hidden;
}
.sol-article-wrapper .sv-image-portlet .sol-article-date,
.sol-article-wrapper .sv-mfpimage-portlet .sol-article-date {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 60px;
  padding-top: 15px;
  padding-left: 0px;
  border-radius: 8px;
  background-color: rgba(175, 29, 53, 0.8);
  color: #fff;
  text-align: center;
}
.sol-article-wrapper .sv-image-portlet .sol-article-date-day,
.sol-article-wrapper .sv-mfpimage-portlet .sol-article-date-day {
  display: block;
  font-size: 26px;
  line-height: 20px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .sol-article-wrapper .sv-image-portlet .sol-article-date-day,
  .sol-article-wrapper .sv-mfpimage-portlet .sol-article-date-day {
    font-size: 14px;
    line-height: 7px;
  }
}
.sol-article-wrapper .sv-image-portlet .sol-article-date-month,
.sol-article-wrapper .sv-mfpimage-portlet .sol-article-date-month {
  font-size: 16px;
  line-height: 18px;
  display: block;
}
.sol-article-wrapper .pagecontent:after,
.sol-article-wrapper .pagecontent:before {
  content: "";
  display: table;
}
.sol-article-wrapper .pagecontent:after {
  clear: both;
}
.sol-news-menu .sol-menu-item[data-expanded="false"] .sol-menu-list {
  display: none;
}
.sol-article__listing {
  max-width: inherit !important;
}
/* New listning */
.sol-article-listing {
  width: 100%;
}
.sol-breadcrumbs-wrapper {
  display: inherit;
  background-color: #f4f4f4;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after,
.sol-breadcrumbs-wrapper .sol-breadcrumbs:before {
  content: "";
  display: table;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after {
  clear: both;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li {
  float: left;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a,
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span {
  font-size: 14px;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a {
  text-decoration: none;
  color: #af1d35;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a:hover {
  color: #262626;
  text-decoration: underline;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span {
  color: #262626;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span.sol-divider {
  margin-right: 3px;
  margin-left: 5px;
}
/* subpage/page-listing.less */
.sol-page-listing {
  position: relative;
}
.sol-page-listing ul.sol-row {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (max-width: 767px) {
  .sol-page-listing ul.sol-row {
    margin-left: 8px !important;
  }
}
.sol-page-listing-item {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1150px) and (min-width: 767px) {
  .sol-page-listing-item {
    max-width: 40% !important;
    display: inline-block;
    flex: 1 0 100% !important;
  }
  .sol-page-listing-item:nth-child(odd) {
    margin-left: 20%;
  }
}
@media (max-width: 767px) {
  .sol-page-listing-item {
    max-width: 80% !important;
    display: block;
    flex: 1 0 100% !important;
    margin-left: 20px;
  }
}
.sol-page-listing-item__link {
  text-decoration: none;
}
.sol-page-listing-item__link:hover {
  text-decoration: underline;
}
.sol-page-listing-item__name {
  color: inherit;
  margin-bottom: 12px;
  margin-top: 0;
  font-weight: 700;
}
.sol-page-listing-item__icon {
  font-size: 17px;
  float: left;
  margin-right: 7px;
  height: 20px;
  width: 20px;
  color: #fff;
  background-color: #af1d35;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: 7px;
  padding-left: 1px;
  padding-top: 1px;
  margin-left: -12px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .sol-page-listing-item__desc {
    display: none;
  }
}
.sol-page-listing-child__link {
  display: block;
  margin-bottom: 10px;
  font-size: 17px;
  color: #262626;
  text-decoration: none;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-page-listing-child__link:hover {
  text-decoration: underline;
}
.sol-page-listing-child__link i.fa-external-link {
  font-size: 11px;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.sol-page-listing-child__icon {
  font-size: 18px;
  float: left;
  margin-right: 8px;
  color: #af1d35;
  position: relative;
  top: 2px;
  padding-left: 2px;
}
.sol-print {
  color: #3a92b3;
  font-size: 20px;
  font-weight: 600;
  float: none;
}
@media screen and (max-width: 380px) {
  .sol-print {
    padding-left: 5px;
  }
}
.sol-print > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  background: #2A809C;
  display: inline-block;
  border-radius: 23px;
  width: 44px;
  height: 44px;
  text-align: center;
}
.sol-print > button:hover {
  text-decoration: underline;
}
.sol-print i.fal {
  font-size: 20px;
  color: #fff;
}
.sol-publish-info {
  font-size: 20px;
  font-weight: 300;
}
.sol-publish-info strong {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .sol-publish-info strong {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .sol-publish-info strong {
    display: block;
  }
}
.sol-contact,
.sol-related {
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  margin-bottom: 10px;
}
.sol-related-item {
  text-decoration: none;
  line-height: 23px;
  display: inline-block;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 0;
  position: relative;
}
.sol-related-item-name {
  text-decoration: underline;
  color: #2B6F88;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-related-item-size {
  display: inline-block;
  color: #2B6F88;
  font-size: 12px;
  margin-left: 10px;
}
.sol-related-item i.fal {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  border-radius: 50%;
  color: #262626;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  position: absolute;
  top: 15px;
}
.sol-related-item:hover .sol-related-item-name {
  color: #262626;
}
div .sol-related-item .sol-related-item-name {
  text-decoration: none;
  color: #262626;
  display: inline-block;
  margin-left: 20px;
}
.sol-related .sol-ul li {
  border-bottom: 1px solid #E9E9E9;
}
.sol-contact-item {
  margin-bottom: 20px;
}
.sol-contact-item:not(:first-child) {
  padding-top: 10px;
  border-top: 2px solid #2A809C;
}
.sol-contact-item:last-child {
  margin-bottom: 0;
}
.sol-contact-item h3 {
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 20px;
}
.sol-contact-item h4 {
  margin-top: 10px;
  display: block;
  margin-bottom: 0;
  font-size: 18px;
}
.sol-contact-item h5 {
  margin-top: 10px;
  display: block;
  margin-bottom: 0;
  font-size: 16px;
}
.sol-contact-item p,
.sol-contact-item p.font-normal,
.sol-contact-item dd,
.sol-contact-item dl {
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 0;
  font-size: 17px;
  line-height: 1.2;
}
.sol-contact-item p a,
.sol-contact-item p.font-normal a,
.sol-contact-item dd a,
.sol-contact-item dl a {
  color: #1f5f74;
  text-decoration: none;
}
.sol-contact-item p a span,
.sol-contact-item p.font-normal a span,
.sol-contact-item dd a span,
.sol-contact-item dl a span {
  text-decoration: underline;
}
.sol-contact-item p a:hover span,
.sol-contact-item p.font-normal a:hover span,
.sol-contact-item dd a:hover span,
.sol-contact-item dl a:hover span {
  color: #262626;
}
.sol-contact-item p i.fal,
.sol-contact-item p.font-normal i.fal,
.sol-contact-item dd i.fal,
.sol-contact-item dl i.fal,
.sol-contact-item p i.far,
.sol-contact-item p.font-normal i.far,
.sol-contact-item dd i.far,
.sol-contact-item dl i.far,
.sol-contact-item p i.fas,
.sol-contact-item p.font-normal i.fas,
.sol-contact-item dd i.fas,
.sol-contact-item dl i.fas {
  margin-right: 5px;
  width: 17px;
  text-align: center;
}
.sol-contact-item dt {
  margin-top: 15px;
  font-weight: 500;
}
.sol-contact-item dd.formatted-output a {
  text-decoration: underline;
}
.sol-contact-item dd.formatted-output a img,
.sol-contact-item dd.formatted-output a span {
  display: none;
}
.sol-contact-item .sol-contact-img {
  max-height: 150px;
  margin-bottom: 10px;
}
.sol-related-contact .sol-box-decoration-header {
  padding: 20px 30px;
  background: #2A809C;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0;
}
.sol-related-contact .sol-simple-box-decoration {
  background-color: #f4f4f4;
  margin-top: 0;
  border-top: 0 none;
  padding: 20px 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sol-related-contact .sol-simple-box-decoration .sol-related-item i.fal {
  color: #2A809C;
}
.sol-menu-container {
  margin-top: 48px;
  overflow: visible;
}
.sol-menu-container .sol-menu-list {
  display: none;
  width: 100%;
}
.sol-menu-container .sol-menu-list-expanded-init {
  display: block;
}
.sol-menu-container .sol-menu-item {
  position: relative;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link {
  position: relative;
  display: block;
  padding: 8px 34px 8px 20px;
  border-top: 1px solid #e7e7e7;
  color: #262626;
  text-decoration: none;
  -webkit-transition-property: color, background-color, border;
  transition-property: color, background-color, border;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="keyboard"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="mouse"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="touch"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-item-link {
  padding-left: 35px;
  line-height: 14px;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-button {
  height: 30px;
}
.sol-menu-container .sol-menu-item[data-menu-level="4"] .sol-menu-item-link {
  padding-left: 45px;
}
.sol-menu-container .sol-menu-item[data-menu-level="5"] .sol-menu-item-link {
  padding-left: 55px;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link:hover {
  color: #fff;
  background-color: #3a92b3;
  border-top-color: #3a92b3;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.fal {
  color: #fff;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-list > ul {
  border-left: 2px solid #3a92b3;
}
.sol-menu-container .sol-menu-item[data-expanded="true"] > .sol-menu-button i.fal {
  transform: rotate(90deg);
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link:hover {
  color: #3a92b3;
  background-color: #e8f3f8;
  border-color: #e7e7e7;
  font-weight: 700;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fal {
  color: #fff;
}
.sol-menu-container .sol-menu-item[data-has-children="false"] > .sol-menu-item-link {
  padding-right: 5px;
}
.sol-menu-container .sol-menu-item > .sol-menu-item-link:hover {
  color: #af1d35;
  background-color: transparent;
}
.sol-menu-container .sol-menu-item .sol-menu-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  width: 34px;
  text-align: right;
  font-size: 17px;
  top: 0;
  height: 38px;
  padding-right: 20px;
  cursor: pointer;
}
.sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="keyboard"] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="mouse"] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="touch"] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-menu-container .sol-menu-item .sol-menu-button > i.fal {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-menu-container .sol-menu-item[data-node-type="sv:folder"] > .sol-menu-button {
  width: 100%;
  left: 0;
}
.sv-file-portlet .sv-file-portlet-fileicon {
  width: 26px;
}
.sv-file-portlet .sv-file-portlet-fileicon img {
  width: 100%;
}
.sv-file-portlet .sv-file-portlet-file {
  width: auto;
}
.sv-linklist-portlet > div > div:nth-child(3) a,
.sv-linklist-portlet > div > div:nth-child(3) span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 2px;
  padding: 4px 0 0;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: none;
  color: #262626;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  border: none;
  text-decoration: none;
  line-height: 22px;
}
.sv-linklist-portlet > div > div:nth-child(3) a:hover,
.sv-linklist-portlet > div > div:nth-child(3) span:hover {
  background-color: #e8f3f8;
}
.sv-linklist-portlet > div > div:nth-child(3) span,
.sv-linklist-portlet > div > div:nth-child(3) span {
  background-color: #af1d35;
  color: #fff;
}
.sv-linklist-portlet > div > div:nth-child(3) span strong,
.sv-linklist-portlet > div > div:nth-child(3) span strong {
  font-weight: 300;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-image-portlet {
  margin-bottom: 0;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-image-portlet img {
  border-radius: 0;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-text-portlet {
  padding: 25px 20px 25px 35px;
  background-color: #fff;
  position: relative;
  border-top: 5px solid #af1d35;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-text-portlet:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: "\f105";
  position: absolute;
  top: 25px;
  left: 20px;
  right: auto;
  background-image: none;
  height: auto;
  font-size: 20px;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-text-portlet:after.fa-pull-left {
  margin-right: 0.3em;
}
.sol-subpage-wrapper .sol-widget-decoration .sv-text-portlet:after.fa-pull-right {
  margin-left: 0.3em;
}
.sol-subpage-wrapper .sol-widget-decoration:hover .sol-widget-decoration-img:before {
  opacity: 0.5;
}
.sol-subpage-wrapper .sol-widget-decoration:hover .font-widget-heading {
  color: #af1d35;
  text-decoration: underline;
}
.sol-subpage-wrapper:after,
.sol-subpage-wrapper:before {
  content: "";
  display: table;
}
.sol-subpage-wrapper:after {
  clear: both;
}
.sol-subpage-wrapper > .sol-left {
  width: 65%;
  float: left;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-left {
    float: none;
    width: 100%;
  }
}
.sol-subpage-wrapper > .sol-right {
  width: 30%;
  float: right;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-right {
    float: none;
    width: 100%;
  }
}
.sol-subpage-wrapper .pagecontent:after,
.sol-subpage-wrapper .pagecontent:before {
  content: "";
  display: table;
}
.sol-subpage-wrapper .pagecontent:after {
  clear: both;
}
.sol-feedback {
  background-color: #f4f4f4;
  padding-top: 20px;
  width: 100%;
}
.sol-feedback span {
  margin-right: 16px;
  color: #333333;
}
.sol-feedback span:first-child {
  font-size: 23px;
  font-weight: bold;
}
.sol-feedback textarea {
  width: 100%;
  margin: 8px 0px;
  padding: 10px;
  font-size: inherit;
  resize: vertical;
}
.sol-feedback button {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  background-color: #3c3c3c;
}
.sol-feedback button:focus,
[data-whatinput="keyboard"] .sol-feedback button:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-feedback button:focus,
[data-whatinput="mouse"] .sol-feedback button:focus,
[data-whatinput="touch"] .sol-feedback button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-feedback button:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-feedback button:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-feedback button i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-feedback button.sol-js-feedback-comment {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 15px 25px;
}
.sol-feedback form {
  margin-bottom: 20px;
}
.sol-feedback__content {
  display: table;
  margin: 0 auto;
}
@media (max-width: 991px), (max-width: 767px) {
  .sol-feedback__answers {
    margin-bottom: -2px;
  }
}
.sol-feedback__buttons {
  display: inline-block;
}
.sol-section {
  padding-top: 35px;
  padding-bottom: 50px;
  max-width: 760px;
  margin: 0 auto;
}
.sol-section .sv-image-portlet,
.sol-section .sv-mfpimage-portlet,
.sol-section .sv-mediaflowVideo,
.sol-section .sv-embeddedmedia-portlet {
  margin-bottom: 20px;
}
.sol-section .sv-image-portlet img,
.sol-section .sv-mfpimage-portlet img,
.sol-section .sv-mediaflowVideo img,
.sol-section .sv-embeddedmedia-portlet img {
  border-radius: 8px;
}
.sol-section .sv-mediaflowVideo iframe {
  border-radius: 8px;
}
.sol-section .sv-mediaflowVideo > div > div > div {
  background: none !important;
}
@media (max-width: 991px) {
  .sol-section {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .sol-section {
    padding-top: 10px;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .sol-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.sol-article-wrapper .sv-image-portlet .sv-portlet-image-caption,
.sol-subpage-wrapper:not(.sol-landing-page) .sv-image-portlet .sv-portlet-image-caption,
.sol-article-wrapper .sv-portlet .sv-portlet-image-caption,
.sol-subpage-wrapper:not(.sol-landing-page) .sv-portlet .sv-portlet-image-caption,
.sol-article-wrapper .sv-decoration-content .sv-portlet-image-caption,
.sol-subpage-wrapper:not(.sol-landing-page) .sv-decoration-content .sv-portlet-image-caption {
  margin-top: 0;
  padding: 8px 5px;
  background-color: #fff;
  color: #262626;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}
.sol-article-wrapper .sv-script-portlet,
.sol-subpage-wrapper:not(.sol-landing-page) .sv-script-portlet {
  margin-bottom: 0;
}
.sol-article-wrapper .sv-linkicon,
.sol-subpage-wrapper:not(.sol-landing-page) .sv-linkicon {
  display: none;
}
.sol-event-form-n-more-info-wrapper .sol-pinboard-creator__registration-form select {
  border-radius: 4px;
  border: solid 1px #ccc;
  padding: 10px 15px;
  width: 100%;
}
.sol-error-message {
  display: none;
}
.sol-thankyou-message {
  display: none;
}
.sol-pinboard {
  background-color: #dfecf0;
  padding-left: 30px;
  border-radius: 8px;
}
.sol-pinboard-creator,
.sol-pinboard-creator input::-webkit-input-placeholder,
.sol-pinboard-creator input::-moz-placeholder,
.sol-pinboard-creator input:-ms-input-placeholder,
.sol-pinboard-creator input:-moz-placeholder {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a7a7a7;
}
.sol-pinboard-creator__registration-form {
  padding: 20px 0;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: 30px;
}
.sol-pinboard-creator__registration-form select {
  -webkit-appearance: menulist;
}
.sol-pinboard-creator__registration-form .sol-form-group {
  margin-top: 10px;
}
.sol-pinboard-creator__registration-form.input-group--text-field.input-group--dirty.input-group--select label,
.sol-pinboard-creator__registration-form .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
  transform: translateY(-28px) scale(0.75);
  left: 40px !important;
}
.sol-pinboard-creator__registration-form .input-group--text-field label {
  top: 8px;
  left: 50px !important;
}
.sol-pinboard-creator__registration-form .menu__activator--active label {
  top: 18px;
}
@media (max-width: 991px) {
  .sol-pinboard-creator__registration-form {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.sol-pinboard-creator__registration-form h2 {
  margin-top: 0;
}
.sol-pinboard-creator__registration-form form input[type='email'],
.sol-pinboard-creator__registration-form form input[type='number'],
.sol-pinboard-creator__registration-form form input[type='password'],
.sol-pinboard-creator__registration-form form input[type='tel'],
.sol-pinboard-creator__registration-form form input[type='text'],
.sol-pinboard-creator__registration-form form input[type='url'],
.sol-pinboard-creator__registration-form form select,
.sol-pinboard-creator__registration-form form textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.sol-pinboard-creator__registration-form form input[type='email']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='number']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='password']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='tel']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='text']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='url']::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form select::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form form textarea::-webkit-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form input[type='email']:-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='number']:-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='password']:-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='tel']:-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='text']:-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='url']:-moz-placeholder,
.sol-pinboard-creator__registration-form form select:-moz-placeholder,
.sol-pinboard-creator__registration-form form textarea:-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form input[type='email']::-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='number']::-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='password']::-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='tel']::-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='text']::-moz-placeholder,
.sol-pinboard-creator__registration-form form input[type='url']::-moz-placeholder,
.sol-pinboard-creator__registration-form form select::-moz-placeholder,
.sol-pinboard-creator__registration-form form textarea::-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form input[type='email']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='number']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='password']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='tel']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='text']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form input[type='url']:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form select:-ms-input-placeholder,
.sol-pinboard-creator__registration-form form textarea:-ms-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form input[type='email']:focus,
.sol-pinboard-creator__registration-form form input[type='number']:focus,
.sol-pinboard-creator__registration-form form input[type='password']:focus,
.sol-pinboard-creator__registration-form form input[type='tel']:focus,
.sol-pinboard-creator__registration-form form input[type='text']:focus,
.sol-pinboard-creator__registration-form form input[type='url']:focus,
.sol-pinboard-creator__registration-form form select:focus,
.sol-pinboard-creator__registration-form form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form form input[type='email']:hover,
.sol-pinboard-creator__registration-form form input[type='number']:hover,
.sol-pinboard-creator__registration-form form input[type='password']:hover,
.sol-pinboard-creator__registration-form form input[type='tel']:hover,
.sol-pinboard-creator__registration-form form input[type='text']:hover,
.sol-pinboard-creator__registration-form form input[type='url']:hover,
.sol-pinboard-creator__registration-form form select:hover,
.sol-pinboard-creator__registration-form form textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-pinboard-creator__registration-form form input[type='email']:focus,
.sol-pinboard-creator__registration-form form input[type='number']:focus,
.sol-pinboard-creator__registration-form form input[type='password']:focus,
.sol-pinboard-creator__registration-form form input[type='tel']:focus,
.sol-pinboard-creator__registration-form form input[type='text']:focus,
.sol-pinboard-creator__registration-form form input[type='url']:focus,
.sol-pinboard-creator__registration-form form select:focus,
.sol-pinboard-creator__registration-form form textarea:focus {
  background-color: #fff;
  border-color: black;
}
.sol-pinboard-creator__registration-form form input[type='email']::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='number']::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='password']::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='tel']::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='text']::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='url']::-ms-clear,
.sol-pinboard-creator__registration-form form select::-ms-clear,
.sol-pinboard-creator__registration-form form textarea::-ms-clear,
.sol-pinboard-creator__registration-form form input[type='email']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='number']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='password']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='tel']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='text']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='url']::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form select::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form textarea::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form input[type='email']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='number']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='password']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='tel']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='text']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='url']::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form select::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form textarea::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form input[type='email']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='number']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='password']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='tel']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='text']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='url']::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form select::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form textarea::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form input[type='email']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form input[type='number']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form input[type='password']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form input[type='tel']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form input[type='text']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form input[type='url']::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form select::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form form textarea::-webkit-search-results-decoration {
  display: none;
}
.sol-pinboard-creator__registration-form form input[type='email'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form input[type='number'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form input[type='password'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form input[type='tel'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form input[type='text'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form input[type='url'].sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form select.sol-dirty-input:invalid,
.sol-pinboard-creator__registration-form form textarea.sol-dirty-input:invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sol-pinboard-creator__registration-form form input[type='email']:required:valid,
.sol-pinboard-creator__registration-form form input[type='number']:required:valid,
.sol-pinboard-creator__registration-form form input[type='password']:required:valid,
.sol-pinboard-creator__registration-form form input[type='tel']:required:valid,
.sol-pinboard-creator__registration-form form input[type='text']:required:valid,
.sol-pinboard-creator__registration-form form input[type='url']:required:valid,
.sol-pinboard-creator__registration-form form select:required:valid,
.sol-pinboard-creator__registration-form form textarea:required:valid {
  border-color: #c7e6b3;
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/check_valid.svg');
}
.sol-pinboard-creator__registration-form form input[type='email'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form input[type='number'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form input[type='password'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form input[type='tel'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form input[type='text'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form input[type='url'] ~ span.font-normal,
.sol-pinboard-creator__registration-form form select ~ span.font-normal,
.sol-pinboard-creator__registration-form form textarea ~ span.font-normal {
  margin-top: 10px;
}
.sol-pinboard-creator__registration-form form textarea {
  height: auto;
}
.sol-pinboard-creator__registration-form form select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.sol-pinboard-creator__registration-form form select::-webkit-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form select:-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form select::-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form select:-ms-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form form select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form form select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-pinboard-creator__registration-form form select:focus {
  background-color: #fff;
  border-color: black;
}
.sol-pinboard-creator__registration-form form select::-ms-clear,
.sol-pinboard-creator__registration-form form select::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form form select::-webkit-search-decoration,
.sol-pinboard-creator__registration-form form select::-webkit-search-results-button,
.sol-pinboard-creator__registration-form form select::-webkit-search-results-decoration {
  display: none;
}
.sol-pinboard-creator__registration-form form .sol-form-field {
  margin-bottom: 10px;
}
.sol-pinboard-creator__registration-form form .sol-label-container > label,
.sol-pinboard-creator__registration-form form .sv-field-title-container > label,
.sol-pinboard-creator__registration-form form .sv-login-input-group > label {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.sol-pinboard-creator__registration-form form .sol-label-container > span,
.sol-pinboard-creator__registration-form form .sv-field-title-container > span,
.sol-pinboard-creator__registration-form form .sv-login-input-group > span {
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
}
.sol-pinboard-creator__registration-form form .sv-field-title-container label span,
.sol-pinboard-creator__registration-form form .sv-field-title-container span span {
  color: #af1a1a !important;
}
.sol-pinboard-creator__registration-form form .sv-form-datefield > div {
  width: 50%;
  margin: 0 !important;
}
.sol-pinboard-creator__registration-form form .sv-form-datefield > div:nth-of-type(1) {
  padding-right: 5px;
}
.sol-pinboard-creator__registration-form form .sv-form-datefield > div:nth-of-type(2) {
  padding-left: 5px;
}
.sol-pinboard-creator__registration-form form .sv-form-datefield > div select {
  min-width: 100%;
  margin-bottom: 10px;
}
.sol-pinboard-creator__registration-form form br {
  display: none;
}
.sol-pinboard-creator__registration-form form .font-normal {
  display: block;
}
.sol-pinboard-creator__registration-form form .sv-formField {
  margin-bottom: 0;
}
.sol-pinboard-creator__registration-form form .sv-formField .sv-form-consent label {
  font-weight: 400;
}
.sol-pinboard-creator__registration-form form .sv-formField .sv-form-consent label span {
  color: #af1a1a !important;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form form .sv-formField {
    width: 100%;
    padding: 0;
  }
}
.sol-pinboard-creator__registration-form form input[type='checkbox'] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-pinboard-creator__registration-form form input[type='checkbox'] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-pinboard-creator__registration-form form input[type='checkbox'] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form form input[type='checkbox'] + label:hover::before {
  border-color: #257088;
}
.sol-pinboard-creator__registration-form form input[type='checkbox']:focus + label::before,
.sol-pinboard-creator__registration-form form input[type='checkbox']:focus:checked + label::before {
  border: 2px solid black;
}
.sol-pinboard-creator__registration-form form input[type='checkbox']:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.sol-pinboard-creator__registration-form form input[type='checkbox']:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-pinboard-creator__registration-form form input[type='checkbox']:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-pinboard-creator__registration-form form input[type='radio'] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-pinboard-creator__registration-form form input[type='radio'] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-pinboard-creator__registration-form form input[type='radio'] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form form input[type='radio'] + label:hover::before {
  border-color: #257088;
}
.sol-pinboard-creator__registration-form form input[type='radio']:focus + label::before,
.sol-pinboard-creator__registration-form form input[type='radio']:focus:checked + label::before {
  border: 2px solid black;
}
.sol-pinboard-creator__registration-form form input[type='radio'] + label:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 1px;
}
.sol-pinboard-creator__registration-form form input[type='radio']:checked + label:before {
  border-width: 3px;
  border-color: #2A809C;
  background-color: #2A809C;
}
.sol-pinboard-creator__registration-form form button[type='submit'],
.sol-pinboard-creator__registration-form form input[type='submit'] {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  -webkit-appearance: none;
}
.sol-pinboard-creator__registration-form form button[type='submit']:focus,
.sol-pinboard-creator__registration-form form input[type='submit']:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form form button[type='submit']:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form form input[type='submit']:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form form button[type='submit']:focus,
[data-whatinput="initial"] .sol-pinboard-creator__registration-form form input[type='submit']:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form form button[type='submit']:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form form input[type='submit']:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form form button[type='submit']:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form form input[type='submit']:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form form button[type='submit']:hover,
.sol-pinboard-creator__registration-form form input[type='submit']:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-pinboard-creator__registration-form form button[type='submit']:active,
.sol-pinboard-creator__registration-form form input[type='submit']:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-pinboard-creator__registration-form form button[type='submit'] i.fal,
.sol-pinboard-creator__registration-form form input[type='submit'] i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-pinboard-creator__registration-form form button[type='submit'] > i.fal,
.sol-pinboard-creator__registration-form form input[type='submit'] > i.fal {
  margin-right: 11px;
  margin-left: -15px;
}
.sol-pinboard-creator__registration-form form .sol-form-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-event-form__buttons {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-event-form__buttons > button[type="submit"] {
    width: 100%;
  }
}
.sol-pinboard-creator__registration-form .sol-remove-time {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  height: 40px;
  margin-left: 1%;
}
.sol-pinboard-creator__registration-form .sol-remove-time:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form .sol-remove-time:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form .sol-remove-time:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form .sol-remove-time:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form .sol-remove-time:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form .sol-remove-time:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-pinboard-creator__registration-form .sol-remove-time:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-pinboard-creator__registration-form .sol-remove-time i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-remove-time {
    margin-bottom: 15px;
  }
}
.sol-pinboard-creator__registration-form .sol-remove-time i.fal {
  margin-left: auto;
  margin-right: auto;
}
.sol-pinboard-creator__registration-form .sol-datetime-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.sol-pinboard-creator__registration-form .sol-datetime-input-container:not(:first-child) > div {
  width: 47%;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-datetime-input-container:not(:first-child) > div {
    width: 100%;
  }
}
.sol-pinboard-creator__registration-form .sol-datetime-input-container:not(:first-child) > div:first-child {
  margin-right: 2%;
}
.sol-pinboard-creator__registration-form .sol-datetime-input-container > div {
  width: 47%;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-datetime-input-container > div {
    flex-wrap: wrap;
    width: 100%;
  }
}
.sol-pinboard-creator__registration-form__categories > div {
  display: inline-block;
}
.sol-pinboard-creator__registration-form__categories > div:first-child {
  margin-right: 45px;
}
.sol-pinboard-creator__registration-form__content {
  position: relative;
}
.sol-pinboard-creator__registration-form__content h2 {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .sol-pinboard-creator__registration-form__content h2 {
    font-size: 19px;
  }
}
.sol-pinboard-creator__registration-form__content .sol-row > div {
  margin-bottom: 20px;
}
.sol-pinboard-creator__registration-form__content .sol-form-row > div {
  margin-bottom: 20px;
}
.sol-pinboard-creator__registration-form__content--loading::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.sol-pinboard-creator__registration-form__divider {
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form__divider {
    padding-bottom: 0;
  }
}
.sol-pinboard-creator__registration-form__buttons {
  padding-top: 18px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
}
.sol-pinboard-creator__registration-form__buttons:after,
.sol-pinboard-creator__registration-form__buttons:before {
  content: "";
  display: table;
}
.sol-pinboard-creator__registration-form__buttons:after {
  clear: both;
}
.sol-pinboard-creator__registration-form__error-msg {
  display: block;
  color: #d53636;
  margin-bottom: 15px;
  font-weight: 500;
  margin-top: 5px;
}
.sol-pinboard-creator__registration-form__error-msg:after {
  content: '';
  height: 19px;
  width: 19px;
  display: inline-block;
  background-image: url('/webdav/files/System/resources/graphics/icons/forms/times-invalid.svg');
  background-size: cover;
  margin-left: 2px;
  position: relative;
  top: 4px;
  margin-top: -4px;
}
.sol-pinboard-creator__registration-form fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.sol-pinboard-creator__registration-form input[type="text"],
.sol-pinboard-creator__registration-form input[type="tel"],
.sol-pinboard-creator__registration-form input[type="email"],
.sol-pinboard-creator__registration-form input[type="url"] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.sol-pinboard-creator__registration-form input[type="text"]::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form input[type="tel"]::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form input[type="email"]::-webkit-input-placeholder,
.sol-pinboard-creator__registration-form input[type="url"]::-webkit-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form input[type="text"]:-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="tel"]:-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="email"]:-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="url"]:-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form input[type="text"]::-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="tel"]::-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="email"]::-moz-placeholder,
.sol-pinboard-creator__registration-form input[type="url"]::-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form input[type="text"]:-ms-input-placeholder,
.sol-pinboard-creator__registration-form input[type="tel"]:-ms-input-placeholder,
.sol-pinboard-creator__registration-form input[type="email"]:-ms-input-placeholder,
.sol-pinboard-creator__registration-form input[type="url"]:-ms-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form input[type="text"]:focus,
.sol-pinboard-creator__registration-form input[type="tel"]:focus,
.sol-pinboard-creator__registration-form input[type="email"]:focus,
.sol-pinboard-creator__registration-form input[type="url"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form input[type="text"]:hover,
.sol-pinboard-creator__registration-form input[type="tel"]:hover,
.sol-pinboard-creator__registration-form input[type="email"]:hover,
.sol-pinboard-creator__registration-form input[type="url"]:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-pinboard-creator__registration-form input[type="text"]:focus,
.sol-pinboard-creator__registration-form input[type="tel"]:focus,
.sol-pinboard-creator__registration-form input[type="email"]:focus,
.sol-pinboard-creator__registration-form input[type="url"]:focus {
  background-color: #fff;
  border-color: black;
}
.sol-pinboard-creator__registration-form input[type="text"]::-ms-clear,
.sol-pinboard-creator__registration-form input[type="tel"]::-ms-clear,
.sol-pinboard-creator__registration-form input[type="email"]::-ms-clear,
.sol-pinboard-creator__registration-form input[type="url"]::-ms-clear,
.sol-pinboard-creator__registration-form input[type="text"]::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form input[type="tel"]::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form input[type="email"]::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form input[type="url"]::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form input[type="text"]::-webkit-search-decoration,
.sol-pinboard-creator__registration-form input[type="tel"]::-webkit-search-decoration,
.sol-pinboard-creator__registration-form input[type="email"]::-webkit-search-decoration,
.sol-pinboard-creator__registration-form input[type="url"]::-webkit-search-decoration,
.sol-pinboard-creator__registration-form input[type="text"]::-webkit-search-results-button,
.sol-pinboard-creator__registration-form input[type="tel"]::-webkit-search-results-button,
.sol-pinboard-creator__registration-form input[type="email"]::-webkit-search-results-button,
.sol-pinboard-creator__registration-form input[type="url"]::-webkit-search-results-button,
.sol-pinboard-creator__registration-form input[type="text"]::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form input[type="tel"]::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form input[type="email"]::-webkit-search-results-decoration,
.sol-pinboard-creator__registration-form input[type="url"]::-webkit-search-results-decoration {
  display: none;
}
.sol-pinboard-creator__registration-form input[type="text"].ng-invalid.ng-touched,
.sol-pinboard-creator__registration-form input[type="tel"].ng-invalid.ng-touched,
.sol-pinboard-creator__registration-form input[type="email"].ng-invalid.ng-touched,
.sol-pinboard-creator__registration-form input[type="url"].ng-invalid.ng-touched,
.ng-submitted.sol-pinboard-creator__registration-form input[type="text"].ng-invalid,
.ng-submitted.sol-pinboard-creator__registration-form input[type="tel"].ng-invalid,
.ng-submitted.sol-pinboard-creator__registration-form input[type="email"].ng-invalid,
.ng-submitted.sol-pinboard-creator__registration-form input[type="url"].ng-invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sol-pinboard-creator__registration-form textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  min-height: 120px;
  height: auto;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sol-pinboard-creator__registration-form textarea::-webkit-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form textarea:-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form textarea::-moz-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form textarea:-ms-input-placeholder {
  color: #313131;
}
.sol-pinboard-creator__registration-form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-pinboard-creator__registration-form textarea:focus {
  background-color: #fff;
  border-color: black;
}
.sol-pinboard-creator__registration-form textarea::-ms-clear,
.sol-pinboard-creator__registration-form textarea::-webkit-search-cancel-button,
.sol-pinboard-creator__registration-form textarea::-webkit-search-decoration,
.sol-pinboard-creator__registration-form textarea::-webkit-search-results-button,
.sol-pinboard-creator__registration-form textarea::-webkit-search-results-decoration {
  display: none;
}
.sol-pinboard-creator__registration-form textarea.ng-invalid.ng-touched,
.ng-submitted.sol-pinboard-creator__registration-form textarea.ng-invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sol-pinboard-creator__registration-form input[type="checkbox"] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-pinboard-creator__registration-form input[type="checkbox"] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-pinboard-creator__registration-form input[type="checkbox"] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form input[type="checkbox"] + label:hover::before {
  border-color: #257088;
}
.sol-pinboard-creator__registration-form input[type="checkbox"]:focus + label::before,
.sol-pinboard-creator__registration-form input[type="checkbox"]:focus:checked + label::before {
  border: 2px solid black;
}
.sol-pinboard-creator__registration-form input[type="checkbox"]:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.sol-pinboard-creator__registration-form input[type="checkbox"]:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-pinboard-creator__registration-form input[type="checkbox"]:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-pinboard-creator__registration-form input[type="radio"] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-pinboard-creator__registration-form input[type="radio"] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-pinboard-creator__registration-form input[type="radio"] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form input[type="radio"] + label:hover::before {
  border-color: #257088;
}
.sol-pinboard-creator__registration-form input[type="radio"]:focus + label::before,
.sol-pinboard-creator__registration-form input[type="radio"]:focus:checked + label::before {
  border: 2px solid black;
}
.sol-pinboard-creator__registration-form input[type="radio"] + label:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 1px;
}
.sol-pinboard-creator__registration-form input[type="radio"]:checked + label:before {
  border-width: 3px;
  border-color: #2A809C;
  background-color: #2A809C;
}
.sol-pinboard-creator__registration-form .sol-event-form__file {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-pinboard-creator__registration-form .sol-event-form__file + label {
  margin-bottom: 20px;
}
.sol-pinboard-creator__registration-form .sol-event-form__file + label:after,
.sol-pinboard-creator__registration-form .sol-event-form__file + label:before {
  content: "";
  display: table;
}
.sol-pinboard-creator__registration-form .sol-event-form__file + label:after {
  clear: both;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__label {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-bottom: 8px;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  padding: 5px 20px;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form .sol-event-form__file__btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form .sol-event-form__file__btn:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form .sol-event-form__file__btn:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form .sol-event-form__file__btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__text {
  float: left;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 300;
  line-height: 34px;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__remove {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  min-height: 32px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__remove i {
  font-size: 18px;
  color: #af1d35;
}
.sol-pinboard-creator__registration-form .sol-event-form__file__btn {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0!important;
  min-height: 35px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .sol-pinboard-creator__registration-form .sol-event-form__file__btn {
    min-width: 110px;
    min-height: 50px;
  }
}
.sol-pinboard-creator__registration-form .sol-event-form__file__text {
  float: none !important;
}
.sol-pinboard-creator__registration-form .sol-event-form__file + label {
  margin-bottom: 0;
}
.sol-pinboard-creator__registration-form button[type="submit"] {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
}
.sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form button[type="submit"]:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form button[type="submit"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form button[type="submit"]:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-pinboard-creator__registration-form button[type="submit"]:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-pinboard-creator__registration-form button[type="submit"] i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-pinboard-creator__registration-form button[type="submit"] {
    min-width: 140px;
    min-height: 50px;
  }
}
.sol-pinboard-creator__registration-form .sol-add-time {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-top: 17px;
  margin-bottom: 37px;
  min-height: 35px !important;
}
.sol-pinboard-creator__registration-form .sol-add-time:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form .sol-add-time:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form .sol-add-time:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form .sol-add-time:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form .sol-add-time:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-pinboard-creator__registration-form .sol-add-time:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-pinboard-creator__registration-form .sol-add-time:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-pinboard-creator__registration-form .sol-add-time i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .sol-pinboard-creator__registration-form .sol-add-time {
    min-height: 50px !important;
  }
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-add-time {
    width: 100%;
  }
}
.sol-pinboard-creator__registration-form .sol-loading {
  position: absolute;
  top: 60px;
  left: 50%;
  margin-left: -50px;
  z-index: 1;
}
.sol-pinboard-creator__registration-form .sol-loading svg {
  width: 100px;
  height: 100px;
}
.sol-pinboard-creator__registration-form .sol-loading svg path {
  fill: #af1d35;
}
.sol-pinboard-creator__registration-form label {
  font-weight: 400;
}
.sol-pinboard-creator__registration-form .sol-notice__dates .application--wrap {
  background-color: #fff;
  padding: 0;
}
.sol-pinboard-creator__registration-form .sol-notice__dates__error-message {
  color: red;
  font-family: 'Quicksand', sans-serif;
  line-height: 22px;
}
.sol-pinboard-creator__registration-form .sol-notice__dates .menu__content {
  min-width: initial !important;
}
.sol-pinboard-creator__registration-form .sol-authority__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-title__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-form-group__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-files-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.sol-pinboard-creator__registration-form .sol-files-list li {
  margin-right: 10px;
  margin-bottom: 10px;
}
.sol-pinboard-creator__registration-form .sol-remove-file-btn {
  background-color: #bee9f5;
  padding: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  border: solid 2px transparent;
  background-color: #2A809C;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin: 10px 0 0 0;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus {
  outline: 2px solid black;
  outline-offset: -2px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  z-index: inherit;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn [data-whatinput='keyboard']:focus {
  background-color: #f4f2f0;
  color: #262626;
}
[data-whatinput='initial'] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
[data-whatinput='mouse'] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus,
[data-whatinput='touch'] .sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:focus {
  color: #fff;
  background-color: #3f8da6;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:hover {
  color: #fff;
  background-color: #3f8da6;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i.fa,
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i.far,
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i.fal,
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i.fab,
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i.fas {
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
  position: relative;
  top: 1px;
}
.sol-pinboard-creator__registration-form button[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  border: solid 2px transparent;
  background-color: #2A809C;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="keyboard"] .sol-pinboard-creator__registration-form button[type="submit"]:focus {
  outline: 2px solid black;
  outline-offset: -2px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="mouse"] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput="touch"] .sol-pinboard-creator__registration-form button[type="submit"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  z-index: inherit;
}
.sol-pinboard-creator__registration-form button[type="submit"]:focus,
.sol-pinboard-creator__registration-form button[type="submit"] [data-whatinput='keyboard']:focus {
  background-color: #f4f2f0;
  color: #262626;
}
[data-whatinput='initial'] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput='mouse'] .sol-pinboard-creator__registration-form button[type="submit"]:focus,
[data-whatinput='touch'] .sol-pinboard-creator__registration-form button[type="submit"]:focus {
  color: #fff;
  background-color: #3f8da6;
}
.sol-pinboard-creator__registration-form button[type="submit"]:hover {
  color: #fff;
  background-color: #3f8da6;
}
.sol-pinboard-creator__registration-form button[type="submit"] i.fa,
.sol-pinboard-creator__registration-form button[type="submit"] i.far,
.sol-pinboard-creator__registration-form button[type="submit"] i.fal,
.sol-pinboard-creator__registration-form button[type="submit"] i.fab,
.sol-pinboard-creator__registration-form button[type="submit"] i.fas {
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
  position: relative;
  top: 1px;
}
.sol-pinboard-creator__registration-form .sol-file-uploader input {
  display: none;
}
.sol-pinboard-creator__registration-form .input-group {
  height: 40px;
  padding: 0 0 0 0;
  margin: 23px 0 18px 0;
}
.sol-pinboard-creator__registration-form .input-group__details {
  display: none;
}
.sol-pinboard-creator__registration-form .sol-pinboard-datePicker {
  max-height: 100px;
}
.sol-pinboard-creator__registration-form .application--wrap {
  min-height: auto;
}
.sol-pinboard-creator ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
.sol-pinboard .sol-pin {
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 30px;
}
.sol-pinboard .sol-pin p {
  margin-bottom: 0;
}
.sol-collapsible-pinboard {
  margin-bottom: 20px;
}
.sol-collapsible-pinboard .sv-decoration-content {
  line-height: 30px;
}
.sol-collapsible-pinboard .sv-decoration-content a {
  display: block;
  margin-bottom: 15px;
}
.sol-form-top-level-error-list {
  border-radius: 3px;
  margin-bottom: 1rem;
}
.sol-form-top-level-error-item {
  padding: 15px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  background-color: rgba(200, 73, 107, 0.2);
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
  color: #333333;
}
.sol-startpage-content-bottom__blurb {
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-rows: max-content auto;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  position: relative;
  background-color: #fff;
}
.sol-startpage-content-bottom__blurb h1,
.sol-startpage-content-bottom__blurb h2,
.sol-startpage-content-bottom__blurb h3,
.sol-startpage-content-bottom__blurb h4 {
  margin: 0 0 10px !important;
  font-size: 24px !important;
  overflow-wrap: anywhere;
}
.sol-startpage-content-bottom__blurb p,
.sol-startpage-content-bottom__blurb p.font-normal {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 0 !important;
  margin-top: 0;
}
.sol-startpage-content-bottom__blurb .rspkr_dr_link {
  display: none;
}
.sol-startpage-content-bottom__blurb:hover a {
  text-decoration: underline;
}
.sol-startpage-content-bottom__blurb .sv-image-portlet,
.sol-startpage-content-bottom__blurb .sv-mfpimage-portlet {
  border-radius: 5px 5px 0 0;
  grid-row: 1;
  padding-bottom: 56.25%;
  position: relative;
}
.sol-startpage-content-bottom__blurb .sv-image-portlet img,
.sol-startpage-content-bottom__blurb .sv-mfpimage-portlet img {
  border-radius: 5px 5px 0 0;
  height: 100%;
  left: 0;
  min-height: 100%!important;
  min-width: 100%!important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50%;
  object-position: 50%;
  position: absolute;
  top: 0;
  width: 100%;
}
.sol-startpage-content-bottom__blurb > .sv-vertical {
  border-top: 6px solid #af1d35;
  grid-row: 2;
  padding: 20px 30px 60px 20px;
}
.sol-eservices .env-button {
  background-color: #2A809C;
  margin: 15px 0;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  justify-content: space-between;
  padding: 0 12px;
  transition: 0.2s;
}
.sol-eservices .env-button::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: inherit;
  content: "\f054";
  font-size: 22px;
}
.sol-eservices .env-button::after.fa-pull-left {
  margin-right: 0.3em;
}
.sol-eservices .env-button::after.fa-pull-right {
  margin-left: 0.3em;
}
.sol-eservices .env-button:hover {
  background-color: #1f5f74;
}
.sol-startpage-entrances .sv-script-portlet,
.sol-landing-page__listing .sv-script-portlet {
  width: 100%;
}
.sol-landing-large {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}
.sol-landing-large__item {
  flex: 0 0 23%;
  position: relative;
  padding-left: 33px;
  padding-bottom: 13px;
  padding-bottom: 20px;
  padding-right: 15px;
  margin: 0 1%;
}
.sol-landing-large__item a,
.sol-landing-large__item div {
  text-decoration: none;
}
.sol-landing-large__item a i:not(.fa-external-link),
.sol-landing-large__item div i:not(.fa-external-link) {
  background: #fff;
  width: 22px;
  height: 22px;
  color: #2A809C;
  border-radius: 50%;
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  margin-right: 5px;
  position: absolute;
  left: 0;
}
@media (max-width: 991px) {
  .sol-landing-large__item a i:not(.fa-external-link),
  .sol-landing-large__item div i:not(.fa-external-link) {
    left: auto;
    top: 4px;
  }
}
.sol-landing-large__item a i.fa-external-link,
.sol-landing-large__item div i.fa-external-link {
  font-size: 14px;
}
.sol-landing-large__item > div {
  cursor: initial;
}
.sol-landing-large__item:hover a .sol-landing-large__header {
  text-decoration: underline;
}
@media only screen and (max-width: 1070px) and (min-width: 952px) {
  .sol-landing-large__item {
    flex: 31%;
    flex-grow: 0;
    flex-shrink: 1;
    padding-left: 60px;
  }
}
@media only screen and (max-width: 952px) {
  .sol-landing-large__item {
    flex: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    padding-left: 15px;
    margin-right: 0;
    margin-top: 0;
  }
}
.sol-landing-large__header {
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 3px;
}
@media only screen and (max-width: 1070px) {
  .sol-landing-large__header {
    padding-left: 35px;
  }
}
@media (max-width: 991px) {
  .sol-landing-large__text {
    display: none;
  }
}
.sol-startpage-entrances,
.sol-landing-page__listing {
  background: #2A809C;
  margin-top: -6px;
}
.sol-startpage-entrances .sv-text-portlet,
.sol-landing-page__listing .sv-text-portlet {
  background-color: #af1d35;
  width: 100%;
}
@media (max-width: 991px) {
  .sol-startpage-entrances .sv-text-portlet,
  .sol-landing-page__listing .sv-text-portlet {
    display: block;
  }
}
.sol-startpage-entrances .sv-text-portlet-content,
.sol-landing-page__listing .sv-text-portlet-content {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.sol-startpage-entrances .sv-text-portlet-content h2,
.sol-landing-page__listing .sv-text-portlet-content h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  color: #fff;
}
.sol-startpage-entrances .sol-landing-large__item a,
.sol-landing-page__listing .sol-landing-large__item a,
.sol-startpage-entrances .sol-landing-large__item a h2,
.sol-landing-page__listing .sol-landing-large__item a h2,
.sol-startpage-entrances .sol-landing-large__item div,
.sol-landing-page__listing .sol-landing-large__item div,
.sol-startpage-entrances .sol-landing-large__item div h2,
.sol-landing-page__listing .sol-landing-large__item div h2 {
  color: #fff;
}
.sol-startpage-entrances .sol-landing-large__text,
.sol-landing-page__listing .sol-landing-large__text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
}
.sol-popular-pages-container {
  background-color: #2A809C;
}
.sol-popular-pages-container .sol-popular-pages {
  display: flex;
  justify-content: space-evenly;
}
.sol-popular-pages-container .sol-popular-pages:after,
.sol-popular-pages-container .sol-popular-pages:before {
  content: "";
  display: table;
}
.sol-popular-pages-container .sol-popular-pages:after {
  clear: both;
}
@media (max-width: 991px) {
  .sol-popular-pages-container .sol-popular-pages {
    align-items: start;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 15px;
  }
}
@media (max-width: 479px) {
}
.sol-popular-pages-container .sol-popular-pages > a,
.sol-popular-pages-container .sol-popular-pages > div {
  color: #fff;
  float: left;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .sol-popular-pages-container .sol-popular-pages > a,
  .sol-popular-pages-container .sol-popular-pages > div {
    font-size: 13px;
  }
}
.sol-popular-pages-container .sol-popular-pages > div {
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
}
.sol-popular-pages-container .sol-popular-pages > a {
  text-align: center;
  width: 200px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  border-left: 2px solid #fff;
  padding: 2px 0 2px 10px;
  margin: 15px 10px 15px 0;
}
@media (max-width: 991px) {
  .sol-popular-pages-container .sol-popular-pages > a {
    text-align: left;
    justify-content: left;
    border: none;
    padding: 0;
  }
}
.sol-popular-pages-container .sol-popular-pages > a:first-child {
  border: none;
}
.sol-popular-pages-container .sol-popular-pages > a:hover {
  text-decoration: underline;
}
@media (max-width: 991px) {
  .sol-popular-pages-container .sol-popular-pages > a {
    width: 100%;
    margin-right: 0;
  }
}
.sol-popular-pages-container .sol-popular-pages > a i.fal {
  display: none;
  background: #fff;
  width: 22px;
  height: 22px;
  color: #2A809C;
  border-radius: 50%;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  margin-right: 5px;
  position: relative;
}
@media (max-width: 991px) {
  .sol-popular-pages-container .sol-popular-pages > a i.fal {
    display: block;
  }
}
.sol-popular-pages-container .sol-popular-pages > a i.fal::before {
  content: '\f054';
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .sol-popular-pages-container .sol-popular-pages-first {
    width: 100%;
  }
}
.sol-popular-services a {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 8px 10px 9px 35px;
  border-bottom: 1px solid #f1f1f1;
  color: #262626;
  font-size: 13px;
  line-height: 20px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-popular-services a:hover {
  color: #af1d35;
  text-decoration: underline;
}
.sol-popular-services a i.fal {
  font-size: 14px;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color: #af1d35;
  border: 1px solid #af1d35;
  border-radius: 50%;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;
  position: absolute;
  left: 9px;
  top: 50%;
  margin-top: -9px;
}
.sol-startpage-shortcuts {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .sol-startpage-shortcuts li {
    display: flex;
    flex: 50%;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
}
.sol-startpage-shortcuts li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  max-width: 120px;
  transition: 0.2s;
}
.sol-startpage-shortcuts li a:hover i {
  background-color: #831628;
  text-decoration: none;
}
.sol-startpage-shortcuts li a:hover p {
  text-decoration: underline;
}
.sol-startpage-shortcuts li a i {
  border-radius: 50%;
  background-color: #af1d35;
  height: 95px;
  width: 95px;
  font-size: 45px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sol-startpage-shortcuts li a p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.sol-startpage-wrapper .sol-top .sv-mfpimage-portlet img {
  height: 385px;
  object-fit: cover;
  max-height: unset !important;
  max-width: unset !important;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-top .sv-mfpimage-portlet img {
    height: 135px;
  }
}
.sol-startpage-top-images {
  z-index: 1;
  width: 100%;
}
.sol-startpage-top-images::before {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  margin-bottom: -2px;
  bottom: 0;
  content: "";
  min-height: 85px;
  position: absolute;
  width: calc(100% + 2px);
  z-index: 1;
  background-image: url(/webdav/files/System/resources/graphics/pattern/mora-pattern.svg);
}
.sol-startpage-top-images-item {
  height: 385px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .sol-startpage-top-images-item {
    height: 200px !important;
  }
}
@media (max-width: 767px) {
  .sol-startpage-top-images-item {
    display: flex !important;
  }
}
.sol-startpage-top-images .slick-next,
.sol-startpage-top-images .slick-prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 24px;
  background: rgba(176, 24, 42, 0.75);
  cursor: pointer;
}
.sol-startpage-top-images .slick-next i,
.sol-startpage-top-images .slick-prev i {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sol-startpage-top-images .slick-next {
  right: 0;
  margin-right: 50px;
}
.sol-startpage-top-images .slick-prev {
  left: 0;
  z-index: 2;
  margin-left: 50px;
}
.sol-startpage-top-images .slick-dots {
  margin: auto;
  padding: 10px 10px 5px 10px;
  position: absolute;
  z-index: 2;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}
.sol-startpage-top-images .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  margin: 0 3px 5px;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-startpage-top-images .slick-dots li button {
  display: none;
}
.sol-startpage-top-images .slick-dots li.slick-active {
  background-color: white;
}
.sol-startpage-top-images-content {
  display: none;
}
.sol-startpage-top-images-content:has(.sv-text-portlet) {
  display: block;
  position: absolute;
  width: 570px;
  left: 10%;
  padding: 20px 30px;
  margin-top: 100px;
  top: auto;
  z-index: 10;
  border-radius: 5px;
  background-color: rgba(176, 24, 42, 0.9);
}
.sol-startpage-top-images-content:has(.sv-text-portlet) div div h1,
.sol-startpage-top-images-content:has(.sv-text-portlet) div div p {
  margin-bottom: 0;
  color: #fff;
}
@media (max-width: 991px) {
  .sol-startpage-top-images-content:has(.sv-text-portlet) {
    display: none;
  }
}
.sol-startpage-top-images-content:has(.sv-text-portlet) .sol-top-search {
  background-color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 767px) {
  .sol-startpage-top-images-content:has(.sv-text-portlet) .sol-top-search {
    background-color: #fff;
  }
}
.sol-startpage-widgets {
  background: #f4f4f4;
  padding: 30px 15px;
}
.sol-startpage-widgets .pagecontent {
  width: 100%;
}
.sol-startpage-widgets .pagecontent > .sv-layout {
  width: 100%;
  display: flex;
}
@media (max-width: 991px) {
  .sol-startpage-widgets .pagecontent > .sv-layout {
    flex-wrap: wrap;
  }
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout {
  flex: 0 0 23%;
  margin-right: 1%;
  margin-left: 1%;
}
@media (max-width: 991px) {
  .sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout {
    flex: 48%;
    margin-right: 1%;
    margin-left: 1%;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout {
    flex: 100%;
    margin-bottom: 20px;
    margin-left: 1px;
    margin-right: 1px;
  }
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration {
  background-color: transparent;
  box-shadow: 0 2px 2px rgba(38, 38, 38, 0.05);
  height: 100%;
  background-color: white;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-image-portlet {
  margin-bottom: 0;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-image-portlet img {
  height: 170px;
}
@media (max-width: 991px) {
  .sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-image-portlet img {
    height: 124px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-image-portlet img {
    height: 250px;
  }
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet {
  padding: 25px 20px 25px 35px;
  background-color: #fff;
  position: relative;
  border-top: 5px solid #af1d35;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: "\f105";
  position: absolute;
  top: 25px;
  left: 20px;
  right: auto;
  background-image: none;
  height: auto;
  font-size: 20px;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet:after.fa-pull-left {
  margin-right: 0.3em;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet:after.fa-pull-right {
  margin-left: 0.3em;
}
.sol-startpage-widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration-wrapper {
  height: 100%;
}
.sol-startpage-wrapper {
  margin-bottom: 0;
}
.sol-startpage-wrapper .sol-top {
  position: relative;
  height: 390px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-top {
    height: 130px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
  padding-right: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
    padding-right: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
  padding-left: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
    padding-left: 10px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-left h2.font-heading-2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
  padding-top: 33px;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
    padding-top: 0;
  }
}
.sol-startpage-wrapper .sol-startpage-news-events > .sv-column-7,
.sol-startpage-wrapper .sol-startpage-news-events > .sv-column-8 {
  padding-right: 50px;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-news-events > .sv-column-7,
  .sol-startpage-wrapper .sol-startpage-news-events > .sv-column-8 {
    padding-right: 5px;
  }
}
.sol-startpage-wrapper .sol-font-btn-small .font-btn {
  margin: 0;
}
.sol-startpage-wrapper .sol-font-btn-small .far {
  line-height: inherit;
}
.sol-a-z-icon {
  font-size: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.sol-letters {
  max-width: 450px;
}
.sol-letters:after,
.sol-letters:before {
  content: "";
  display: table;
}
.sol-letters:after {
  clear: both;
}
.sol-letters a {
  padding: 6px 0;
  text-align: center;
  height: 25px;
  width: 25px;
  color: white !important;
  background-color: #045c79;
  box-shadow: none;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 10px;
  float: left;
}
.sol-contact-card {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 20px 20px 10px;
}
.sol-contact-card .sv-text-portlet {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
  min-height: 51px;
}
.sol-contact-card .sv-text-portlet .sv-portlet-toolbar {
  font-weight: 600;
}
.sol-contact-card h1.font-heading-1 {
  line-height: 1.7em;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  font-weight: normal;
}
@media (max-width: 767px) {
  .sol-contact-card h1.font-heading-1 {
    font-size: 15px;
  }
}
a.sol-contact-card h1.font-heading-1,
.sol-contact-card h1.font-heading-1 a {
  color: #2B6F88;
}
a.sol-contact-card h1.font-heading-1:hover,
.sol-contact-card h1.font-heading-1 a:hover {
  color: #262626;
}
@media (max-width: 767px) {
  .sol-contact-card h1.font-heading-1 {
    font-size: 16px;
  }
}
a.sol-contact-card h1.font-heading-1,
.sol-contact-card h1.font-heading-1 a {
  color: #2B6F88;
  font-weight: 400;
}
a.sol-contact-card h1.font-heading-1:hover,
.sol-contact-card h1.font-heading-1 a:hover {
  color: #262626;
}
.sol-contact-card h2.font-heading-2 {
  font-size: 13px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;
}
@media (max-width: 479px) {
  .sol-contact-card h2.font-heading-2 {
    font-size: 12px;
  }
}
@media (max-width: 479px) {
  .sol-contact-card h2.font-heading-2 {
    font-size: 12px;
  }
}
.sol-contact-card h1.font-heading-1,
.sol-contact-card h2.font-heading-2,
.sol-contact-card h3.font-heading-3,
.sol-contact-card h4.font-heading-4,
.sol-contact-card p.font-normal {
  margin: 0;
}
.sol-contact-card .sv-html-portlet {
  display: none;
}
.sol-contact-card .sv-html-portlet p b {
  font-weight: 700;
}
.sv-editing-mode .sol-contact-card .sv-html-portlet {
  display: block;
}
.sol-contact-card .pagecontent {
  background-color: #f4f4f4;
  margin: -20px -20px -20px -20px;
  padding: 20px 20px 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sol-contact-card .sv-image-portlet img,
.sol-contact-card .sv-mfpimage-portlet img {
  width: 115px !important;
}
.sol-contact-card > .sv-text-portlet {
  margin: -20px -20px 20px -20px;
  padding: 20px 20px 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
}
.sol-contact-card > .sv-text-portlet h1.font-heading-1 {
  color: white;
}
.sol-error-page {
  padding-top: 90px;
  padding-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
}
.sol-error-page-text-big {
  font-size: 140px;
  line-height: 140px;
  font-weight: 700;
}
.sol-error-page-text-small {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 34px;
  line-height: 40px;
  font-weight: 300;
}
.sol-error-page-text-smaller {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}
.sol-error-page .sol-btn {
  margin-top: 20px;
}
.sol-error-page .sol-btn:first-child {
  margin-right: 10px;
}
.sol-error-page .sv-portlet {
  margin-bottom: 20px;
}
.sol-error-page .sv-form-portlet {
  text-align: left;
}
@media (max-width: 767px) {
  .sol-error-page .sol-top-search-field button[type=submit] > i.fal {
    top: 7px;
    padding-bottom: 13px;
  }
}
.sol-404-page .sv-searchform-portlet {
  display: block !important;
}
.sol-404-page .sv-searchform-portlet form {
  width: 70%;
  float: right;
  border: 1px solid #e7e7e7;
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .sol-404-page .sv-searchform-portlet form {
    width: initial;
    float: initial;
    margin-bottom: 10px;
  }
}
.sol-404-page .sv-searchform-portlet form input,
.sol-404-page .sv-searchform-portlet form button {
  height: 50px;
  font-size: 20px;
}
.sol-404-page .font-btn {
  margin: 0;
  padding: 0;
}
.sol-404-page .font-btn a {
  width: 70%;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 33px;
}
@media (max-width: 991px) {
  .sol-404-page .font-btn a {
    width: initial;
    float: initial;
  }
}
.sv-login-portlet.sol-form-decoration {
  padding-bottom: 0;
}
.sv-login-portlet.sol-form-decoration fieldset {
  margin: 0;
  padding: 0;
}
/* För deras speciallösning kring filmer från MediaflowPro */
.mf-livewait p {
  color: #fff !important;
}
.rspkr_dr_link {
  background-color: transparent!important;
  padding-left: 0px!important;
}
.rspkr_dr_link img {
  position: relative !important;
}
.sv-image-portlet .rspkr_dr_link {
  top: -25px;
  position: relative;
  background-color: #fff !important;
  border: 2px solid #262626;
  border-radius: 5px;
}
.sv-image-portlet .rspkr_dr_link:hover {
  opacity: 0.7;
}
.sv-image-portlet .rspkr_dr_link img {
  width: 16px;
}
.sol-searchpage-wrapper {
  padding-top: 35px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;
}
.sv-standardsearch-portlet .sol-top-search-field,
.sv-facetedsearch-portlet .sol-top-search-field {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0.7em;
  list-style-type: none;
  width: 100%;
  padding-right: 0;
}
.sv-standardsearch-portlet .sol-top-search input[type=text],
.sv-facetedsearch-portlet .sol-top-search input[type=text] {
  border: 1px solid #e7e7e7;
  border-radius: 0;
}
.sv-standardsearch-portlet .sol-top-search button[type=submit],
.sv-facetedsearch-portlet .sol-top-search button[type=submit] {
  border: 1px solid #e7e7e7;
  border-radius: 0;
  margin-right: 0;
}
.sv-standardsearch-portlet .sol-did-you-mean,
.sv-facetedsearch-portlet .sol-did-you-mean,
.sv-standardsearch-portlet .sol-num-hits,
.sv-facetedsearch-portlet .sol-num-hits {
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
}
.sv-standardsearch-portlet .sol-search-hits,
.sv-facetedsearch-portlet .sol-search-hits {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sv-standardsearch-portlet .sol-search-hits .sol-num-hits,
.sv-facetedsearch-portlet .sol-search-hits .sol-num-hits {
  margin-top: 0;
}
.sv-standardsearch-portlet .sol-search-hits .font-btn,
.sv-facetedsearch-portlet .sol-search-hits .font-btn {
  margin: 0 0 10px 0;
}
.sv-standardsearch-portlet .sv-search-facets,
.sv-facetedsearch-portlet .sv-search-facets {
  float: none;
  padding: 0 !important;
  margin-top: 0.7em;
  order: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .sv-standardsearch-portlet .sv-search-facets,
  .sv-facetedsearch-portlet .sv-search-facets {
    flex-direction: column;
  }
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet {
  margin-bottom: 20px;
  background-color: #f4f4f4;
  width: 45%;
  position: relative;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  margin-left: 6px;
  margin-right: 6px;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(1),
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(1) {
  z-index: 5;
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(2),
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(2) {
  z-index: 4;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(3),
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet:nth-child(3) {
  z-index: 3;
  margin-right: 0;
}
@media (max-width: 767px) {
  .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet,
  .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > p,
  .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > p {
    display: none;
  }
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 50px 16px 15px;
  background-color: #dfecf0;
  border-radius: 6px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  text-align: left;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="keyboard"] .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="keyboard"] .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="initial"] .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="mouse"] .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="mouse"] .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="touch"] .sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button:focus,
[data-whatinput="touch"] .sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -25px;
  background: #2A809C;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::after,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::after,
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::before,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::before {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 4px;
  margin-left: -10px;
  margin-top: -3px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::after,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button .sv-search-facet__button::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button.sol-active,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button.sol-active {
  background-color: #204560;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > button.sol-active .sv-search-facet__button::after,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > button.sol-active .sv-search-facet__button::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > ul,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > ul {
  display: none;
  margin: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 14px 19px 24px 19px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: -1;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-radius: 3px;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > ul > li,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > ul > li {
  margin: 0 0 10px !important;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a {
  position: relative;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a::after,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a::after {
  content: '';
  border-bottom: 2px solid rgba(110, 205, 233, 0.6);
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 3px;
  z-index: -1;
}
.sv-standardsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a:hover::after,
.sv-facetedsearch-portlet .sv-search-facets > .sv-search-facet > ul > li a:hover::after {
  border-bottom-color: #6ecde9;
}
.sv-standardsearch-portlet .sv-search-result,
.sv-facetedsearch-portlet .sv-search-result {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0.7em;
  list-style-type: none;
  width: 100%;
  padding-right: 0;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 0 20px;
  border-radius: 2px;
  background-color: #fff;
  border-top: 3px solid #f4f4f4;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .font-heading-3,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .font-heading-3 {
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  color: #262626;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .font-heading-3:hover,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .font-heading-3:hover {
  text-decoration: underline;
  color: #af1d35;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .font-normal,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .font-normal {
  font-size: 15px;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text {
  font-size: 13px;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text i.fal,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text i.fal {
  color: #af1d35;
  margin-right: 5px;
  margin-left: 10px;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text i.fal:first-child,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text i.fal:first-child {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text > strong,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text > strong {
  margin-left: 10px;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text > strong:first-of-type,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-info-text > strong:first-of-type {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text {
  font-size: 15px;
  font-weight: 400;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text a,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text a {
  color: #af1d35;
  text-decoration: none;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text a:hover,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sol-hit-path-text a:hover {
  color: #262626;
  text-decoration: underline;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-8,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-8,
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-4,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-4 {
  padding-left: 0;
}
@media (max-width: 767px) {
  .sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-8,
  .sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-8,
  .sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-4,
  .sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-4 {
    width: 100%;
    padding-left: 0 !important;
  }
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-4,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-4 {
  padding-left: 80px;
}
@media screen and (max-width: 1300px) {
  .sv-standardsearch-portlet .sv-search-result .sv-search-hit .sv-column-4,
  .sv-facetedsearch-portlet .sv-search-result .sv-search-hit .sv-column-4 {
    padding-left: 30px;
  }
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .font-btn,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .font-btn {
  padding-top: 0;
  padding-bottom: 0;
}
.sv-standardsearch-portlet .sv-search-result .sv-search-hit .font-btn > a,
.sv-facetedsearch-portlet .sv-search-result .sv-search-hit .font-btn > a {
  display: inline-block;
}
.sv-standardsearch-portlet .sol-only-contacts,
.sv-facetedsearch-portlet .sol-only-contacts {
  display: flex;
  flex-wrap: wrap;
}
.sv-standardsearch-portlet .sol-only-contacts .sol-contactcard--hasImage li,
.sv-facetedsearch-portlet .sol-only-contacts .sol-contactcard--hasImage li {
  width: 100% !important;
  word-break: break-word;
}
.sv-standardsearch-portlet .sol-contactcard .font-heading-3,
.sv-facetedsearch-portlet .sol-contactcard .font-heading-3 {
  margin-bottom: 20px !important;
}
.sv-standardsearch-portlet .sol-contactcard .font-heading-3:hover,
.sv-facetedsearch-portlet .sol-contactcard .font-heading-3:hover {
  text-decoration: none !important;
  color: initial !important;
}
.sv-standardsearch-portlet .sol-contactcard .font-heading-1,
.sv-facetedsearch-portlet .sol-contactcard .font-heading-1,
.sv-standardsearch-portlet .sol-contactcard .font-heading-2,
.sv-facetedsearch-portlet .sol-contactcard .font-heading-2 {
  font-size: initial !important;
}
.sv-standardsearch-portlet .sol-contactcard .font-heading-3 + img,
.sv-facetedsearch-portlet .sol-contactcard .font-heading-3 + img {
  margin-right: 30px;
  vertical-align: top;
}
@media (max-width: 991px) {
  .sv-standardsearch-portlet .sol-contactcard .font-heading-3 + img,
  .sv-facetedsearch-portlet .sol-contactcard .font-heading-3 + img {
    margin-right: 10px;
  }
}
.sv-standardsearch-portlet .sol-contactcard ul,
.sv-facetedsearch-portlet .sol-contactcard ul {
  display: inline-block;
  padding: 0 0 5px 0;
}
.sv-standardsearch-portlet .sol-contactcard ul li,
.sv-facetedsearch-portlet .sol-contactcard ul li {
  float: left;
  list-style: none;
  margin: 0 0 5px 0;
  width: 100%;
}
.sv-standardsearch-portlet .sol-contactcard ul .sv-text-portlet-content img,
.sv-facetedsearch-portlet .sol-contactcard ul .sv-text-portlet-content img {
  max-height: 180px !important;
  max-width: initial !important;
}
.sv-standardsearch-portlet .sol-contactcard--noImage ul,
.sv-facetedsearch-portlet .sol-contactcard--noImage ul {
  width: 100%;
}
@media (max-width: 991px) {
  .sv-standardsearch-portlet .sol-contactcard--noImage ul,
  .sv-facetedsearch-portlet .sol-contactcard--noImage ul {
    width: 50% !important;
  }
}
@media (max-width: 991px) {
  .sv-standardsearch-portlet .sol-contactcard--noImage ul,
  .sv-facetedsearch-portlet .sol-contactcard--noImage ul {
    float: initial;
  }
}
.sv-standardsearch-portlet .sol-contactcard--hasImage,
.sv-facetedsearch-portlet .sol-contactcard--hasImage {
  width: 50%;
}
.sv-standardsearch-portlet .sol-contact__list,
.sv-facetedsearch-portlet .sol-contact__list {
  margin: 0 0 20px 0;
  padding: 0 0 0 0 !important;
  display: flex;
  flex: none !important;
}
@media (max-width: 767px) {
  .sv-standardsearch-portlet .sol-contact__list,
  .sv-facetedsearch-portlet .sol-contact__list {
    width: 100% !important;
  }
}
@media screen and (max-width: 1000px) {
  .sv-standardsearch-portlet .sol-contact__list,
  .sv-facetedsearch-portlet .sol-contact__list {
    min-height: 250px;
  }
}
@media screen and (min-width: 1000px) {
  .sv-standardsearch-portlet .sol-contact__list:nth-child(even) .sol-contactcard,
  .sv-facetedsearch-portlet .sol-contact__list:nth-child(even) .sol-contactcard {
    margin: 0 0 0 52px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .sv-standardsearch-portlet .sol-contact__list:nth-child(even) .sol-contactcard,
  .sv-facetedsearch-portlet .sol-contact__list:nth-child(even) .sol-contactcard {
    margin: 0 0 0 40px;
  }
}
.sv-standardsearch-portlet .sol-contact__list .sol-contactcard,
.sv-facetedsearch-portlet .sol-contact__list .sol-contactcard {
  width: 100%;
}
.sv-standardsearch-portlet .sol-contact__list .sol-contactcard ul li,
.sv-facetedsearch-portlet .sol-contact__list .sol-contactcard ul li {
  font-size: 16px;
  margin: 0 0 0 0;
}
.sv-standardsearch-portlet .sol-contact__list .sol-contactcard--hasImage,
.sv-facetedsearch-portlet .sol-contact__list .sol-contactcard--hasImage {
  width: 60% !important;
}
.sv-standardsearch-portlet .sv-search-facet-name,
.sv-facetedsearch-portlet .sv-search-facet-name {
  margin-top: 0;
  margin-bottom: 3px;
  color: #262626;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  display: none;
}
.sv-standardsearch-portlet .sv-search-facet-item,
.sv-facetedsearch-portlet .sv-search-facet-item {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 8px;
}
.sv-standardsearch-portlet .sv-search-facet-item a,
.sv-facetedsearch-portlet .sv-search-facet-item a {
  font-size: 17px;
  color: #333333;
}
.sv-standardsearch-portlet .sv-search-facet-item--has-matches a,
.sv-facetedsearch-portlet .sv-search-facet-item--has-matches a {
  font-size: 17px;
  text-align: left;
  color: #204560;
}
.sv-standardsearch-portlet .sv-search-facet-item-selected a::after,
.sv-facetedsearch-portlet .sv-search-facet-item-selected a::after {
  display: block;
  content: '';
  border-bottom: 2px solid rgba(110, 205, 233, 0.6);
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 3px;
  z-index: -1;
}
.sv-standardsearch-portlet .sol-pagination,
.sv-facetedsearch-portlet .sol-pagination {
  text-align: center;
}
.sv-standardsearch-portlet .sol-pagination .pagination,
.sv-facetedsearch-portlet .sol-pagination .pagination {
  display: inline-block;
  margin-top: 50px;
}
.sv-standardsearch-portlet .sol-pagination .pagination:after,
.sv-facetedsearch-portlet .sol-pagination .pagination:after,
.sv-standardsearch-portlet .sol-pagination .pagination:before,
.sv-facetedsearch-portlet .sol-pagination .pagination:before {
  content: "";
  display: table;
}
.sv-standardsearch-portlet .sol-pagination .pagination:after,
.sv-facetedsearch-portlet .sol-pagination .pagination:after {
  clear: both;
}
.sv-standardsearch-portlet .sol-pagination .pagination a,
.sv-facetedsearch-portlet .sol-pagination .pagination a,
.sv-standardsearch-portlet .sol-pagination .pagination span.current,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current {
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 2px;
  padding: 4px 0 0;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: none;
  color: #262626;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  border: none;
  text-decoration: none;
  line-height: 22px;
}
.sv-standardsearch-portlet .sol-pagination .pagination a:hover,
.sv-facetedsearch-portlet .sol-pagination .pagination a:hover,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current:hover {
  background-color: #e8f3f8;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current:hover {
  font-weight: 700;
  background-color: #af1d35;
  color: #fff;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current strong,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current strong,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover strong,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current:hover strong {
  font-weight: 300;
}
.sv-standardsearch-portlet .sol-pagination .pagination > *:first-child:not(span),
.sv-facetedsearch-portlet .sol-pagination .pagination > *:first-child:not(span),
.sv-standardsearch-portlet .sol-pagination .pagination > *:last-child:not(span),
.sv-facetedsearch-portlet .sol-pagination .pagination > *:last-child:not(span) {
  border: none;
  width: auto;
  height: auto;
  border-radius: 0;
  font-size: 14px;
  padding: 4px 0 0;
  text-decoration: underline;
  margin-left: 15px;
  margin-right: 15px;
}
.sv-standardsearch-portlet .sol-pagination .pagination > *:first-child:not(span):hover,
.sv-facetedsearch-portlet .sol-pagination .pagination > *:first-child:not(span):hover,
.sv-standardsearch-portlet .sol-pagination .pagination > *:last-child:not(span):hover,
.sv-facetedsearch-portlet .sol-pagination .pagination > *:last-child:not(span):hover {
  background-color: transparent;
  color: #af1d35;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current.next,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current.next,
.sv-standardsearch-portlet .sol-pagination .pagination span.current.prev,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current.prev {
  display: none;
}
/* Listan med knappar */
.sharebuttonlist {
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
}
.sharebuttonlist--horizontal .sharebuttonlist__item {
  display: inline-block;
}
/* Delaknappar */
.sharebutton {
  display: inline-block;
  height: 30px;
  margin: 0 1em 0.5em 0;
  padding: 0 16px;
  background-color: #ccc;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 2;
  text-decoration: none;
  background-size: 27px auto;
  background-position: 12px 50%;
  background-repeat: no-repeat;
}
.sharebutton--facebook {
  background-color: #3b5998;
}
.sharebutton--twitter {
  background-color: #00aced;
}
.sharebutton--linkedin {
  background-color: #007bb6;
}
.sharebutton--gplus {
  background-color: #dd4b39;
}
.sharebutton--email {
  background-color: #57b51d;
}
/* Ikoner på delaknapparna */
.sharebutton--icon-facebook::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 20px;
  content: "\f39e";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Brands';
  text-align: center;
  width: 44px;
  padding-top: 12px;
}
.sharebutton--icon-facebook::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-facebook::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-facebook::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-facebook::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-twitter::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 20px;
  content: "\f099";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Brands';
  text-align: center;
  width: 44px;
  padding-top: 12px;
}
.sharebutton--icon-twitter::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-twitter::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-twitter::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-twitter::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-linkedin::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 20px;
  content: "\f08c";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Brands';
  text-align: center;
  width: 44px;
  padding-top: 12px;
}
.sharebutton--icon-linkedin::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-linkedin::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-linkedin::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-linkedin::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-email::after {
  font-weight: 900;
  font-size: 20px;
  content: "\f0e0";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  text-align: center;
  width: 44px;
  padding-top: 12px;
}
.sharebutton--icon-email::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-email::after.fa-pull-right {
  margin-left: 0.3em;
}
.sharebutton--icon-email::after.fa-pull-left {
  margin-right: 0.3em;
}
.sharebutton--icon-email::after.fa-pull-right {
  margin-left: 0.3em;
}
/* Dölj text på delaknappen och gör den rund (kombinera med ikoner på knapparna =) */
.sharebutton--hide-text {
  width: 44px;
  height: 44px;
  margin: 0 0.125em;
  padding: 0;
  background-position: 50% 50%;
  border-radius: 100%;
}
.sharebutton--hide-text .sharebutton__text {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.sol-icon__tipsa {
  margin-right: 12px;
  max-height: 27.6px;
}
.sol-icon__tipsa::after {
  height: 27.6px;
  width: 29.5px;
}
.sol-event-page .font-heading-1::after {
  display: none;
}
.sol-event-page__create-event-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-bottom: 20px;
}
.sol-event-page__create-event-btn:focus,
[data-whatinput="keyboard"] .sol-event-page__create-event-btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-page__create-event-btn:focus,
[data-whatinput="mouse"] .sol-event-page__create-event-btn:focus,
[data-whatinput="touch"] .sol-event-page__create-event-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-page__create-event-btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-page__create-event-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-page__create-event-btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-event-page__create-event-btn__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 4px;
  top: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* events/event-registration.less */
.sol-event-form-n-more-info-wrapper .sol-collapsible {
  max-width: 100%;
}
.sol-event-form {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .sol-event-form .sol-event-form__buttons {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .sol-event-form .sol-event-form__buttons > button[type="submit"] {
    width: 100%;
  }
}
.sol-event-form .sol-remove-time {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  height: 40px;
  margin-left: 1%;
}
.sol-event-form .sol-remove-time:focus,
[data-whatinput="keyboard"] .sol-event-form .sol-remove-time:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-form .sol-remove-time:focus,
[data-whatinput="mouse"] .sol-event-form .sol-remove-time:focus,
[data-whatinput="touch"] .sol-event-form .sol-remove-time:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form .sol-remove-time:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-form .sol-remove-time:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-form .sol-remove-time i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .sol-event-form .sol-remove-time {
    margin-bottom: 15px;
  }
}
.sol-event-form .sol-remove-time i.fal {
  margin-left: auto;
  margin-right: auto;
}
.sol-event-form .sol-datetime-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.sol-event-form .sol-datetime-input-container:not(:first-child) > div {
  width: 47%;
}
@media (max-width: 767px) {
  .sol-event-form .sol-datetime-input-container:not(:first-child) > div {
    width: 100%;
  }
}
.sol-event-form .sol-datetime-input-container:not(:first-child) > div:first-child {
  margin-right: 2%;
}
.sol-event-form .sol-datetime-input-container > div {
  width: 47%;
}
@media (max-width: 767px) {
  .sol-event-form .sol-datetime-input-container > div {
    flex-wrap: wrap;
    width: 100%;
  }
}
.sol-event-form__categories > div {
  display: inline-block;
}
.sol-event-form__categories > div:first-child {
  margin-right: 45px;
}
.sol-event-form__content {
  position: relative;
}
.sol-event-form__content h2 {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .sol-event-form__content h2 {
    font-size: 19px;
  }
}
.sol-event-form__content .sol-row > div {
  margin-bottom: 20px;
}
.sol-event-form__content .sol-form-row > div {
  margin-bottom: 20px;
}
.sol-event-form__content--loading::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.sol-event-form__divider {
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .sol-event-form__divider {
    padding-bottom: 0;
  }
}
.sol-event-form__buttons {
  padding-top: 18px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
}
.sol-event-form__buttons:after,
.sol-event-form__buttons:before {
  content: "";
  display: table;
}
.sol-event-form__buttons:after {
  clear: both;
}
.sol-event-form__error-msg {
  display: block;
  color: #d53636;
  margin-bottom: 15px;
  font-weight: 500;
  margin-top: 5px;
}
.sol-event-form__error-msg:after {
  content: '';
  height: 19px;
  width: 19px;
  display: inline-block;
  background-image: url('/webdav/files/System/resources/graphics/icons/forms/times-invalid.svg');
  background-size: cover;
  margin-left: 2px;
  position: relative;
  top: 4px;
  margin-top: -4px;
}
.sol-event-form fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.sol-event-form input[type="text"],
.sol-event-form input[type="tel"],
.sol-event-form input[type="email"],
.sol-event-form input[type="url"] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.sol-event-form input[type="text"]::-webkit-input-placeholder,
.sol-event-form input[type="tel"]::-webkit-input-placeholder,
.sol-event-form input[type="email"]::-webkit-input-placeholder,
.sol-event-form input[type="url"]::-webkit-input-placeholder {
  color: #313131;
}
.sol-event-form input[type="text"]:-moz-placeholder,
.sol-event-form input[type="tel"]:-moz-placeholder,
.sol-event-form input[type="email"]:-moz-placeholder,
.sol-event-form input[type="url"]:-moz-placeholder {
  color: #313131;
}
.sol-event-form input[type="text"]::-moz-placeholder,
.sol-event-form input[type="tel"]::-moz-placeholder,
.sol-event-form input[type="email"]::-moz-placeholder,
.sol-event-form input[type="url"]::-moz-placeholder {
  color: #313131;
}
.sol-event-form input[type="text"]:-ms-input-placeholder,
.sol-event-form input[type="tel"]:-ms-input-placeholder,
.sol-event-form input[type="email"]:-ms-input-placeholder,
.sol-event-form input[type="url"]:-ms-input-placeholder {
  color: #313131;
}
.sol-event-form input[type="text"]:focus,
.sol-event-form input[type="tel"]:focus,
.sol-event-form input[type="email"]:focus,
.sol-event-form input[type="url"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form input[type="text"]:hover,
.sol-event-form input[type="tel"]:hover,
.sol-event-form input[type="email"]:hover,
.sol-event-form input[type="url"]:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-event-form input[type="text"]:focus,
.sol-event-form input[type="tel"]:focus,
.sol-event-form input[type="email"]:focus,
.sol-event-form input[type="url"]:focus {
  background-color: #fff;
  border-color: black;
}
.sol-event-form input[type="text"]::-ms-clear,
.sol-event-form input[type="tel"]::-ms-clear,
.sol-event-form input[type="email"]::-ms-clear,
.sol-event-form input[type="url"]::-ms-clear,
.sol-event-form input[type="text"]::-webkit-search-cancel-button,
.sol-event-form input[type="tel"]::-webkit-search-cancel-button,
.sol-event-form input[type="email"]::-webkit-search-cancel-button,
.sol-event-form input[type="url"]::-webkit-search-cancel-button,
.sol-event-form input[type="text"]::-webkit-search-decoration,
.sol-event-form input[type="tel"]::-webkit-search-decoration,
.sol-event-form input[type="email"]::-webkit-search-decoration,
.sol-event-form input[type="url"]::-webkit-search-decoration,
.sol-event-form input[type="text"]::-webkit-search-results-button,
.sol-event-form input[type="tel"]::-webkit-search-results-button,
.sol-event-form input[type="email"]::-webkit-search-results-button,
.sol-event-form input[type="url"]::-webkit-search-results-button,
.sol-event-form input[type="text"]::-webkit-search-results-decoration,
.sol-event-form input[type="tel"]::-webkit-search-results-decoration,
.sol-event-form input[type="email"]::-webkit-search-results-decoration,
.sol-event-form input[type="url"]::-webkit-search-results-decoration {
  display: none;
}
.sol-event-form input[type="text"].ng-invalid.ng-touched,
.sol-event-form input[type="tel"].ng-invalid.ng-touched,
.sol-event-form input[type="email"].ng-invalid.ng-touched,
.sol-event-form input[type="url"].ng-invalid.ng-touched,
.ng-submitted.sol-event-form input[type="text"].ng-invalid,
.ng-submitted.sol-event-form input[type="tel"].ng-invalid,
.ng-submitted.sol-event-form input[type="email"].ng-invalid,
.ng-submitted.sol-event-form input[type="url"].ng-invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sol-event-form textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  min-height: 120px;
  height: auto;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sol-event-form textarea::-webkit-input-placeholder {
  color: #313131;
}
.sol-event-form textarea:-moz-placeholder {
  color: #313131;
}
.sol-event-form textarea::-moz-placeholder {
  color: #313131;
}
.sol-event-form textarea:-ms-input-placeholder {
  color: #313131;
}
.sol-event-form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-event-form textarea:focus {
  background-color: #fff;
  border-color: black;
}
.sol-event-form textarea::-ms-clear,
.sol-event-form textarea::-webkit-search-cancel-button,
.sol-event-form textarea::-webkit-search-decoration,
.sol-event-form textarea::-webkit-search-results-button,
.sol-event-form textarea::-webkit-search-results-decoration {
  display: none;
}
.sol-event-form textarea.ng-invalid.ng-touched,
.ng-submitted.sol-event-form textarea.ng-invalid {
  border-color: #af1a1a;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/form/times_invalid.svg');
}
.sol-event-form input[type="checkbox"] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-event-form input[type="checkbox"] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-form input[type="checkbox"] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-event-form input[type="checkbox"] + label:hover::before {
  border-color: #257088;
}
.sol-event-form input[type="checkbox"]:focus + label::before,
.sol-event-form input[type="checkbox"]:focus:checked + label::before {
  border: 2px solid black;
}
.sol-event-form input[type="checkbox"]:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.sol-event-form input[type="checkbox"]:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-form input[type="checkbox"]:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-form input[type="radio"] {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-event-form input[type="radio"] + label::before {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 2px;
  position: relative;
  top: 1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-form input[type="radio"] + label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-event-form input[type="radio"] + label:hover::before {
  border-color: #257088;
}
.sol-event-form input[type="radio"]:focus + label::before,
.sol-event-form input[type="radio"]:focus:checked + label::before {
  border: 2px solid black;
}
.sol-event-form input[type="radio"] + label:before {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 1px;
}
.sol-event-form input[type="radio"]:checked + label:before {
  border-width: 3px;
  border-color: #2A809C;
  background-color: #2A809C;
}
.sol-event-form .sol-event-form__file {
  display: block;
  height: 0;
  opacity: 0;
}
.sol-event-form .sol-event-form__file + label {
  margin-bottom: 20px;
}
.sol-event-form .sol-event-form__file + label:after,
.sol-event-form .sol-event-form__file + label:before {
  content: "";
  display: table;
}
.sol-event-form .sol-event-form__file + label:after {
  clear: both;
}
.sol-event-form .sol-event-form__file__label {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-bottom: 8px;
}
.sol-event-form .sol-event-form__file__btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  padding: 5px 20px;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.sol-event-form .sol-event-form__file__btn:focus,
[data-whatinput="keyboard"] .sol-event-form .sol-event-form__file__btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-form .sol-event-form__file__btn:focus,
[data-whatinput="mouse"] .sol-event-form .sol-event-form__file__btn:focus,
[data-whatinput="touch"] .sol-event-form .sol-event-form__file__btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form .sol-event-form__file__btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-form .sol-event-form__file__btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-form .sol-event-form__file__btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-event-form .sol-event-form__file__text {
  float: left;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 300;
  line-height: 34px;
}
.sol-event-form .sol-event-form__file__remove {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  min-height: 32px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.sol-event-form .sol-event-form__file__remove i {
  font-size: 18px;
  color: #af1d35;
}
.sol-event-form .sol-event-form__file__btn {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0!important;
  min-height: 35px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .sol-event-form .sol-event-form__file__btn {
    min-width: 110px;
    min-height: 50px;
  }
}
.sol-event-form .sol-event-form__file__text {
  float: none !important;
}
.sol-event-form .sol-event-form__file + label {
  margin-bottom: 0;
}
.sol-event-form button[type="submit"] {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
}
.sol-event-form button[type="submit"]:focus,
[data-whatinput="keyboard"] .sol-event-form button[type="submit"]:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-form button[type="submit"]:focus,
[data-whatinput="mouse"] .sol-event-form button[type="submit"]:focus,
[data-whatinput="touch"] .sol-event-form button[type="submit"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form button[type="submit"]:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-form button[type="submit"]:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-form button[type="submit"] i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-event-form button[type="submit"] {
    min-width: 140px;
    min-height: 50px;
  }
}
.sol-event-form .sol-add-time {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-top: 17px;
  margin-bottom: 37px;
  min-height: 35px !important;
}
.sol-event-form .sol-add-time:focus,
[data-whatinput="keyboard"] .sol-event-form .sol-add-time:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-form .sol-add-time:focus,
[data-whatinput="mouse"] .sol-event-form .sol-add-time:focus,
[data-whatinput="touch"] .sol-event-form .sol-add-time:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-form .sol-add-time:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-form .sol-add-time:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-form .sol-add-time i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .sol-event-form .sol-add-time {
    min-height: 50px !important;
  }
}
@media (max-width: 767px) {
  .sol-event-form .sol-add-time {
    width: 100%;
  }
}
.sol-event-form .sol-loading {
  position: absolute;
  top: 60px;
  left: 50%;
  margin-left: -50px;
  z-index: 1;
}
.sol-event-form .sol-loading svg {
  width: 100px;
  height: 100px;
}
.sol-event-form .sol-loading svg path {
  fill: #af1d35;
}
.sol-event-listing-page {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-event-listing-page {
    width: 79%;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page .font-heading-1 {
    margin-left: 4%;
    margin-right: 4%;
  }
}
.sol-event-listing-page__highlighted-event__list {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .sol-event-listing-page__highlighted-event__list {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page__highlighted-event__list {
    padding-left: 4%;
    padding-right: 4%;
  }
}
.sol-event-listing-page__highlighted-event__item {
  text-decoration: none;
  flex-basis: 32%;
  flex-grow: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sol-event-listing-page__highlighted-event__item {
    max-height: 130px;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page__highlighted-event__item {
    max-height: 100%;
  }
}
.sol-event-listing-page__highlighted-event__item:hover,
.sol-event-listing-page__highlighted-event__item:active {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
.sol-event-listing-page__highlighted-event__item:hover .sol-event-listing-page__highlighted-event__item__content__title,
.sol-event-listing-page__highlighted-event__item:active .sol-event-listing-page__highlighted-event__item__content__title {
  text-decoration: underline;
}
.sol-event-listing-page__highlighted-event__item:nth-child(3n+2) {
  margin-left: 1.5%;
  margin-right: 1.5%;
}
@media (max-width: 991px) {
  .sol-event-listing-page__highlighted-event__item:nth-child(3n+2) {
    margin-left: 0;
    margin-right: 0;
  }
}
.sol-event-listing-page__highlighted-event__item__image {
  height: 200px;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 991px) {
  .sol-event-listing-page__highlighted-event__item__image {
    height: 130px;
    width: 33%;
    float: left;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page__highlighted-event__item__image {
    height: 200px;
    width: 100%;
    float: none;
  }
}
.sol-event-listing-page__highlighted-event__item__content {
  background-color: #fff;
  padding: 17px 25px 31px 21px;
  min-height: 155px;
}
@media (max-width: 991px) {
  .sol-event-listing-page__highlighted-event__item__content {
    width: 67%;
    float: left;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page__highlighted-event__item__content {
    width: 100%;
    padding-top: 16px;
    float: none;
  }
}
.sol-event-listing-page__highlighted-event__item__content__date > span {
  font-size: 17px;
  line-height: 21px;
  font-weight: 300;
  color: #313131;
  margin-bottom: 4px;
}
.sol-event-listing-page__highlighted-event__item__content__title {
  font-size: 21px;
  text-align: left;
  color: #204560;
}
/* events/moment-picker-modified.less */
.moment-picker-input {
  cursor: pointer;
}
.moment-picker {
  position: absolute;
  z-index: 1000;
}
.moment-picker-container {
  color: #262626;
  min-width: 20em;
  background: #fff;
  padding: 4px;
  border: 1px solid #e7e7e7;
  position: absolute;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.moment-picker.inline {
  display: block;
  position: relative;
}
.moment-picker.inline .moment-picker-container {
  position: relative;
  margin: 0;
}
.moment-picker.top .moment-picker-container {
  bottom: 100%;
  margin-top: auto;
  margin-bottom: 4px;
}
.moment-picker .right .moment-picker-container {
  right: 0;
  margin-left: auto;
  margin-right: -0.5em;
}
.moment-picker table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  table-layout: fixed;
}
.moment-picker th {
  font-weight: bold;
}
.moment-picker th:first-child,
.moment-picker th:last-child {
  width: 2em;
}
.moment-picker th,
.moment-picker td {
  padding: 0;
  text-align: center;
  min-width: 2em;
  height: 2em;
  cursor: pointer;
  border-radius: 2px;
}
.moment-picker th:hover,
.moment-picker td:hover {
  background-color: #e8f3f8;
}
.moment-picker th.disabled,
.moment-picker td.disabled,
.moment-picker th.disabled:hover,
.moment-picker td.disabled:hover {
  color: #e7e7e7;
  background: none;
  cursor: default;
}
.moment-picker th.today,
.moment-picker td.today {
  background: #d3d3d3;
  color: #262626;
}
.moment-picker th.selected,
.moment-picker td.selected {
  color: #fff;
  border-color: #af1d35;
  background-color: #af1d35;
}
.moment-picker th.highlighted,
.moment-picker td.highlighted {
  background-color: #af1d35;
  color: #fff;
}
.moment-picker .decade-view td,
.moment-picker .year-view td {
  height: 3.4em;
}
.moment-picker .month-view .moment-picker-specific-views th {
  background: none;
  cursor: default;
}
.moment-picker .month-view td {
  width: 1.42857143em;
}
.moment-picker .day-view td,
.moment-picker .hour-view td {
  height: 2.33333333em;
}
.moment-picker .minute-view td {
  height: 1.8em;
}
.moment-picker i.fa-angle-right,
.moment-picker i.fa-angle-left {
  font-size: 17px;
  position: relative;
  top: 1px;
}
.sol-icon__clock-small {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 2px;
}
.sol-event-item--img,
.sol-event-item--no-img {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 2px 20px 30px 0;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  min-height: 140px;
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .sol-event-item--img,
  .sol-event-item--no-img {
    padding-top: 190px;
    padding-right: 20px;
    padding-left: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
  }
}
.sol-event-item--img:hover .sol-event-item__img:before,
.sol-event-item--no-img:hover .sol-event-item__img:before {
  background-color: rgba(175, 29, 53, 0.4);
}
.sol-event-item--img:hover .sol-event-item__title,
.sol-event-item--no-img:hover .sol-event-item__title {
  color: #af1d35;
  text-decoration: underline;
}
.sol-event-item--img {
  padding-left: 190px;
}
@media (max-width: 991px) {
  .sol-event-item--img {
    margin-left: 16px;
  }
}
@media (max-width: 767px) {
  .sol-event-item--img {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sol-event-item--no-img {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding-left: 4%;
  }
}
.sol-event-item__img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 180px;
  height: 140px;
  text-decoration: none;
  border-radius: 8px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .sol-event-item__img {
    width: 160px;
  }
}
@media (max-width: 767px) {
  .sol-event-item__img {
    width: 100%;
    height: 180px;
  }
}
.sol-event-item__img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .sol-event-item__content {
    padding-top: 16px;
  }
}
.sol-event-item__date-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: #727272;
  margin-bottom: 8px;
  font-weight: 400;
}
.sol-event-item__title {
  display: block;
  margin-bottom: 8px;
  font-size: 25px;
  font-weight: 300;
  font-family: 'Quicksand', sans-serif;
  line-height: 32px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-search__filters {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .sol-event-search__filters {
    margin-bottom: 0;
    flex-direction: column;
  }
}
.sol-event-search__filters > div {
  flex-basis: 48%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-event-search__filters > div {
    flex-basis: 46%;
  }
}
@media (max-width: 767px) {
  .sol-event-search__filters > div {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
}
.sol-event-search__filters .sol-event-search-collapsible,
.sol-event-search__filters .sol-event-category-collapsible {
  position: relative;
}
@media (max-width: 991px) {
  .sol-event-search__filters .sol-event-search-collapsible,
  .sol-event-search__filters .sol-event-category-collapsible {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-event-search__filters .sol-event-search-collapsible,
  .sol-event-search__filters .sol-event-category-collapsible {
    padding-left: 4%;
    padding-right: 4%;
  }
}
.sol-event-search__filters .sol-event-search-collapsible__button,
.sol-event-search__filters .sol-event-category-collapsible__button {
  border-radius: 8px;
  webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0;
  border: 0;
  background-color: #2A809C;
  color: #fff;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  text-align: left;
  position: relative;
  cursor: pointer;
  line-height: normal;
  overflow: visible;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: ease;
  z-index: 4;
}
.sol-event-search__filters .sol-event-search-collapsible__button__text,
.sol-event-search__filters .sol-event-category-collapsible__button__text {
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
}
.sol-event-search__filters .sol-event-search-collapsible__button__btn,
.sol-event-search__filters .sol-event-category-collapsible__button__btn {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sol-event-search__filters .sol-event-search-collapsible__button__btn::before,
.sol-event-search__filters .sol-event-category-collapsible__button__btn::before,
.sol-event-search__filters .sol-event-search-collapsible__button__btn::after,
.sol-event-search__filters .sol-event-category-collapsible__button__btn::after {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 4px;
  margin-left: -11px;
  margin-top: -2px;
  border-radius: 2px;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.sol-event-search__filters .sol-event-search-collapsible__button__btn::after,
.sol-event-search__filters .sol-event-category-collapsible__button__btn::after {
  transform: rotate(90deg);
}
.sol-event-search__filters .sol-event-search-collapsible__content,
.sol-event-search__filters .sol-event-category-collapsible__content {
  background-color: #fff;
  width: 95%;
  position: absolute;
  top: 40px;
  padding: 0 13px;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -moz-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  -o-transform: translatex(-50%);
  transform: translatex(-50%);
  max-height: 0;
  overflow: hidden;
  transition-property: padding, max-height;
  transition-duration: 200ms;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .sol-event-search__filters .sol-event-search-collapsible__content,
  .sol-event-search__filters .sol-event-category-collapsible__content {
    width: 90%;
  }
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date label,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #262626;
  margin-top: 11px;
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date .el-date-editor,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date .el-date-editor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /*input {
            display: flex;
            border: solid 1px #ececec;
            border-radius: 2px;
            min-height: 40px;
            font-size: 15px;
            height: auto;
            padding: 5px 15px;
            max-width: 42%;
           
            &#filterStartDate, #filterEndDate {
              position: relative;
            }
          }*/
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date .el-date-editor i,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date .el-date-editor i {
  display: none;
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date .el-date-editor:first-child,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date .el-date-editor:first-child {
  flex-basis: 100%;
  max-width: 100%;
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date .sol-form-btn__container,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date .sol-form-btn__container {
  flex-basis: 100%;
  width: 100%;
}
.sol-event-search__filters .sol-event-search-collapsible__content .sol-article-filter__date .sol-form-btn__container .sol-btn,
.sol-event-search__filters .sol-event-category-collapsible__content .sol-article-filter__date .sol-form-btn__container .sol-btn {
  width: 100%;
}
.sol-event-search__filters .sol-event-search-collapsible__content__dates,
.sol-event-search__filters .sol-event-category-collapsible__content__dates {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}
.sol-event-search__filters .sol-event-search-collapsible__content__dates > div,
.sol-event-search__filters .sol-event-category-collapsible__content__dates > div {
  display: inline-flex;
  flex-basis: 47%;
  max-width: 47%;
  flex-direction: column;
}
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-search-collapsible__content,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-search-collapsible__content,
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-category-collapsible__content,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-category-collapsible__content {
  max-height: 1000px !important;
  z-index: 2;
  overflow: visible;
  padding: 31px 13px 22px;
}
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-search-collapsible__button,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-search-collapsible__button,
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-category-collapsible__button,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-category-collapsible__button {
  background-color: #41869f;
  color: #fff;
}
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-search-collapsible__button__btn::after,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-search-collapsible__button__btn::after,
.sol-event-search__filters .sol-event-search-collapsible--expanded .sol-event-category-collapsible__button__btn::after,
.sol-event-search__filters .sol-event-category-collapsible--expanded .sol-event-category-collapsible__button__btn::after {
  transform: rotate(0deg);
}
@media (max-width: 767px) {
  .sol-event-search__filter {
    z-index: 10;
  }
}
.sol-event-search__categories__content > ul {
  padding-left: 12px !important;
}
.sol-event-search__categories__content input[type=checkbox],
.sol-event-search__categories__content input[type=radio] {
  display: none;
}
.sol-event-search__categories__content input[type=checkbox] + label:before,
.sol-event-search__categories__content input[type=radio] + label:before {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #2A809C;
  border-radius: 3px;
  position: relative;
  top: -1px;
  margin-right: 10px;
  float: left;
  text-align: center;
  -webkit-transition-property: color, background-color, border-color, border-width;
  transition-property: color, background-color, border-color, border-width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-search__categories__content input[type=checkbox] + label,
.sol-event-search__categories__content input[type=radio] + label {
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.38;
  color: #3b3b3b;
}
.sol-event-search__categories__content input[type=checkbox] + label span,
.sol-event-search__categories__content input[type=radio] + label span {
  font-size: 16px;
  line-height: 1.38;
  color: #969696;
}
.sol-event-search__categories__content input[type=checkbox]:checked + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  background-color: #2A809C;
  border-color: #2A809C;
  color: #fff;
}
.sol-event-search__categories__content input[type=checkbox]:checked + label:before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-event-search__categories__content input[type=checkbox]:checked + label:before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-event-search__categories__content input[type=checkbox] + label:hover::before {
  border-color: rgba(110, 205, 233, 0.4);
}
.sol-event-search__categories__content input[type=radio] + label:before {
  border-radius: 50%;
}
.sol-event-search__categories__content input[type=radio]:checked + label:before {
  background-color: #204560;
  border-color: #204560;
}
.sol-event-search__filter-n-categories .reset-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}
.sol-event-search__filter-n-categories .reset-btn:hover span {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .sol-event-search__filter-n-categories .font-btn {
    margin-left: 4%;
    margin-right: 4%;
  }
}
.sol-event-search__tabs {
  display: flex;
  padding-left: 103px;
  border-bottom: 3px solid #41869f;
  margin-top: 25px;
  margin-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-event-search__tabs {
    padding-left: 0;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .sol-event-search__tabs {
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 40px;
  }
}
.sol-event-search__tabs > .sol-event-search__tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 45px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border: solid 1px rgba(51, 51, 51, 0.11);
  text-decoration: none;
  font-size: 18px;
}
.sol-event-search__tabs > .sol-event-search__tab span {
  margin-left: 5px;
}
.sol-event-search__tabs > .sol-event-search__tab:first-child {
  margin-right: 9px;
}
.sol-event-search__tabs > .sol-event-search__tab--active {
  background-color: #0e3c5d;
  color: #fff;
}
@media (max-width: 767px) {
  .sol-event-listing-page .sol-event-search .sol-top .sol-flex-row {
    padding-left: 4%;
    padding-right: 4%;
  }
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn {
  margin-top: 38px;
  max-width: 570px;
  border-radius: 100px;
  webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: #2A809C;
  padding: 8px 28px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  text-align: left;
  position: relative;
  cursor: pointer;
  line-height: normal;
  overflow: visible;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: ease;
  font-size: 20px;
  line-height: 1.8;
  text-decoration: none;
  margin-left: 103px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn {
    margin-left: 0;
    width: 100%;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn {
    width: auto;
    max-width: none;
    margin-left: 4%;
    margin-right: 4%;
  }
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn:hover {
  text-decoration: underline;
  background-color: #2f90b0;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn .sol-icon__tipsa {
  margin-right: 12px;
  max-height: 27.6px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn .sol-icon__tipsa::after {
  height: 27.6px;
  width: 29.5px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn .sol-icon__tipsa.sol-icon-edge {
  height: 27.6px;
  width: 29.5px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn .sol-icon__tipsa.sol-icon-edge::after {
  transform: scale(0.5);
  height: 55.2px;
  width: 59px;
  margin-top: -13.8px;
  margin-left: -14.75px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 4px;
  top: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn__icon .sol-icon {
  display: flex;
  max-height: 17px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn__icon .sol-icon::after {
  height: 17px;
  width: 19px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn__icon .sol-icon.sol-icon-edge {
  height: 17px;
  width: 19px;
}
.sol-event-listing-page .sol-event-search .sol-event-listing-page__create-event-btn__icon .sol-icon.sol-icon-edge::after {
  transform: scale(0.5);
  height: 34px;
  width: 38px;
  margin-top: -8.5px;
  margin-left: -9.5px;
}
.sol-event-page .sol-other-dates ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sol-event-page .sol-flex-cnt {
  display: flex;
}
.sol-event-page .sol-event__show-all-dates {
  color: #204560;
  position: relative;
  z-index: 1;
  text-decoration: none;
  background-size: 200% 200%;
  background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 50%, transparent 50%);
  -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  padding-left: 1px;
  padding-right: 1px;
  border-bottom: 1px solid rgba(110, 205, 233, 0.6);
  font-size: 17px;
  font-weight: normal;
}
.sol-event-page .sol-event__show-all-dates:hover {
  background-image: linear-gradient(to top, rgba(110, 205, 233, 0.6) 51%, transparent 50%);
  background-position: 0 100%;
  -webkit-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -moz-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -ms-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  -o-transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
}
@media (max-width: 767px) {
  .sol-event-page {
    margin-right: 15px;
    margin-left: 15px;
  }
  .sol-event-page .sv-image-portlet,
  .sol-event-page .sol-event-info {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.sol-event-page h1.font-heading-1 {
  padding-bottom: 0;
  margin-bottom: 22px;
}
.sol-event-page h1.font-heading-1::after {
  display: none;
}
.sol-icon__tipsa {
  margin-right: 12px;
  max-height: 27.6px;
}
.sol-icon__tipsa::after {
  height: 27.6px;
  width: 29.5px;
}
.sol-event-page__create-event-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: #fff;
  border-radius: 8px;
  background-color: #2A809C;
  box-shadow: inset 0 -2px 0 0 transparent;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  border: 0;
  margin-bottom: 20px;
  /*     margin: 25px 0;
    max-width: 570px;
    border-radius: 100px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: @event-tipsa-bg;
    padding: 8px 28px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 50px;
    text-align: left;
    position: relative;
    cursor: pointer;
    overflow: visible;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    font-size: 20px;
    line-height: 1.8;
    text-decoration: none; */
}
.sol-event-page__create-event-btn:focus,
[data-whatinput="keyboard"] .sol-event-page__create-event-btn:focus {
  outline: 2px solid #fff;
  z-index: 10;
  outline-offset: 0;
  box-shadow: 0 0 0 4px black;
}
[data-whatinput="initial"] .sol-event-page__create-event-btn:focus,
[data-whatinput="mouse"] .sol-event-page__create-event-btn:focus,
[data-whatinput="touch"] .sol-event-page__create-event-btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-event-page__create-event-btn:hover {
  background-color: #3f8da6;
  color: #fff;
}
.sol-event-page__create-event-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 transparent;
}
.sol-event-page__create-event-btn i.fal {
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 400;
}
.sol-event-page__create-event-btn__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 4px;
  top: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sol-landing-page {
  background: #fff;
}
.sol-landing-page h1,
.sol-landing-page h1.font-heading-1 {
  text-align: center;
  margin: 30px 0 20px 0;
}
@media (max-width: 767px) {
  .sol-landing-page .pagecontent {
    width: 100%;
  }
  .sol-landing-page .pagecontent .sol-widget-decoration {
    margin-bottom: 30px;
  }
}
.sol-landing-page__listing .sv-script-portlet {
  width: 100%;
}
.sol-landing-page__top {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 90px 15px;
  background-color: #af1d35;
}
@media (max-width: 767px) {
  .sol-landing-page__top {
    padding: 50px 15px;
  }
}
.sol-landing-page__content {
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 660px;
  padding: 25px 35px;
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 767px) {
  .sol-landing-page__content {
    padding: 25px;
  }
}
.sol-landing-page__content h1.font-heading-1 {
  margin-top: 0;
}
.sol-landing-page__widgets {
  background: #f4f4f4;
  padding: 30px 0;
}
@media (max-width: 767px) {
  .sol-landing-page__widgets {
    padding-bottom: 0;
  }
}
.sol-landing-page__widgets .pagecontent {
  width: 100%;
  max-width: 1170px;
  margin: auto;
}
.sol-landing-page__widgets .pagecontent > .sv-layout {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 767px) {
  .sol-landing-page__widgets .pagecontent > .sv-layout {
    flex-wrap: wrap;
  }
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout {
  flex: 0 0 23%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(38, 38, 38, 0.05);
  margin-right: 10px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout {
    flex: 100%;
    margin-bottom: 20px;
    margin-left: 1px;
    margin-right: 1px;
  }
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout:first-child {
  margin-left: 0;
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout:last-child {
    margin-right: 1px;
  }
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration {
  background-color: transparent;
  box-shadow: none;
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-image-portlet {
  margin-bottom: 0;
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet {
  padding: 25px 10px 25px 27px;
  position: relative;
  border-top: 5px solid #af1d35;
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  top: 25px;
  left: 15px;
  right: auto;
  background-image: none;
  height: auto;
  font-size: 20px;
  content: "\f105";
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet::after.fa-pull-left {
  margin-right: 0.3em;
}
.sol-landing-page__widgets .pagecontent > .sv-layout > .sv-layout .sol-widget-decoration .sv-text-portlet::after.fa-pull-right {
  margin-left: 0.3em;
}
/* lessfile: modal.less */
.sol-menu-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  overflow: scroll;
}
.sol-menu-modal-wrapper {
  display: table-cell;
  vertical-align: top;
}
.sol-menu-modal-container {
  width: 325px;
  margin: 148px auto 0;
  margin-top: 0px;
  float: left;
  transition: opacity 0.3s ease;
  opacity: 1;
  background-color: #af1d35;
}
.sol-menu-modal-container:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-menu-modal-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 24px;
  right: 45px;
  color: #fff;
  font-weight: 300;
  font-size: 17px;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sol-menu-modal-close {
    top: 5px;
    right: 10px;
  }
}
.sol-menu-modal-close i {
  display: block;
  margin-bottom: 5px;
  font-size: 44px;
  color: rgba(255, 255, 255, 0.91);
}
.sol-menu-modal-transition-enter {
  opacity: 0;
}
.sol-menu-modal-transition-leave-active {
  opacity: 0;
}
.sol-menu-modal-transition-enter .sol-menu-modal-container {
  opacity: 0;
}
.sol-menu-modal-transition-leave-active .sol-menu-modal-container {
  opacity: 0;
}
.sol-mega-menu {
  width: 325px;
  min-height: 400px;
}
.sol-mega-menu ul {
  padding-left: 0px;
}
.sol-mega-menu > ul > li {
  list-style: none;
  height: 50px;
  margin-left: 0px;
}
.sol-mega-menu-topitem {
  text-decoration: none;
  position: relative;
  display: flex;
  vertical-align: middle;
  z-index: 1;
}
.sol-mega-menu-topitem:hover {
  background-color: #a21b31;
}
.sol-mega-menu-topitem__name {
  font-size: 20px;
  color: #fff;
  padding: 10px 0px;
  padding-left: 30px;
}
.sol-mega-menu-topitem--expanded {
  font-weight: 500;
}
.sol-mega-menu-topitem--active {
  font-weight: 500;
}
.sol-mega-menu-topitem--active[aria-expanded="false"] {
  background-color: #8c172a;
}
.sol-mega-menu-item {
  text-decoration: none;
}
.sol-mega-menu-item[data-level="2"] > span {
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 3px solid #f4f4f4;
  display: block;
}
.sol-mega-menu-item[data-level="2"][aria-current="page"] > span {
  font-weight: 700;
}
.sol-mega-menu-item[data-level="3"] > span {
  font-weight: 300;
}
.sol-mega-menu-item[data-level="3"][aria-current="page"] > span {
  font-weight: 500;
}
.sol-mega-menu-item > span {
  line-height: 25px;
}
.sol-mega-menu-item :hover {
  text-decoration: underline;
}
.sol-mega-menu-item--active[aria-current="page"] > span {
  text-decoration: underline;
  color: #af1d35;
}
.sol-mega-menu-item--active[aria-current="page"] > span::before {
  font-weight: 900;
  font-size: inherit;
  content: "\f105";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  margin-right: 5px;
}
.sol-mega-menu-item--active[aria-current="page"] > span::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-mega-menu-item--active[aria-current="page"] > span::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-mega-menu-item--active[aria-current="page"] > span::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-mega-menu-item--active[aria-current="page"] > span::before.fa-pull-right {
  margin-left: 0.3em;
}
.sol-mega-menu-items {
  position: absolute;
  background: #fff;
  left: 0px;
  top: 0px;
  width: 85vw;
  height: calc(100vh - 132px);
  overflow-y: scroll;
}
@media only screen and (min-width: 1900px) {
  .sol-mega-menu-items {
    width: 70vw;
  }
}
@media only screen and (max-width: 1900px) {
  .sol-mega-menu-items {
    width: 90vw;
  }
}
@media only screen and (max-width: 1450px) {
  .sol-mega-menu-items {
    width: 90vw;
  }
}
@media only screen and (max-width: 1200px) {
  .sol-mega-menu-items {
    width: 85vw;
  }
}
.sol-mega-menu-items > a {
  display: none;
  position: absolute;
  left: 365px;
  top: 30px;
  z-index: 1;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  border: 1px solid #e7e8e7;
  background-color: #f4f4f4;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.sol-mega-menu-items > a:hover {
  text-decoration: none;
  background-color: #e7e8e7;
}
.sol-mega-menu-items > ul {
  padding-top: 20px;
  padding-bottom: 60px;
  min-height: 600px;
  border-left: 325px solid #af1d35;
  column-count: 3;
}
@media only screen and (max-width: 1450px) {
  .sol-mega-menu-items > ul {
    column-count: 2;
  }
}
.sol-mega-menu-items > ul li {
  padding-left: 40px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  list-style: none;
  width: 100%;
  display: inline-block;
}
.sol-mega-menu-sublist > ul {
  padding-left: 0px !important;
}
.sol-mega-menu-sublist > ul li {
  width: 100%;
  margin-bottom: 2px;
  list-style: none;
  font-weight: 300;
  height: auto;
  padding-left: 0;
}
.sol-mega-menu__button {
  background: #af1d35;
  border: none;
  width: 100%;
  padding-left: 30px;
  z-index: 1;
  position: relative;
  display: flex;
  vertical-align: middle;
  cursor: pointer;
}
.sol-mega-menu__button:hover {
  background-color: #a21b31;
}
.sol-mega-menu__button[aria-expanded="true"] {
  background-color: #8c172a;
}
.sol-mega-menu__button .sol-mega-menu-topitem {
  background-color: #af1d35;
}
.sol-mega-menu__button .sol-mega-menu-topitem__name {
  line-height: 1.7em;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  line-height: 1.5em;
  font-weight: 400;
  float: left;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px;
  margin: 0px;
  padding: 10px 0px;
  color: #fff;
}
@media (max-width: 767px) {
  .sol-mega-menu__button .sol-mega-menu-topitem__name {
    font-size: 15px;
  }
}
a.sol-mega-menu__button .sol-mega-menu-topitem__name,
.sol-mega-menu__button .sol-mega-menu-topitem__name a {
  color: #2B6F88;
}
a.sol-mega-menu__button .sol-mega-menu-topitem__name:hover,
.sol-mega-menu__button .sol-mega-menu-topitem__name a:hover {
  color: #262626;
}
@media (max-width: 767px) {
  .sol-mega-menu__button .sol-mega-menu-topitem__name {
    font-size: 16px;
  }
}
a.sol-mega-menu__button .sol-mega-menu-topitem__name,
.sol-mega-menu__button .sol-mega-menu-topitem__name a {
  color: #2B6F88;
  font-weight: 400;
}
a.sol-mega-menu__button .sol-mega-menu-topitem__name:hover,
.sol-mega-menu__button .sol-mega-menu-topitem__name a:hover {
  color: #262626;
}
.sol-mega-menu__button .sol-mega-menu-topitem--expanded {
  font-weight: 500;
}
.sol-mega-menu__button .sol-mega-menu-topitem--active {
  font-weight: 500;
}
.sol-mega-menu__button.sol-mega-menu-topitem--expanded {
  background-color: #fff;
}
.sol-mega-menu__button.sol-mega-menu-topitem--expanded span {
  font-weight: 500;
  color: #262626;
}
.sol-mega-menu__button.sol-mega-menu-topitem--expanded i {
  color: #262626;
  color: #fff;
}
.sol-mega-menu__button.sol-mega-menu-topitem--active span.sol-mega-menu-topitem__name {
  font-weight: 500;
}
.sol-mega-menu__button i {
  right: 0px;
  line-height: 22px;
  padding: 10px 20px;
  position: absolute;
  font-size: 20px;
  color: #fff;
}
.sol-mega-menu-expanded {
  height: 900px;
}
.sol-article-item .sol-article-item-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.sol-breadcrumbs-wrapper {
  display: inherit;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after,
.sol-breadcrumbs-wrapper .sol-breadcrumbs:before {
  content: "";
  display: table;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after {
  clear: both;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li {
  float: left;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a,
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span {
  font-size: 16px;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a {
  text-decoration: none;
  color: #af1d35;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a:hover {
  color: #262626;
  text-decoration: underline;
}
.sol-contact-card > .sv-text-portlet {
  background: #2A809C;
}
.sol-event-item-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.sol-event-item-date {
  border-radius: 8px;
}
.sol-event-item-info {
  margin-bottom: 3px;
  margin-bottom: 2px;
  font-weight: 400;
}
footer.sv-layout {
  background-color: #af1d35;
  background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-2r.svg);
  background-repeat: no-repeat;
}
.sol-startpage-entrances,
.sol-landing-page__listing {
  background-color: #2A809C;
  background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-1l.svg), url(/webdav/files/System/resources/graphics/Mora_kurbits-1r.svg);
  background-position: left 50%,
    right 50%;
  background-repeat: no-repeat;
  background-size: 250px 100%;
}
@media (max-width: 1199px) {
  .sol-startpage-entrances,
  .sol-landing-page__listing {
    background-image: url(/webdav/files/System/resources/graphics/Mora_kurbits-1l.svg);
    background-size: 290px 100%;
  }
}
@media (max-width: 991px) {
  .sol-startpage-entrances,
  .sol-landing-page__listing {
    background-size: 70%  100%;
    background-image: none;
  }
}
.sol-mora-vaxer-top h1,
.sol-mora-vaxer-top p {
  text-align: left;
}
.sol-mora-vaxer-top .tag {
  background-color: var(--sol-map-category-backgroundcolor);
  color: var(--sol-map-category-color);
}
.sol-mora-vaxer-image {
  display: flex;
  justify-content: center;
  max-width: 1240px !important;
}
.sol-mora-vaxer-image .sv-layout {
  width: 100%;
}
.sol-mora-vaxer-image .sv-portlet-image-caption {
  background-color: var(--sol-map-category-backgroundcolor);
  color: var(--sol-map-category-color);
  padding: 13px 10px;
  margin-top: 0;
  font-size: 14px;
}
.sol-mora-vaxer-image img {
  width: 100%;
  min-width: 100%;
  max-width: 100% !important;
  max-height: 515px !important;
  object-fit: cover;
}
.sol-startpage-entrances-mv .sol-landing-large {
  justify-content: center;
  align-items: center;
}
.sol-startpage-entrances-mv .sol-landing-large .expandable-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .sol-startpage-entrances-mv .sol-landing-large .expandable-content {
    display: none;
  }
  .sol-startpage-entrances-mv .sol-landing-large .expandable-content.expanded {
    display: flex;
    flex-wrap: wrap;
  }
}
.sol-startpage-entrances-mv .sol-landing-large #expand-btn {
  font-family: 'Quicksand', sans-serif;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 8px;
  display: none;
}
@media (max-width: 991px) {
  .sol-startpage-entrances-mv .sol-landing-large #expand-btn {
    display: block;
  }
}
.sol-startpage-entrances-mv .sol-landing-large #expand-btn .fa-chevron-down {
  color: white;
  font-size: 16px;
  margin-left: 4px;
}
.sol-startpage-entrances-mv .sol-landing-large #expand-btn.expanded .fa-chevron-down {
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  .sol-startpage-entrances-mv .sol-landing-large {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.sol-startpage-entrances-mv .sol-landing-large__item {
  flex: 0 0 auto;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 6px;
  margin-left: 6px;
  align-items: center;
}
@media (max-width: 991px) {
  .sol-startpage-entrances-mv .sol-landing-large__item {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}
.sol-startpage-entrances-mv .sol-landing-large__item a {
  display: flex;
  align-items: center;
}
.sol-startpage-entrances-mv .sol-landing-large__item .sol-landing-large__header {
  font-size: 18px;
  margin-bottom: 0;
}
.sol-startpage-entrances-mv .sol-landing-large__item .sol-landing-large__icon {
  width: 17px;
  height: 17px;
  font-size: 17px;
  margin-right: 2px;
}
@media (max-width: 991px) {
  .sol-startpage-entrances-mv .sol-landing-large__item .sol-landing-large__icon {
    top: auto;
  }
}
body {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  background-color: #fff;
  overflow-x: hidden;
}
p,
p.font-normal,
.font-normal,
p.font-description,
.font-description {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
}
@media (max-width: 767px) {
  p,
  p.font-normal,
  .font-normal,
  p.font-description,
  .font-description {
    font-size: 16px;
  }
}
ap,
ap.font-normal,
a.font-normal,
ap.font-description,
a.font-description,
p a,
p.font-normal a,
.font-normal a,
p.font-description a,
.font-description a {
  color: #2B6F88;
  font-weight: 400;
}
ap:hover,
ap.font-normal:hover,
a.font-normal:hover,
ap.font-description:hover,
a.font-description:hover,
p a:hover,
p.font-normal a:hover,
.font-normal a:hover,
p.font-description a:hover,
.font-description a:hover {
  color: #262626;
}
p.font-description {
  color: #262626;
  font-weight: 700;
  font-size: 20px;
}
ap.font-description,
p.font-description a {
  color: #2B6F88;
}
ap.font-description:hover,
p.font-description a:hover {
  color: #262626;
}
h1,
.font-heading-1,
h1.font-heading-1 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 29px;
  line-height: 1.3em;
  font-weight: 600;
}
@media (max-width: 767px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 26px;
  }
}
@media (max-width: 479px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    margin-bottom: 10px;
  }
}
h2,
h2.font-heading-2,
.font-heading-2 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 700;
}
@media (max-width: 479px) {
  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-size: 18px;
    line-height: 29px;
  }
}
h3,
h3.font-heading-3,
.font-heading-3 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 500;
}
@media (max-width: 479px) {
  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-size: 14px;
  }
}
h4,
h4.font-heading-4,
.font-heading-4 {
  font-family: 'Quicksand', sans-serif;
  color: #262626;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;
}
@media (max-width: 479px) {
  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-size: 12px;
  }
}
p.font-quote,
.font-quote {
  position: relative;
  font-family: 'Quicksand', sans-serif;
  color: #3c3c3c;
  font-size: 18px;
  line-height: 29px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
}
@media (max-width: 991px) {
  p.font-quote,
  .font-quote {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  p.font-quote,
  .font-quote {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 479px) {
  p.font-quote,
  .font-quote {
    padding-left: 30px;
  }
}
p.font-quote::before,
.font-quote::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 20px;
  content: "\f10e";
  position: absolute;
  left: 20px;
  top: 0px;
  opacity: 0.3;
  line-height: 35px;
}
p.font-quote::before.fa-pull-left,
.font-quote::before.fa-pull-left {
  margin-right: 0.3em;
}
p.font-quote::before.fa-pull-right,
.font-quote::before.fa-pull-right {
  margin-left: 0.3em;
}
@media (max-width: 991px) {
  p.font-quote::before,
  .font-quote::before {
    left: 5px;
    line-height: 33px;
  }
}
@media (max-width: 479px) {
  p.font-quote::before,
  .font-quote::before {
    left: 5px;
    line-height: 31px;
  }
}
